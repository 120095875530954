/**
 * @generated SignedSource<<75b82d30534c2e8e2003a395aec91d07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as equipmentKindLookupLabelResolverType } from "../../../__resolvers__/EquipmentKindLookupLabelResolver";
export type SaleCraneSelectorFields_EquipmentKindFragment$data = {
  readonly equipmentKind: {
    readonly code: number;
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_EquipmentKindFragment";
};
export type SaleCraneSelectorFields_EquipmentKindFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_EquipmentKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_EquipmentKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_EquipmentKindFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentKindLookup",
      "kind": "LinkedField",
      "name": "equipmentKind",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "lang",
              "variableName": "lang"
            }
          ],
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquipmentKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/EquipmentKindLookupLabelResolver').label,
          "path": "equipmentKind.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "6f16286199d3f249e0dbe1dd925fc68c";

export default node;
