import { Suspense, useCallback } from 'react';
import { DetailsLayout, EmptyLayout, SidebarContentProps } from '../layout/Layouts';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Navigate, useParams } from 'react-router-dom';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { FormProvider } from '../common/utils/forms';
import {
  BillingCodeAutomaticGrid,
  billingCodeRuleDetailsFormContext,
  useFieldBillingCodesVisible,
  useFieldCapacities,
  useFieldClients,
  useFieldDescription,
  useFieldEquipmentKinds,
  useFieldKinds,
  useFieldNatureOfWorks,
  useFieldNatureOfWorkSubCategories,
  useFieldQuestionsBase,
} from './BillingCodeRuleFields';
import { FormSection2, FormSectionContent, FormSectionHeader } from '../layout/FormLayout';
import { BillingCodeRuleSaveButton } from './BillingCodeRuleSaveButton';
import { indexedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { RequireAdmin } from '../auth/Authorization';
import { BillingCodeRuleDetailsPageFormFragment$key } from './__generated__/BillingCodeRuleDetailsPageFormFragment.graphql';
import { BillingCodeRuleDetailsPageConditionsFragment$key } from './__generated__/BillingCodeRuleDetailsPageConditionsFragment.graphql';
import { BillingCodeRuleDetailsPageEffectsFragment$key } from './__generated__/BillingCodeRuleDetailsPageEffectsFragment.graphql';
import { BillingCodeRuleDetailsPageRootQuery } from './__generated__/BillingCodeRuleDetailsPageRootQuery.graphql';
import { BillingCodeRuleDetailsPageInformationFragment$key } from './__generated__/BillingCodeRuleDetailsPageInformationFragment.graphql';
import { useTranslation } from 'react-i18next';
import { resolvedLanguage } from '../i18n';
import { ErrorBanner, ErrorStateProvider } from '../common/components/ErrorBanner';
import { BillingCodeRuleDeleteButton } from './BillingCodeRuleDeleteButton';
import { usePreventNavigationOnFormDirty } from '../common/hooks/usePreventNavigationOnFormDirty';
import { DetailsPageErrorBoundary } from '../layout/DetailsPageErrorBoundary';
import { DetailsPageRootErrorBoundary } from '../layout/DetailsPageRootErrorBoundary';
import { ElementNotFoundError } from '../common/exceptions/ElementNotFoundError';
import { ElementNotFoundErrorBoundary } from '../layout/ElementNotFoundErrorBoundary';
import { Typography } from '@mui/material';
import { BillingCodeRuleDetailsPageRootEtagFragment$key } from './__generated__/BillingCodeRuleDetailsPageRootEtagFragment.graphql';

export function BillingCodeRuleDetailsPage() {
  const { t } = useAmbientTranslation();
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageErrorBoundary heading={heading}>
      <Suspense fallback={<EmptyLayout />}>
        <ErrorStateProvider>
          <ElementNotFoundErrorBoundary sidebar$key={null} detailsLayout$key={null} heading={heading}>
            <BillingCodeRuleDetailsPageRoot />
          </ElementNotFoundErrorBoundary>
        </ErrorStateProvider>
      </Suspense>
    </DetailsPageErrorBoundary>
  );
}

function BillingCodeRuleDetailsPageRoot() {
  const { t } = useAmbientTranslation();
  usePageTitle(indexedPageTitle('sidebar.billingCodeRules'));
  const params = useParams<{ id: DataID }>();
  const id = params.id ?? 'new';
  const { i18n } = useTranslation();

  const $data = useLazyLoadQuery<BillingCodeRuleDetailsPageRootQuery>(
    graphql`
      query BillingCodeRuleDetailsPageRootQuery($lang: String!, $id: ID!, $isNew: Boolean!) {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsDetailsLayoutFragment
        ...DetailsPageRootErrorBoundaryFragment
        billingCodeRule(id: $id) @skip(if: $isNew) {
          ...BillingCodeRuleDetailsPageFormFragment @arguments(lang: $lang)
          ...BillingCodeRuleDetailsPageRootEtagFragment
        }
      }
    `,
    { id, isNew: id === 'new', lang: resolvedLanguage(i18n) },
  );

  const eTag = useFragment<BillingCodeRuleDetailsPageRootEtagFragment$key>(
    graphql`
      fragment BillingCodeRuleDetailsPageRootEtagFragment on BillingCodeRule {
        eTag
      }
    `,
    $data.billingCodeRule,
  )?.eTag;

  if (id !== 'new' && $data.billingCodeRule == null) {
    throw new ElementNotFoundError('BillingCodeRule', id, '/billing-code-rules');
  }

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = id === 'new' ? t('newTitle') : t('editTitle', { id });

  return (
    <DetailsPageRootErrorBoundary heading={heading} $key={$data}>
      <RequireAdmin $key={$data} fallback={<Navigate to='..' replace />}>
        <FormProvider key={eTag ?? id} context={billingCodeRuleDetailsFormContext}>
          <DetailsLayout
            heading={heading}
            sidebarProvider={sidebar}
            $key={$data}
            actions={
              <>
                <BillingCodeRuleDeleteButton id={id} />
                <BillingCodeRuleSaveButton id={id} />
              </>
            }>
            <ErrorBanner />
            <BillingCodeRuleDetailsPageForm $key={$data.billingCodeRule} />
          </DetailsLayout>
        </FormProvider>
      </RequireAdmin>
    </DetailsPageRootErrorBoundary>
  );
}

function BillingCodeRuleDetailsPageForm({ $key }: { $key: BillingCodeRuleDetailsPageFormFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  usePreventNavigationOnFormDirty([billingCodeRuleDetailsFormContext]);

  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPageFormFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        ...BillingCodeRuleDetailsPageInformationFragment
        ...BillingCodeRuleDetailsPageConditionsFragment @arguments(lang: $lang)
        ...BillingCodeRuleDetailsPageEffectsFragment @arguments(lang: $lang)
      }
    `,
    $key,
  );

  return (
    <>
      <FormSection2 heading={t('placeholder.information')}>
        <BillingCodeRuleInformation $key={$data} />
      </FormSection2>
      <FormSection2 heading={t('fields.label.conditions')}>
        <BillingCodeRuleConditions $key={$data} />
      </FormSection2>
      <FormSectionHeader label={t('fields.label.billingCodes')} />
      <BillingCodeRuleEffects $key={$data} />
    </>
  );
}

function BillingCodeRuleInformation({ $key }: { $key: BillingCodeRuleDetailsPageInformationFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPageInformationFragment on BillingCodeRule {
        ...BillingCodeRuleFieldsDescriptionFragment
      }
    `,
    $key,
  );

  const { renderDescription } = useFieldDescription($data);
  return renderDescription();
}

function BillingCodeRuleConditions({ $key }: { $key: BillingCodeRuleDetailsPageConditionsFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPageConditionsFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        ...BillingCodeRuleFieldsKindsFragment
        ...BillingCodeRuleFieldsCapacitiesFragment
        ...BillingCodeRuleFieldsEquipmentKindsFragment @arguments(lang: $lang)
        ...BillingCodeRuleFieldsClientFragment
        ...BillingCodeRuleFieldsNatureOfWorkFragment @arguments(lang: $lang)
        ...BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment
        ...BillingCodeRuleFieldsQuestionsBase
      }
    `,
    $key,
  );

  const { renderKinds } = useFieldKinds($data);
  const { renderCapacities } = useFieldCapacities($data);
  const { renderEquipmentKinds } = useFieldEquipmentKinds($data);
  const { renderClient } = useFieldClients($data);
  const { renderNatureOfWorks } = useFieldNatureOfWorks($data);
  const { renderNatureOfWorkSubCategories } = useFieldNatureOfWorkSubCategories($data);
  const { renderQuestionsBase } = useFieldQuestionsBase($data);

  return (
    <>
      {renderKinds()}
      {renderCapacities()}
      {renderEquipmentKinds()}
      {renderClient()}
      {renderNatureOfWorks()}
      {renderNatureOfWorkSubCategories()}
      {renderQuestionsBase()}
    </>
  );
}

function BillingCodeRuleEffects({ $key }: { $key: BillingCodeRuleDetailsPageEffectsFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleDetailsPageEffectsFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        ...BillingCodeRuleFieldsBillingCodesVisibleFragment @arguments(lang: $lang)
        ...BillingCodeRuleFields_AutomaticGridFragment
      }
    `,
    $key,
  );

  const { renderBillingCodesVisible } = useFieldBillingCodesVisible($data);

  return (
    <>
      <FormSectionContent>
        {renderBillingCodesVisible()}
        <Typography>{t('fields.label.billingCodesAutomatic')}</Typography>
      </FormSectionContent>
      <BillingCodeAutomaticGrid $key={$data} />
    </>
  );
}
