import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  Queryable,
} from './PaginatedAutocomplete';
import { resolvedLanguage } from '../../i18n';
import { convertToTsQuery } from '../utils/stringUtils';
import { CostBillingCodeAutocompleteListFragment$data } from './__generated__/CostBillingCodeAutocompleteListFragment.graphql';
import { CostBillingCodeAutocompleteQuery } from './__generated__/CostBillingCodeAutocompleteQuery.graphql';
import { useAmbientTranslation } from '../hooks/useAmbientTranslation';

export interface CostBillingCodeAutocompleteProps {
  searchInput: SearchBillingCodeInput;
  onlyVisible: boolean;
}

export type SearchBillingCodeInput = {
  capacity: string;
  clientExternalId: string;
  equipmentKindCode: string;
  jobKind: string;
  natureOfWorkCode: string;
  natureOfWorkSubCategory: string;
  questionsBase: string[];
};

export type ForwardCostBillingCodeAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<CostBillingCodeAutocompleteListFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const CostBillingCodeAutocomplete = forwardRef<
  HTMLInputElement,
  CostBillingCodeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<CostBillingCodeAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      boolean,
      boolean,
      ElementType
    >
>(function CostBillingCodeAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    searchInput,
    onlyVisible,
    textFieldProps,
    ...paginatedAutocompleteProps
  }: CostBillingCodeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<CostBillingCodeAutocompleteListFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const { i18n, t } = useAmbientTranslation();
  const env = useRelayEnvironment();

    const handleQuery = useCallback(
      (searchTerm: string | null) =>
        fetchQuery<CostBillingCodeAutocompleteQuery>(
          env,
          graphql`
            query CostBillingCodeAutocompleteQuery(
              $lang: String!
              $searchTerm: String
              $input: SearchBillingCodeInput!
              $onlyVisible: Boolean
            ) {
              ...CostBillingCodeAutocompleteListFragment
                @arguments(lang: $lang, searchTerm: $searchTerm, input: $input, onlyVisible: $onlyVisible)
            }
          `,
          {
            searchTerm: convertToTsQuery(searchTerm),
            lang: resolvedLanguage(i18n),
            input: searchInput,
            onlyVisible: onlyVisible,
          },
        ),
      [env, i18n, onlyVisible, searchInput],
    );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<CostBillingCodeAutocompleteListFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref as Ref<HTMLInputElement & Queryable>}
      fragment={graphql`
        fragment CostBillingCodeAutocompleteListFragment on Query
        @refetchable(queryName: "billingCodeAutocomplete2ListFragmentQuery")
        @argumentDefinitions(
          lang: { type: "String!" }
          searchTerm: { type: "String" }
          input: { type: "SearchBillingCodeInput!" }
          onlyVisible: { type: "Boolean" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchCostBillingCodes(
            searchTerm: $searchTerm
            input: $input
            onlyVisible: $onlyVisible
            after: $cursor
            first: $count
          ) @connection(key: "billingCodeAutocomplete2ListFragment_searchResults") {
            edges {
              node {
                id
                code
                label(lang: $lang)
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      {...paginatedAutocompleteProps}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          placeholder: t('button.select', { ns: 'common' }),
          ...p,
        };
      }}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: CostBillingCodeAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<CostBillingCodeAutocompleteListFragment$data>,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    > &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
