import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { BranchLabelResolver$key } from './__generated__/BranchLabelResolver.graphql';

/**
 * @RelayResolver Branch.label(lang: String!): String
 * @rootFragment BranchLabelResolver
 *
 * A label for Branch which includes number and name.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(branch$key: BranchLabelResolver$key, { lang }: { lang: string }): string {
  const branch$data = readFragment(
    graphql`
      fragment BranchLabelResolver on Branch {
        number
        name
      }
    `,
    branch$key,
  );
  return `${branch$data.number} - ${branch$data.name[lang]}`;
}
