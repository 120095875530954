/**
 * @generated SignedSource<<13ac3b6b2730057f5ff411097d578aa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumKind = "archived" | "clientOrWorksite" | "craneOperator" | "dispatching" | "internalNote" | "invoicing" | "quote" | "salesSupervisor" | "supervisionNote" | "%future added value";
export type QuoteKind = "accessoriesRental" | "bare" | "laborRental" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "%future added value";
export type SaveAddendumTemplateInput = {
  kind: AddendumKind;
  requiredForQuoteKinds: ReadonlyArray<QuoteKind>;
  templateEn: string;
  templateFr: string;
  titleEn: string;
  titleFr: string;
};
export type AddendumTemplateSaveButtonMutation$variables = {
  id?: string | null | undefined;
  saveAddendumTemplate: SaveAddendumTemplateInput;
};
export type AddendumTemplateSaveButtonMutation$data = {
  readonly saveAddendumTemplate: {
    readonly addendumTemplate: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateDetailsPageFragment">;
    } | null | undefined;
  };
};
export type AddendumTemplateSaveButtonMutation = {
  response: AddendumTemplateSaveButtonMutation$data;
  variables: AddendumTemplateSaveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "saveAddendumTemplate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "saveAddendumTemplate",
    "variableName": "saveAddendumTemplate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddendumTemplateSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAddendumTemplatePayload",
        "kind": "LinkedField",
        "name": "saveAddendumTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddendumTemplate",
            "kind": "LinkedField",
            "name": "addendumTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AddendumTemplateDetailsPageFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddendumTemplateSaveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SaveAddendumTemplatePayload",
        "kind": "LinkedField",
        "name": "saveAddendumTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AddendumTemplate",
            "kind": "LinkedField",
            "name": "addendumTemplate",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "template",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredForQuoteKinds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb8ba420bd92c9aa2ee283d577c905c4",
    "id": null,
    "metadata": {},
    "name": "AddendumTemplateSaveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation AddendumTemplateSaveButtonMutation(\n  $id: ID\n  $saveAddendumTemplate: SaveAddendumTemplateInput!\n) {\n  saveAddendumTemplate(id: $id, saveAddendumTemplate: $saveAddendumTemplate) {\n    addendumTemplate {\n      id\n      ...AddendumTemplateDetailsPageFragment\n    }\n  }\n}\n\nfragment AddendumTemplateDetailsPageFragment on AddendumTemplate {\n  id\n  ...AddendumTemplateFields_TitleFrFragment\n  ...AddendumTemplateFields_TitleEnFragment\n  ...AddendumTemplateFieldsKindFragment\n  ...AddendumTemplateFields_TemplateFrFragment\n  ...AddendumTemplateFields_TemplateEnFragment\n  ...AddendumTemplateFieldsRequiredForQuoteKindsFragment\n}\n\nfragment AddendumTemplateFieldsKindFragment on AddendumTemplate {\n  kind\n}\n\nfragment AddendumTemplateFieldsRequiredForQuoteKindsFragment on AddendumTemplate {\n  requiredForQuoteKinds\n}\n\nfragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {\n  template\n}\n\nfragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {\n  title\n}\n\nfragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {\n  title\n}\n"
  }
};
})();

(node as any).hash = "b9a96975d80b8a85d78f632a2222cc80";

export default node;
