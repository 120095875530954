import { createSharedStateContext, createSharedStateKey, useSharedState } from './common/utils/sharedState';
import { ReactNode, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSticky } from './common/hooks/useSticky';
import { _throw } from './common/utils/_throw';
import { useEffectEvent } from './common/utils/effectUtils';

export const appSharedStateContext = createSharedStateContext();

type Operations = { [key: string]: { operationsInFlight: number } };
export const operationsKey = createSharedStateKey<Operations>(() => ({}));
const operationNotificationDelay = 250;

/**
 * Manage and track asynchronous operations.
 *
 * @param {string} key - A key representing the operation. Used to control operations by key when required.
 *
 * @returns {Object} - An object containing the following properties and functions:
 *   - {Function} startOperation - Signal the start of an operation.
 *   - {Function} endOperation - Signal the end of an operation.
 *   - {boolean} hasOperationInFlight - Indicates if there are any operations in flight.
 *   - {boolean} hasOperationInFlightForKey - Indicates if there are any operations in flight for the specified key.
 *   - {boolean} shouldNotify - A boolean indicating if user should be notified for in flight operation. Value is debounced from {@link hasOperationInFlight}. If operation is very fast {@link operationNotificationDelay}, there is no need to notify user.
 *   - {boolean} shouldNotifyForKey - Same as {@link shouldNotify} for a specific key.
 */
export function useOperations(key: string): {
  startOperation: () => void;
  endOperation: () => void;
  hasOperationInFlight: boolean;
  hasOperationInFlightForKey: boolean;
  shouldNotify: boolean;
  shouldNotifyForKey: boolean;
} {
  const [state, setState] = useSharedState(appSharedStateContext, operationsKey);
  const hasOperationInFlight = useMemo(() => Object.values(state).reduce((acc, cur) => acc + cur.operationsInFlight, 0) > 0, [state]);
  const hasOperationInFlightForKey = state[key]?.operationsInFlight > 0;
  const shouldNotify = useSticky(hasOperationInFlight, operationNotificationDelay, (v) => !v);
  const shouldNotifyForKey = useSticky(hasOperationInFlightForKey, operationNotificationDelay, (v) => !v);

  const startOperation = useCallback(
    () =>
      setState((prev) => {
        const ops = prev[key] ?? { operationsInFlight: 0 };
        return { ...prev, [key]: { ...ops, operationsInFlight: ops.operationsInFlight + 1 } };
      }),
    [key, setState],
  );
  const endOperation = useCallback(
    () =>
      setState((prev) => {
        const { [key]: ops, ...rest } = prev;
        if (!ops) return prev;
        const newOps = { ...ops, operationsInFlight: ops.operationsInFlight - 1 };
        if (newOps.operationsInFlight <= 0) return rest;

        return { ...rest, [key]: newOps };
      }),
    [key, setState],
  );

  return useMemo(
    () => ({ startOperation, endOperation, hasOperationInFlight, hasOperationInFlightForKey, shouldNotify, shouldNotifyForKey }),
    [endOperation, hasOperationInFlight, hasOperationInFlightForKey, shouldNotify, shouldNotifyForKey, startOperation],
  );
}

type OperationsError = { [key: string]: { error: Error } };
export const operationsErrorKey = createSharedStateKey<OperationsError>(() => ({}));

/**
 * Manage and track errors for asynchronous operations. Use this hook for errors not managed by the {@link useErrorBanner} that we want to track globally.
 *
 * @param {string} key - A key representing the operation.
 *
 * @returns {Object} - An object containing the following properties and functions:
 *   - {Error | undefined} error - The error associated with the specified key, or undefined if no error is present.
 *   - {Function} setError - Set the error for the specified key.
 *   - {Function} resetError - Reset the error for the specified key.
 */
export function useOperationsError(key: string): {
  error: Error | undefined;
  setError: (error: SetStateAction<Error | undefined>) => void;
  resetError: () => void;
} {
  const [state, setState] = useSharedState(appSharedStateContext, operationsErrorKey);

  const error = state[key]?.error;
  const setError = useCallback(
    (err: SetStateAction<Error | undefined>) =>
      setState((prev) => ({
        ...prev,
        [key]: {
          error:
            (typeof err === 'function' ? err(prev[key]?.error) : err) ??
            _throw(new Error('Invalid Operation, error needs to be set to a valid value')),
        },
      })),
    [key, setState],
  );
  const resetError = useCallback(() => setState(({ [key]: _, ...rest }) => rest), [key, setState]);

  return useMemo(() => ({ error, setError, resetError }), [error, resetError, setError]);
}

/**
 * Fallback component intended to be used in a Suspense above a lazyLoadQuery which would require to start and stop an operation in the app using {@link useOperations}.
 *
 * IMPORTANT: Always remember to call endOperation after the form data has been updated, otherwise operation will always be considered in flight.
 * @param {string} operationKey They key of the operation to start
 * @param {ReactNode} fallback The fallback component to display
 *
 * @example Intended usage:
 * const Parent() {
 *   return (
 *    <Suspense fallback={<StartOperationFallback operationKey='testKey' fallback='loading...' />} >
 *      <ChildQueried />
 *    </Suspense>
 *   )
 * }
 *
 * const ChildQueried() {
 *   const $data = useLazyLoadQuery(...);
 *   const { renderTestField, setTestField } = useTestField($data);
 *   const { endOperation } = useOperations('testKey');
 *
 *   useEffect(() => {
 *    if ($data) {
 *      // Query is done, update field with new data and signal operation end.
 *      setTestField(...);
 *      endOperation();
 *    }
 *   }, []);
 *
 *   return <>{renderTestField()}</>
 * }
 */
export function StartOperationFallback({ operationKey, fallback }: { operationKey: string; fallback: ReactNode }) {
  const { startOperation } = useOperations(operationKey);

  const startLoading = useEffectEvent(() => startOperation());
  useEffect(() => {
    startLoading();
  }, [startLoading]);

  return fallback;
}
