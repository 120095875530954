/**
 * @generated SignedSource<<1956fb5a95880428a1e3dc5d105748eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as rateStrategyLookupLabelResolverType } from "../../../__resolvers__/RateStrategyLookupLabelResolver";
export type RateStrategyListFragment$data = {
  readonly rateStrategies: ReadonlyArray<{
    readonly id: string;
    readonly label: NonNullable<string | null | undefined>;
  }>;
  readonly " $fragmentType": "RateStrategyListFragment";
};
export type RateStrategyListFragment$key = {
  readonly " $data"?: RateStrategyListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RateStrategyListFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RateStrategyListFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RateStrategyLookup",
      "kind": "LinkedField",
      "name": "rateStrategies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "lang",
                "variableName": "lang"
              }
            ],
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RateStrategyLookupLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../../__resolvers__/RateStrategyLookupLabelResolver').label,
            "path": "rateStrategies.label"
          },
          "action": "THROW",
          "path": "rateStrategies.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cc6fd946a1dbcc9466fbf0362ec618e9";

export default node;
