import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { AccessoryTypeLabelResolver$key } from './__generated__/AccessoryTypeLabelResolver.graphql';

/**
 * @RelayResolver AccessoryTypeLookup.label(lang: String!): String
 * @rootFragment AccessoryTypeLabelResolver
 *
 * A label for AccessoryType which includes code and shortDescription.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(accessoryTypeLabel$key: AccessoryTypeLabelResolver$key, { lang }: { lang: string }): string {
  const accessoryTypeLabel$data = readFragment(
    graphql`
      fragment AccessoryTypeLabelResolver on AccessoryTypeLookup {
        code
        shortDescription
      }
    `,
    accessoryTypeLabel$key,
  );

  return `${accessoryTypeLabel$data.code} - ${accessoryTypeLabel$data.shortDescription[lang]}`;
}
