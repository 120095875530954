/**
 * @generated SignedSource<<fec281b9be3c1528e0ad4d1fd836ebb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_ItemQueryFragment$data = {
  readonly clientBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ClientBaseFields_AssignedClientFragment">;
  };
  readonly costsBase: {
    readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_AccessoryLineCollectionFragment">;
  };
  readonly equipmentBase: {
    readonly craneSelector: {
      readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
    };
  };
  readonly project: {
    readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_NatureOfWorkFragment">;
  };
  readonly projectBase: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectBaseFields_ArrivalDateFragment" | "ProjectBaseFields_AssignedWorksiteFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useHasSatisfiedCostsDependenciesFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_ItemQueryFragment";
};
export type AccessoryLinesFields_ItemQueryFragment$key = {
  readonly " $data"?: AccessoryLinesFields_ItemQueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemQueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_ItemQueryFragment",
  "selections": [
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "useHasSatisfiedCostsDependenciesFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectInternalBase",
      "kind": "LinkedField",
      "name": "projectBase",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Literal",
              "name": "isCopy",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_ArrivalDateFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectBaseFields_AssignedWorksiteFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClientInternalBase",
      "kind": "LinkedField",
      "name": "clientBase",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClientBaseFields_AssignedClientFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_DispatchBranchFragment"
        },
        {
          "args": (v1/*: any*/),
          "kind": "FragmentSpread",
          "name": "SaleProjectFields_NatureOfWorkFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquipmentInternalBase",
      "kind": "LinkedField",
      "name": "equipmentBase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CraneSelectorInternal",
          "kind": "LinkedField",
          "name": "craneSelector",
          "plural": false,
          "selections": [
            {
              "args": (v1/*: any*/),
              "kind": "FragmentSpread",
              "name": "JobEquipment_useCraneSelectorFavoriteFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CostsInternalBase",
      "kind": "LinkedField",
      "name": "costsBase",
      "plural": false,
      "selections": [
        {
          "args": [
            (v0/*: any*/),
            {
              "kind": "Literal",
              "name": "skipAccessories",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "AccessoryLinesFields_AccessoryLineCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};
})();

(node as any).hash = "a2184d88251a7e34ac9122b29b460db3";

export default node;
