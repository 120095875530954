/**
 * @generated SignedSource<<f2f637efa3d8d45e4433601240e4d255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useSyncAdditionalCranesAutomaticFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment" | "SaleCraneSelectorFields_CraneSelectorModeFragment">;
  readonly " $fragmentType": "JobEquipment_useSyncAdditionalCranesAutomaticFragment";
};
export type JobEquipment_useSyncAdditionalCranesAutomaticFragment$key = {
  readonly " $data"?: JobEquipment_useSyncAdditionalCranesAutomaticFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useSyncAdditionalCranesAutomaticFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useSyncAdditionalCranesAutomaticFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};
})();

(node as any).hash = "b4fc7292c02b6ee95ee9c0835b866ae5";

export default node;
