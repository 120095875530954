/**
 * @generated SignedSource<<95a8ea45078c2bd661ae41e1f508f8fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as boomConfigurationLabelResolverType } from "../../__resolvers__/BoomConfigurationLabelResolver";
import { label as configurationKindLookupLabelResolverType } from "../../__resolvers__/ConfigurationKindLookupLabelResolver";
import { label as craneCapacityLabelResolverType } from "../../__resolvers__/CraneCapacityLabelResolver";
import { label as equipmentKindLookupLabelResolverType } from "../../__resolvers__/EquipmentKindLookupLabelResolver";
import { label as vehicleIdLabelResolverType } from "../../__resolvers__/VehicleIdLabelResolver";
export type JobEquipment_AutomaticFavoriteFragment$data = {
  readonly boomConfiguration: {
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly boomLength: string;
  readonly capacity: {
    readonly capacity: number;
    readonly label: string | null | undefined;
  };
  readonly configurationKind: {
    readonly code: number;
    readonly id: string;
    readonly label: string | null | undefined;
  };
  readonly counterweight: string;
  readonly equipmentKind: {
    readonly code: number;
    readonly id: string;
    readonly label: string | null | undefined;
  };
  readonly jibLength: string;
  readonly maxWeight: string;
  readonly offsetAngle: string;
  readonly radius: string;
  readonly vehicleId: {
    readonly key: string;
    readonly label: string | null | undefined;
  };
  readonly " $fragmentType": "JobEquipment_AutomaticFavoriteFragment";
};
export type JobEquipment_AutomaticFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_AutomaticFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_AutomaticFavoriteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_AutomaticFavoriteFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "CraneCapacity",
        "kind": "LinkedField",
        "name": "capacity",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "capacity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CraneCapacityLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../__resolvers__/CraneCapacityLabelResolver').label,
            "path": "capacity.label"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "capacity"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "EquipmentKindLookup",
        "kind": "LinkedField",
        "name": "equipmentKind",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EquipmentKindLookupLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../__resolvers__/EquipmentKindLookupLabelResolver').label,
            "path": "equipmentKind.label"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "equipmentKind"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "VehicleId",
        "kind": "LinkedField",
        "name": "vehicleId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VehicleIdLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../__resolvers__/VehicleIdLabelResolver').label,
            "path": "vehicleId.label"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "vehicleId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "ConfigurationKindLookup",
        "kind": "LinkedField",
        "name": "configurationKind",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "fragment": {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ConfigurationKindLookupLabelResolver"
            },
            "kind": "RelayResolver",
            "name": "label",
            "resolverModule": require('./../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
            "path": "configurationKind.label"
          }
        ],
        "storageKey": null
      },
      "action": "THROW",
      "path": "configurationKind"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoomConfiguration",
      "kind": "LinkedField",
      "name": "boomConfiguration",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoomConfigurationLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../__resolvers__/BoomConfigurationLabelResolver').label,
          "path": "boomConfiguration.label"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boomLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jibLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "counterweight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offsetAngle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "radius",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxWeight",
      "storageKey": null
    }
  ],
  "type": "AutomaticConfigurationInfo",
  "abstractKey": null
};
})();

(node as any).hash = "57ad4b58bed0dcf1155dbdb4d8563922";

export default node;
