/**
 * @generated SignedSource<<89d44349e109de6ae99c1af7478f7371>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_ItemFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemQueryFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_ItemFragment";
};
export type AccessoryLinesFields_ItemFragment$key = {
  readonly " $data"?: AccessoryLinesFields_ItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_ItemFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "lang",
          "variableName": "lang"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_ItemQueryFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "90d0d6c8ae1b12d827bb0a1442ec0f95";

export default node;
