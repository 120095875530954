/**
 * @generated SignedSource<<3c41b66a4bd109c94099847a7d2b2463>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as billingCodeLabelResolverType } from "../../__resolvers__/BillingCodeLabelResolver";
import { label as workForceTypeLookupLabelResolverType } from "../../__resolvers__/WorkForceTypeLabelResolver";
export type BillingCodeRuleFields_BillingCodeCollectionFragment$data = {
  readonly effects: {
    readonly billingCodes: {
      readonly automatic: ReadonlyArray<{
        readonly billingCode: {
          readonly code: number;
          readonly id: string;
          readonly label: string | null | undefined;
        } | null | undefined;
        readonly workForceType: {
          readonly code: number;
          readonly id: string;
          readonly label: string | null | undefined;
        } | null | undefined;
      }>;
    };
  };
  readonly " $fragmentType": "BillingCodeRuleFields_BillingCodeCollectionFragment";
};
export type BillingCodeRuleFields_BillingCodeCollectionFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_BillingCodeCollectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_BillingCodeCollectionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_BillingCodeCollectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EffectsInternal",
      "kind": "LinkedField",
      "name": "effects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingCodeEffects",
          "kind": "LinkedField",
          "name": "billingCodes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AutomaticBillingCodeEffectResponse",
              "kind": "LinkedField",
              "name": "automatic",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BillingCode",
                  "kind": "LinkedField",
                  "name": "billingCode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": (v2/*: any*/),
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "BillingCodeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../__resolvers__/BillingCodeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.billingCode.label"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "WorkForceTypeLookup",
                  "kind": "LinkedField",
                  "name": "workForceType",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": (v2/*: any*/),
                      "fragment": {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "WorkForceTypeLabelResolver"
                      },
                      "kind": "RelayResolver",
                      "name": "label",
                      "resolverModule": require('./../../__resolvers__/WorkForceTypeLabelResolver').label,
                      "path": "effects.billingCodes.automatic.workForceType.label"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "4b9f9da470155789d98a211af3e48086";

export default node;
