import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { EquipmentKindLookupLabelResolver$key } from './__generated__/EquipmentKindLookupLabelResolver.graphql';

/**
 * @RelayResolver EquipmentKindLookup.label(lang: String!): String
 * @rootFragment EquipmentKindLookupLabelResolver
 *
 * A label for EquipmentKindLookup which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(equipmentKindLookup$key: EquipmentKindLookupLabelResolver$key, { lang }: { lang: string }): string {
  const equipmentKindLookup$data = readFragment(
    graphql`
      fragment EquipmentKindLookupLabelResolver on EquipmentKindLookup {
        code
        description
      }
    `,
    equipmentKindLookup$key,
  );
  return `${equipmentKindLookup$data.code} - ${equipmentKindLookup$data.description[lang]}`;
}
