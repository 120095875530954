import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { NatureOfWorkLabelResolver$key } from './__generated__/NatureOfWorkLabelResolver.graphql';

/**
 * @RelayResolver NatureOfWorkLookup.label(lang: String!): String
 * @rootFragment NatureOfWorkLabelResolver
 *
 * A label for Nature of work which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(natureOfWork$key: NatureOfWorkLabelResolver$key, { lang }: { lang: string }): string {
  const natureOfWork$data = readFragment(
    graphql`
      fragment NatureOfWorkLabelResolver on NatureOfWorkLookup {
        code
        description
      }
    `,
    natureOfWork$key,
  );
  return `${natureOfWork$data.code} - ${natureOfWork$data.description[lang]}`;
}
