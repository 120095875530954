import { useNavigate, useSearchParams } from 'react-router-dom';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import graphql from 'babel-plugin-relay/macro';
import { useNavigatorOnline } from '../common/hooks/useNavigatorOnline';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import {
  deserializeURLParams,
  serializeFilters,
  WorkPlanningList,
  WorkPlanningListProps,
  WorkPlanningSearchParamsFilters,
} from './WorkPlanningList';
import { Skeleton, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { EmptyLayout, ListLayout, ListLayoutActions, SidebarContentProps } from '../layout/Layouts';
import { Add } from '@mui/icons-material';
import { RequireRead, RequireWrite, UnauthorizedFallback } from '../auth/Authorization';
import { WorkPlanningListPageContentQuery } from './__generated__/WorkPlanningListPageContentQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { WorkPlanningListPageRootQuery } from './__generated__/WorkPlanningListPageRootQuery.graphql';
import { EmployeeNotFoundError } from '../auth/EmployeeNotFoundErrorBoundary';
import { WorkPlanningStatus } from '../__enums__/WorkPlanningStatus';
import { DateTime } from 'luxon';
import { WorkPlanningListPageContentDefaultFiltersFragment$key } from './__generated__/WorkPlanningListPageContentDefaultFiltersFragment.graphql';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { useJobFiltersSearchParams } from '../jobs/JobFilters';

export function WorkPlanningListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('pages.list.title')}>
      <Suspense fallback={<WorkPlanningListPageSkeleton />}>
        <WorkPlanningListPageRoot />
      </Suspense>
    </ListPageErrorBoundary>
  );
}

function WorkPlanningListPageSkeleton() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return <EmptyLayout>{compact ? <ListSkeleton /> : <GridSkeleton />}</EmptyLayout>;
}

function WorkPlanningListPageRoot() {
  const compact = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const $data = useLazyLoadQuery<WorkPlanningListPageRootQuery>(
    graphql`
      query WorkPlanningListPageRootQuery {
        ...AuthorizationReadFragment
        ...AuthorizationWriteFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
        me {
          ...WorkPlanningListPageContentDefaultFiltersFragment
        }
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.workPlannings'));

  const theme = useTheme();
  const navigate = useNavigate();

  const handleItemClick = useCallback((id: DataID) => navigate(`/work-plannings/${id}`), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('pages.list.title');

  if (!$data.me) throw EmployeeNotFoundError;

  return (
    <ListPageRootErrorBoundary $key={$data} heading={heading}>
      <RequireRead
        $key={$data}
        fallback={
          <ListLayout heading={heading} sidebarProvider={sidebar} $key={$data}>
            <UnauthorizedFallback />
          </ListLayout>
        }>
        <ListLayout
          heading={heading}
          sidebarProvider={sidebar}
          $key={$data}
          actions={
            <RequireWrite $key={$data}>
              <ListLayoutActions
                mainAction={{
                  label: t('button.create', { ns: 'common' }),
                  icon: <Add />,
                  onClick: () => navigate('/work-plannings/new'),
                }}
                componentProps={{
                  autoSplitButton: {
                    root: { 'aria-label': t('createWorkPlanningAction.ariaLabels.root') },
                    buttonMain: { 'aria-label': t('createWorkPlanningAction.ariaLabels.main') },
                  },
                  autoSpeedDial: {
                    fab: {
                      'aria-label': 'createWorkPlanningAction.ariaLabels.main',
                    },
                  },
                }}
              />
            </RequireWrite>
          }>
          <Suspense fallback={compact ? <ListSkeleton /> : <GridSkeleton />}>
            <WorkPlanningListContent
              defaultFilters$key={$data.me}
              compact={compact}
              onItemClick={handleItemClick}
              paginationSx={{ [theme.breakpoints.down('sm')]: { mb: '6rem' } }}
            />
          </Suspense>
        </ListLayout>
      </RequireRead>
    </ListPageRootErrorBoundary>
  );
}

function WorkPlanningListContent({
  defaultFilters$key,
  ...listProps
}: {
  defaultFilters$key: WorkPlanningListPageContentDefaultFiltersFragment$key;
} & Omit<WorkPlanningListProps, 'fragmentKey' | 'filtersFragmentKey' | 'filters' | 'onApplyFilters'>) {
  const today = DateTime.now();
  const [filtersDirty, setFiltersDirty] = useState<boolean>(false);
  const online = useNavigatorOnline();

  const $data = useFragment(
    graphql`
      fragment WorkPlanningListPageContentDefaultFiltersFragment on Employee {
        representativeId
        roles
        ...JobFilters_useJobFiltersSearchParamsFragment
      }
    `,
    defaultFilters$key,
  );

  const isSalesRepresentative = $data.roles?.includes('salesRepresentative');

  const [, setSearchParams] = useSearchParams();

  const { effectiveSearchParams } = useJobFiltersSearchParams(
    $data,
    useMemo(
      () =>
        new URLSearchParams([
          ['status', 'inProgress' satisfies WorkPlanningStatus],
          ['status', 'inWriting' satisfies WorkPlanningStatus],
          ['status', 'worksiteSelected' satisfies WorkPlanningStatus],
          ['arrivalDateStart', today.toFormat('yyyy-MM-dd')],
          ['arrivalDateEnd', today.plus({ day: 7 }).toFormat('yyyy-MM-dd')],
          ...(isSalesRepresentative && !!$data.representativeId ? [['representativeId', $data.representativeId]] : []),
        ]),
      [$data.representativeId, isSalesRepresentative, today],
    ),
    filtersDirty,
  );

  const searchParamFilters = useMemo(() => {
    return deserializeURLParams(effectiveSearchParams);
  }, [effectiveSearchParams]);

  // eslint-disable-next-line react/hook-use-state
  const [initialQueriedFilters] = useState(() => ({
    searchTerm: searchParamFilters.searchTerm,
    where: serializeFilters(searchParamFilters),
    representativeId: searchParamFilters.representative.map((rep) => rep.id),
  }));

  const workPlannings = useLazyLoadQuery<WorkPlanningListPageContentQuery>(
    graphql`
      query WorkPlanningListPageContentQuery(
        $first: Int
        $where: WorkPlanningJobRevisionFilterType
        $representativeIds: [ID!]!
        $searchTerm: String
      ) {
        ...WorkPlanningListFragment @arguments(searchTerm: $searchTerm, representativeIds: $representativeIds, first: $first, where: $where)
        ...WorkPlanningListFiltersFragment @arguments(representativeIds: $representativeIds)
      }
    `,
    {
      searchTerm: convertToTsQuery(initialQueriedFilters.searchTerm),
      representativeIds: initialQueriedFilters.representativeId,
      where: initialQueriedFilters.where,
    },
    { fetchPolicy: online ? 'store-and-network' : 'store-only' },
  );

  const handleApplyFilters = useCallback(
    (values: WorkPlanningSearchParamsFilters) => {
      if (!filtersDirty) setFiltersDirty(true);
      setSearchParams({
        ...(values.searchTerm && { searchTerm: values.searchTerm }),
        ...(values.arrivalDate && {
          arrivalDateStart: values.arrivalDate.start,
          arrivalDateEnd: values.arrivalDate.end,
        }),
        representativeId: values.representative.map((rep) => rep.id),
        status: values.status.map(String),
      });
    },
    [filtersDirty, setSearchParams],
  );

  return (
    <WorkPlanningList
      {...listProps}
      fragmentKey={workPlannings}
      filtersFragmentKey={workPlannings}
      filters={searchParamFilters}
      onApplyFilters={handleApplyFilters}
    />
  );
}

function GridSkeleton() {
  return <Skeleton variant='rounded' height='40rem' />;
}

function ListSkeleton() {
  return (
    <Stack gap={0.5}>
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
      <Skeleton variant='rounded' height='5.3rem' />
    </Stack>
  );
}
