/**
 * @generated SignedSource<<32f7c29e2852350d8958df3bfb1c9def>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorAutomaticFavoriteFragment$data = {
  readonly automaticConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorAutomaticFavoriteFragment";
};
export type JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorAutomaticFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorAutomaticFavoriteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorAutomaticFavoriteFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AutomaticConfigurationInternal",
      "kind": "LinkedField",
      "name": "automaticConfiguration",
      "plural": false,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};
})();

(node as any).hash = "6fb424aa2806ebab281b5f6a89ce0b94";

export default node;
