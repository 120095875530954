/**
 * @generated SignedSource<<d11faad6fe731b185fff4055a15a092a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type workForceTypeAutocomplete2FragmentQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  lang: string;
  searchTerm?: string | null | undefined;
};
export type workForceTypeAutocomplete2FragmentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WorkForceTypeAutocompleteFragment">;
};
export type workForceTypeAutocomplete2FragmentQuery = {
  response: workForceTypeAutocomplete2FragmentQuery$data;
  variables: workForceTypeAutocomplete2FragmentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": 25,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v2 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "workForceTypeAutocomplete2FragmentQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "WorkForceTypeAutocompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "workForceTypeAutocomplete2FragmentQuery",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v3/*: any*/),
        "concreteType": "SearchWorkForceTypesConnection",
        "kind": "LinkedField",
        "name": "searchWorkForceTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchWorkForceTypesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkForceTypeLookup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "name": "label",
                    "args": [
                      (v1/*: any*/)
                    ],
                    "fragment": {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "description",
                          "storageKey": null
                        }
                      ],
                      "type": "WorkForceTypeLookup",
                      "abstractKey": null
                    },
                    "kind": "RelayResolver",
                    "storageKey": null,
                    "isOutputType": true
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "searchResults",
        "args": (v3/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "workForceTypeAutocomplete2Fragment_searchResults",
        "kind": "LinkedHandle",
        "name": "searchWorkForceTypes"
      }
    ]
  },
  "params": {
    "cacheID": "0dbb41302c2bbffcf27b40686e7ad880",
    "id": null,
    "metadata": {},
    "name": "workForceTypeAutocomplete2FragmentQuery",
    "operationKind": "query",
    "text": "query workForceTypeAutocomplete2FragmentQuery(\n  $count: Int = 25\n  $cursor: String\n  $searchTerm: String\n) {\n  ...WorkForceTypeAutocompleteFragment_49ib5H\n}\n\nfragment WorkForceTypeAutocompleteFragment_49ib5H on Query {\n  searchResults: searchWorkForceTypes(searchTerm: $searchTerm, after: $cursor, first: $count) {\n    edges {\n      node {\n        id\n        code\n        ...WorkForceTypeLabelResolver\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment WorkForceTypeLabelResolver on WorkForceTypeLookup {\n  code\n  description\n}\n"
  }
};
})();

(node as any).hash = "e960d507e2f31a3ad6350b2d26e4f822";

export default node;
