/**
 * @generated SignedSource<<11e948472c1d3165a64597ff319a7ed1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccessoryLinesFields_Item_SaleProjectFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_ItemContent_SaleProjectFragment">;
  readonly " $fragmentType": "AccessoryLinesFields_Item_SaleProjectFragment";
};
export type AccessoryLinesFields_Item_SaleProjectFragment$key = {
  readonly " $data"?: AccessoryLinesFields_Item_SaleProjectFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccessoryLinesFields_Item_SaleProjectFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccessoryLinesFields_Item_SaleProjectFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "lang",
          "variableName": "lang"
        }
      ],
      "kind": "FragmentSpread",
      "name": "AccessoryLinesFields_ItemContent_SaleProjectFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "af5793b4c7f4fa2b73ae134f358b9585";

export default node;
