import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { WorkForceTypeLabelResolver$key } from './__generated__/WorkForceTypeLabelResolver.graphql';

/**
 * @RelayResolver WorkForceTypeLookup.label(lang: String!): String
 * @rootFragment WorkForceTypeLabelResolver
 *
 * A label for WorkForceType which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(workForceType$key: WorkForceTypeLabelResolver$key, { lang }: { lang: string }): string {
  const workForceType$data = readFragment(
    graphql`
      fragment WorkForceTypeLabelResolver on WorkForceTypeLookup {
        code
        description
      }
    `,
    workForceType$key,
  );
  return `${workForceType$data.code} - ${workForceType$data.description[lang]}`;
}
