import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

interface FormListProps {
  title: string;
  listComponent?: JSX.Element;
  onAddClick?: () => void;
  disableAddButton?: boolean;
  showAddButton?: boolean;
  helperText?: string | null;
  error?: { message?: string };
  sx?: SxProps<Theme>;
}

/**
 * @param title
 * @param listComponent
 * @param handleAddClick
 * @param disableAddButton
 * @param showAddButton
 * @param error
 * @param sx
 * @constructor
 *
 * @deprecated use FormSectionHeader with a form of list
 */
export function FormList({
  title,
  listComponent,
  onAddClick: handleAddClick,
  disableAddButton = false,
  showAddButton = true,
  error,
  sx,
}: FormListProps) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <>
      <Box
        sx={(
          [{ display: 'flex', alignItems: 'center', [theme.breakpoints.down('sm')]: { mx: '1rem' } }, sx ?? null] satisfies SxProps<Theme>[]
        ).flat()}>
        <Typography variant='overline' sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        {error && (
          <Typography variant='caption' color='error' ml={1}>
            {error.message ? t(error.message) : t('errorMessages.error')}
          </Typography>
        )}
        {showAddButton && (
          <Button onClick={handleAddClick} variant='text' color='secondary' disabled={disableAddButton}>
            {t('button.add')}
          </Button>
        )}
      </Box>
      {listComponent}
    </>
  );
}

export function EmptyList({ placeholder, sx }: { placeholder?: string; sx?: SxProps<Theme> }) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Box display='flex' alignItems='center' justifyContent='center' p={2} bgcolor={theme.palette.background.paper} sx={sx}>
      <Typography variant='body2' textTransform='uppercase' sx={{ color: theme.palette.text.secondary }}>
        {placeholder || t('placeholder.noValue')}
      </Typography>
    </Box>
  );
}
