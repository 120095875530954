/**
 * @generated SignedSource<<781e1169c282f4b35de5bb2356b3b345>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageConditionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsCapacitiesFragment" | "BillingCodeRuleFieldsClientFragment" | "BillingCodeRuleFieldsEquipmentKindsFragment" | "BillingCodeRuleFieldsKindsFragment" | "BillingCodeRuleFieldsNatureOfWorkFragment" | "BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment" | "BillingCodeRuleFieldsQuestionsBase">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageConditionsFragment";
};
export type BillingCodeRuleDetailsPageConditionsFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageConditionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageConditionsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageConditionsFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsCapacitiesFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsEquipmentKindsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsClientFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsNatureOfWorkFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsQuestionsBase"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "9ba2b821fa6d2638f449803d2ccb5dbb";

export default node;
