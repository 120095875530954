/**
 * @generated SignedSource<<040059c4374c958deeb92bd601e8ce37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorManualFavoriteFragment$data = {
  readonly userInput: {
    readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useResetBoomConfigurationFragment" | "SaleCraneSelectorFields_CraneCapacityFragment" | "SaleCraneSelectorFields_CraneConfigurationKindFragment" | "SaleCraneSelectorFields_EquipmentKindFragment" | "SaleCraneSelectorFields_Manual_BoomConfigurationFragment" | "SaleCraneSelectorFields_Manual_BoomLengthFragment" | "SaleCraneSelectorFields_Manual_CounterweightFragment" | "SaleCraneSelectorFields_Manual_JibLengthFragment" | "SaleCraneSelectorFields_Manual_MaxWeightFragment" | "SaleCraneSelectorFields_Manual_OffsetAngleFragment" | "SaleCraneSelectorFields_Manual_RadiusFragment" | "SaleCraneSelectorFields_VehicleIdFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AdditionalCranesFields_AdditionalCranesManualCollectionFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorManualFavoriteFragment";
};
export type JobEquipment_useCraneSelectorManualFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorManualFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorManualFavoriteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorManualFavoriteFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "AdditionalCranesFields_AdditionalCranesManualCollectionFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInfo",
      "kind": "LinkedField",
      "name": "userInput",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_CraneCapacityFragment"
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_EquipmentKindFragment"
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_CraneConfigurationKindFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_VehicleIdFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_BoomLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_JibLengthFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_CounterweightFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_OffsetAngleFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_RadiusFragment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SaleCraneSelectorFields_Manual_MaxWeightFragment"
        },
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "JobEquipment_useResetBoomConfigurationFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInternal",
  "abstractKey": null
};
})();

(node as any).hash = "d0dc58c9cf8a6d587509b110add48363";

export default node;
