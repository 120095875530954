import graphql from 'babel-plugin-relay/macro';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback } from 'react';
import { ForwardSelectPickerSuggestibleProps, SelectPickerSuggestible, SelectPickerSuggestibleProps } from './SelectPicker';
import { ChipTypeMap } from '@mui/material';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { NatureOfWorkSubCategoryAutocompleteQuery } from './__generated__/NatureOfWorkSubCategoryAutocompleteQuery.graphql';
import { emptySuggestionPromptInput } from './Suggestions';
import { SuggestionPromptInput } from './__generated__/SuggestionsFakeQuery.graphql';
import { NatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';

interface NatureOfWorkSubCategoryAutocompleteProps {
  suggestionPromptInput?: SuggestionPromptInput | null;
  suggestible?: boolean;
}

export type ForwardNatureOfWorkSubCategoryAutocompletePops<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerSuggestibleProps<NatureOfWorkSubCategoryEnum, Multiple, DisableClearable, FreeSolo> &
    ForwardSelectPickerSuggestibleProps<NatureOfWorkSubCategoryEnum, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'ref' | 'fragment' | 'onQuery'
>;

export const NatureOfWorkSubCategoryAutocomplete = forwardRef<
  HTMLInputElement,
  NatureOfWorkSubCategoryAutocompleteProps & ForwardNatureOfWorkSubCategoryAutocompletePops<boolean, boolean, boolean, ElementType>
>(function NatureOfWorkSubCategoryAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    suggestionPromptInput,
    suggestible,
    ...selectPickerProps
  }: NatureOfWorkSubCategoryAutocompleteProps &
    ForwardNatureOfWorkSubCategoryAutocompletePops<Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: Ref<HTMLInputElement>,
) {
  const env = useRelayEnvironment();

  const handleQuery = useCallback(
    () =>
      fetchQuery<NatureOfWorkSubCategoryAutocompleteQuery>(
        env,
        graphql`
          query NatureOfWorkSubCategoryAutocompleteQuery(
            $suggestionPrompt: SuggestionPromptInput!
            $suggestionCount: Int!
            $suggestionScoreThreshold: Float!
            $skipSuggestion: Boolean!
          ) {
            ...NatureOfWorkSubCategoryAutocompleteFragment
              @arguments(
                suggestionPrompt: $suggestionPrompt
                suggestionCount: $suggestionCount
                suggestionScoreThreshold: $suggestionScoreThreshold
                skipSuggestion: $skipSuggestion
              )
          }
        `,
        {
          suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
          suggestionCount: 5,
          suggestionScoreThreshold: 0,
          skipSuggestion: !suggestible,
        },
      ),
    [env, suggestible, suggestionPromptInput],
  );
  return (
    <SelectPickerSuggestible
      ref={ref}
      onQuery={handleQuery}
      fragment={graphql`
        fragment NatureOfWorkSubCategoryAutocompleteFragment on Query
        @argumentDefinitions(
          suggestionPrompt: { type: "SuggestionPromptInput!" }
          suggestionCount: { type: "Int!" }
          suggestionScoreThreshold: { type: "Float!" }
          skipSuggestion: { type: "Boolean!" }
        ) {
          suggestions: suggestedNatureOfWorkSubCategories(
            prompt: $suggestionPrompt
            count: $suggestionCount
            scoreThreshold: $suggestionScoreThreshold
          ) @skip(if: $skipSuggestion) {
            score
            value @required(action: LOG)
          }
        }
      `}
      {...selectPickerProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: NatureOfWorkSubCategoryAutocompleteProps &
    ForwardNatureOfWorkSubCategoryAutocompletePops<Multiple, DisableClearable, FreeSolo, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
