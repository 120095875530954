import { QuoteStatus } from '../../__enums__/QuoteStatus';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { TransportKind } from '../../__enums__/TransportKind';

const kindAria: Record<QuoteKind, string> = {
  accessoriesRental: 'Create accessories rental quote',
  bare: 'Create bare crane quote',
  laborRental: 'Create labor rental quote',
  liftingPlan: 'Create lifting plan quote',
  liftingTest: 'Create lifting test quote',
  operatedHourly: 'Create operated crane (hourly) quote',
  operatedMonthly: 'Create operated crane (monthly) quote',
  rollingEquipment: 'Create rolling equipment quote',
};

const kindCapitalized: Record<QuoteKind, string> = {
  accessoriesRental: 'ACCESSORIES RENTAL',
  bare: 'BARE CRANE',
  laborRental: 'LABOR RENTAL',
  liftingPlan: 'LIFTING PLAN',
  liftingTest: 'LIFTING TEST',
  operatedHourly: 'OPERATED CRANE (HOURLY)',
  operatedMonthly: 'OPERATED CRANE (MONTHLY)',
  rollingEquipment: 'ROLLING EQUIPMENT',
};

export const quote = {
  addenda: 'Addenda',
  costs: 'Costs',
  button: {
    approve: 'Approve',
    decline: 'Decline',
    modify: 'Modify',
    returnToInWriting: 'Return in writing',
    rejectBySupervisor: 'Reject',
    accept: 'Accept',
  },
  createQuoteAction: {
    main: kindCapitalized.operatedHourly,
    options: kindCapitalized,
    ariaLabels: {
      main: kindAria.operatedHourly,
      more: 'Show more options',
      options: kindAria,
    },
  },
  dateTime: {
    sevenDays: '7 days',
    fourteenDays: '14 days',
    thirtyDays: '30 days',
    sixtyDays: '60 days',
  },
  dialog: {
    email: {
      title: 'Send by Email',
      sendToProjectManager: 'Project Manager',
      sendToClientRepresentative: 'Client Representative',
      sendToCurrentUser: 'Logged-in User',
      sendButton: 'Send',
      unavailable: 'unavailable',
      error: 'An error occurred while sending the email.',
    },
    accept: {
      title: 'Accept Quote',
      body: 'Are you sure you want to accept this quote? This operation is irreversible. A service call will be automatically created.',
      cancelButton: 'No',
      confirmButton: 'Yes',
    },
    decline: {
      title: 'Refuse Quote',
      body: 'Are you sure you want to decline this quote? This operation is irreversible.',
      cancelButton: 'No',
      confirmButton: 'Yes',
    },
  },
  error: {
    arrivalDateBeforeDepartureDate: 'Arrival time cannot be before departure time',
    dateInThePast: 'Date cannot be in the past',
    errorDuringDeclineByClient: 'An error occurred while declining',
    errorDuringAccept: 'An error occurred while accepting',
    errorDuringRequestChange: 'An error occurred while requesting changes',
    errorDuringRequestReview: 'An error occurred while requesting approval',
    errorDuringRejectBySupervisor: 'An error occurred while rejecting',
    errorDuringApproveBySupervisor: 'An error occurred while approving',
    errorDuringReturnToInWriting: 'An error occurred while returning to in writing',
    readyDateBeforeArrivalDate: 'Ready time cannot be before arrival time',
    clientIsRequired: 'Please select a client',
    worksiteIsRequired: 'Please select a worksite',
  },
  field: {
    accessory: {
      accessory: 'Accessory',
      billed: 'Billed',
      billingCode: 'Name of billing code',
      noAccessory: 'No Accessory',
      overline: 'Accessories',
      qty: 'Qty.',
      type: 'Accessory Type',
      billable: 'Billable',
      quantity: 'Quantity',
      rate: 'Rate',
    },
    addenda: {
      description: 'Description',
      kind: 'Type',
      title: 'Title',
    },
    cost: {
      overline: 'Costs',
      billingCode: 'Name of billing code',
      billable: 'Billable',
      crane: 'Crane',
      labor: 'Labor',
      noLine: 'No Line',
      quantity: 'Quantity',
      qty: 'Qty.',
      rate: 'Rate',
    },
    client: {
      inTheCareOf: 'I/C',
      orderNumber: 'PO #',
      projectManager: 'Project Manager',
      requirement: 'Requirement',
    },
    equipment: {
      kind: 'Equipment Kind',
      type: 'Type',
      boomConfiguration: 'Boom Configuration',
      craneConfiguration: 'Crane Configuration',
      craneSelectionMode: 'Crane Selection Mode',
      lifts: 'Lifts',
      configurationList: 'Configurations',
      configuration: 'Configuration',
      otherInformation: 'Other Information',
      transport: {
        transportKind: 'Transport Type',
        craneTransportRate: 'Primary',
        additionalCraneTransportRate: 'Additional {{ craneIndex }}',
        boomTransportRate: 'Primary',
        otherBoomTransportRate: 'Other',
      },
    },
    project: {
      branch: {
        dispatchBranch: 'Dispatch Branch',
        overline: 'Branches',
        salesBranch: 'Sales Branch',
      },
      date: {
        arrival: 'Arrival Time',
        date: 'Date',
        departure: 'Departure Time',
        duration: 'Duration of the job',
        durationPerDay: 'Duration per day',
        isSecondServing: '2nd serving',
        includeWeekendsAndHolidays: 'Includes weekends and holidays',
        notes: 'Notes and additional information',
        ready: 'Ready Time',
      },
      work: {
        isEstablishedSchedule: 'Established Schedule',
        natureOfWork: 'Nature of Work',
        natureOfWorkSubCategory: 'Category',
        overline: 'Work',
        workDescription: 'Work Description',
        workSchedule: 'Work Schedule',
      },
    },
    costs: {
      goToDesktop: 'Continue on the web application to access the quote calculator',
    },
  },
  info: 'Info',
  kind: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare Crane',
    laborRental: 'Labor Rental',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    operatedHourly: 'Operated Crane (hourly)',
    operatedMonthly: 'Operated Crane (monthly)',
    rollingEquipment: 'Rolling Equipment',
  } satisfies Record<QuoteKind, string>,
  kindShort: {
    accessoriesRental: 'Accessories Rental',
    bare: 'Bare',
    laborRental: 'Labor Rental',
    liftingPlan: 'Lifting Plan',
    liftingTest: 'Lifting Test',
    operatedHourly: 'Operated (hourly)',
    operatedMonthly: 'Operated (monthly)',
    rollingEquipment: 'Rolling Equipment',
  } satisfies Record<QuoteKind, string>,
  list: {
    column: {
      friendlyId: 'ID',
      client: 'Client',
      worksite: 'Worksite',
      kind: 'Type',
      crane: 'Crane',
      status: 'Status',
      date: 'Date',
    },
  },
  pages: {
    accessories: {
      title: 'Accessories',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda and Attachments',
    },
    costLine: {
      title: 'Cost Lines',
    },
    client: {
      title: 'Client Information',
      requirementDescriptionsTitle: 'Client requirements',
    },
    details: {
      copyTitle: 'Copy Quote',
      copyTitleShort: 'Copy',
      shortTitle: 'Edit Quote', // it already is short but needed for use as FR has short translation
      title: 'Edit Quote',
      newTitle: 'New Quote',
    },
    project: {
      title: 'Project Information',
      subsection: {
        branches: 'Branches',
        dateAndTime: 'Dates and time',
        work: 'Work',
        worksite: 'Worksite',
      },
    },
    questions: {
      title: 'Questions',
    },
    equipment: {
      title: 'Equipment Information',
      subsection: {
        craneSelector: 'Crane Selector',
      },
    },
    costs: {
      title: 'Costs Information',
      subsection: {
        additionalCranes: 'Additional Cranes',
        mainCrane: 'Main Crane',
        emptyCraneConfiguration: 'No configuration selected',
      },
    },
  },
  placeholder: {
    accessoryInfo: 'Select accessories (optional)',
    accessoryOutOfInventory: 'Write accessory',
    addAddenda: 'Add an addendum',
    addendaInfo: 'Enter addenda information (optional)',
    clientInfo: 'Enter client information',
    equipmentInfo: 'Enter equipment information',
    projectInfo: 'Enter project information',
    questionsInfo: 'Answer basic questions',
    costsInfo: 'Enter costs information',
    selectConfiguration: 'Select a configuration',
  },
  section: {
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas and attachments',
    calculator: {
      header: 'Calculator',
      action: {
        refresh: 'Recalculate',
        apply: 'Apply',
      },
    },
    client: 'Client',
    costLines: 'Cost lines',
    project: 'Project',
    questions: 'Questions',
    equipment: 'Equipment',
    accessories: 'Accessories',
    costs: 'Costs',
    transport: {
      header: 'Transport',
      craneTransportRate: 'Crane Rate',
      boomTransportRate: 'Boom Rate',
    },
  },
  quotes: 'Quotes',
  status: {
    accepted: 'Accepted',
    canceled: 'Canceled',
    inWriting: 'In Writing',
    declined: 'Declined',
    revising: 'Revising',
    inApproval: 'In Approval',
    awaitingClient: 'Awaiting Client',
    locked: 'Locked',
  } satisfies Record<QuoteStatus, string>,
  transportKind: {
    crane: 'Crane transport',
    jibAndCounterweight: 'Jib and counterweight transport',
    mobilisationAndDemobilisation: 'Mobilisation and demobilisation',
  } satisfies Record<TransportKind, string>,
};
