import {
  createFieldKey,
  useField,
  useFieldIsDirty,
  useFieldMapper,
  useFormEtag,
  useFormIsDirty,
  useFormMappings,
} from '../common/utils/forms';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import {
  arrPatchBy,
  flagRemoved,
  hasChanged,
  Patchable,
  PatchableEditProps,
  toPatchOperation,
  usePatchable,
} from '../common/utils/patchable';
import { ReactNode, useCallback, useEffect } from 'react';
import { useRenderItemById, useRenderSubFormCollection } from '../common/utils/patchableForm';
import { jobStageBaseFormContext } from './JobStageBaseFields';
import {
  additionalCranesSubFormContext,
  FieldAdditionalCranesBoomConfiguration,
  FieldAdditionalCranesCapacity,
  FieldAdditionalCranesConfigurationKind,
  FieldAdditionalCranesEquipmentKind,
  useFieldAdditionalCranesActive,
  useFieldAdditionalCranesBoomConfiguration,
  useFieldAdditionalCranesCapacity,
  useFieldAdditionalCranesConfigurationKind,
  useFieldAdditionalCranesEquipmentKind,
  useFieldAdditionalCranesId,
} from './AdditionalCranesSubFormFields';
import { useEffectEvent } from '../common/utils/effectUtils';
import { DateTime } from 'luxon';
import { parseDateTime } from '../common/utils/dateTimeUtils';
import { nanoid } from 'nanoid';
import { AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key } from './__generated__/AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment.graphql';
import { AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key } from './__generated__/AdditionalCranesFields_AdditionalCranesManualCollectionFragment.graphql';
import { useParams } from 'react-router-dom';
import { undefinedIfEmpty } from '../common/utils/formUtils';
import { PatchOperationOfSaveAdditionalConfigurationInfoInput } from '../quote/__generated__/QuoteSaveButtonMutation.graphql';

export type AdditionalCrane = Patchable<{
  id: DataID;
  // Editable fields
  deletedAt?: DateTime | null;
  capacity: FieldAdditionalCranesCapacity;
  equipmentKind: FieldAdditionalCranesEquipmentKind;
  configurationKind: FieldAdditionalCranesConfigurationKind;
  boomConfiguration: FieldAdditionalCranesBoomConfiguration;
}>;

export function keySelector(v: AdditionalCrane): string {
  return v.id;
}

const fieldAdditionalCranesManualCollectionKey = createFieldKey<AdditionalCrane[]>();
export function useFieldAdditionalCranesManualCollection(
  $key: AdditionalCranesFields_AdditionalCranesManualCollectionFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AdditionalCranesFields_AdditionalCranesManualCollectionFragment on ManualConfigurationInternal
      @argumentDefinitions(lang: { type: "String!" }) {
        additionalConfigurations {
          id
          capacity {
            capacity
            label
          }
          equipmentKind {
            id
            code
            label(lang: $lang)
          }
          configurationKind {
            id
            code
            label(lang: $lang)
          }
          boomConfiguration {
            craneConfigurationId
            id
            label
          }
          deletedAt
        }
      }
    `,
    $key,
  );

  // FIXME in GG-8492. Temporary fix for mapAll in SaveCopyButton which would not return any item since they were not dirty or new. Once we have created create endpoint in backend, remove this.
  const params = useParams();
  const isCopy = params.id === 'copy';
  const [additionalCranesManual, setAdditionalCranesManual] = useField<AdditionalCrane[]>(
    jobStageBaseFormContext,
    fieldAdditionalCranesManualCollectionKey,
    () =>
      $data?.additionalConfigurations.map(({ deletedAt, ...rest }) => ({
        ...(isCopy ? { $new: true } : {}), // FIXME in GG-8492 remove this
        deletedAt: parseDateTime(deletedAt),
        ...rest,
      })) ?? [],
  );

  const {
    append,
    replace: replaceAdditionalCraneManual,
    patch: patchAdditionalCraneManual,
  } = usePatchable(setAdditionalCranesManual, keySelector);

  const appendAdditionalCraneManual = useCallback((value: AdditionalCrane) => append({ ...value, id: nanoid() }), [append]);
  const clearAdditionalCranesManual = useCallback(
    () =>
      setAdditionalCranesManual(
        arrPatchBy(
          (v) => flagRemoved(v),
          () => true,
        ),
      ),
    [setAdditionalCranesManual],
  );

  const additionalCraneManualById = useCallback((id: DataID) => additionalCranesManual.find((a) => a.id === id), [additionalCranesManual]);

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAdditionalCranesManualCollectionKey);
  useMapper(
    (rows) => ({
      equipmentBase: {
        craneSelector: {
          manualConfiguration: {
            additionalConfigurations: additionalCraneToPatchable(rows),
          },
        },
      },
    }),
    [],
    'save',
  );
  const additionalCranesManualAreDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAdditionalCranesManualCollectionKey);

  const renderAdditionalCranesManualCollection = useRenderSubFormCollection(
    additionalCranesManual,
    setAdditionalCranesManual,
    keySelector,
    additionalCranesSubFormContext,
  );

  const renderAdditionalCranesManualItemById = useRenderItemById(
    additionalCranesManual,
    setAdditionalCranesManual,
    keySelector,
    additionalCranesSubFormContext,
  );

  return {
    additionalCranesManual,
    additionalCranesManualAreDirty,
    additionalCraneManualById,
    setAdditionalCranesManual,
    appendAdditionalCraneManual,
    replaceAdditionalCraneManual,
    patchAdditionalCraneManual,
    clearAdditionalCranesManual,
    renderAdditionalCranesManualCollection,
    renderAdditionalCranesManualItemById,
  };
}

const fieldAdditionalCranesAutomaticCollectionKey = createFieldKey<AdditionalCrane[]>();
export function useFieldAdditionalCranesAutomaticCollection(
  $key: AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment$key | null | undefined,
) {
  const $data = useFragment(
    graphql`
      fragment AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment on AutomaticConfigurationInternal
      @argumentDefinitions(lang: { type: "String!" }) {
        additionalConfigurations {
          id
          capacity {
            capacity
            label
          }
          equipmentKind {
            id
            code
            label(lang: $lang)
          }
          configurationKind {
            id
            code
            label(lang: $lang)
          }
          boomConfiguration {
            craneConfigurationId
            id
            label
          }
          deletedAt
        }
      }
    `,
    $key,
  );

  const params = useParams();
  const isCopy = params.id === 'copy';
  const [additionalCranesAutomatic, setAdditionalCranesAutomatic] = useField<AdditionalCrane[]>(
    jobStageBaseFormContext,
    fieldAdditionalCranesAutomaticCollectionKey,
    () =>
      $data?.additionalConfigurations.map(({ deletedAt, ...rest }) => ({
        ...(isCopy ? { $new: true } : {}), // FIXME in GG-8492 remove this
        deletedAt: parseDateTime(deletedAt),
        ...rest,
      })) ?? [],
  );

  const {
    append,
    replace: replaceAdditionalCraneAutomatic,
    patch: patchAdditionalCraneAutomatic,
  } = usePatchable(setAdditionalCranesAutomatic, keySelector);
  const appendAdditionalCraneAutomatic = useCallback((value: AdditionalCrane) => append({ ...value, id: nanoid() }), [append]);
  const clearAdditionalCranesAutomatic = useCallback(
    () =>
      setAdditionalCranesAutomatic(
        arrPatchBy(
          (v) => flagRemoved(v),
          () => true,
        ),
      ),
    [setAdditionalCranesAutomatic],
  );
  const additionalCraneAutomaticById = useCallback(
    (id: DataID) => additionalCranesAutomatic.find((a) => a.id === id),
    [additionalCranesAutomatic],
  );

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAdditionalCranesAutomaticCollectionKey);
  useMapper(
    (rows) => ({
      equipmentBase: {
        craneSelector: {
          automaticConfiguration: {
            additionalConfigurations: additionalCraneToPatchable(rows),
          },
        },
      },
    }),
    [],
    'save',
  );

  const additionalCranesAutomaticAreDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAdditionalCranesAutomaticCollectionKey);

  const renderAdditionalCranesAutomaticCollection = useRenderSubFormCollection(
    additionalCranesAutomatic,
    setAdditionalCranesAutomatic,
    keySelector,
    additionalCranesSubFormContext,
  );

  const renderAdditionalCranesAutomaticItemById = useRenderItemById(
    additionalCranesAutomatic,
    setAdditionalCranesAutomatic,
    keySelector,
    additionalCranesSubFormContext,
  );

  return {
    additionalCranesAutomatic,
    additionalCranesAutomaticAreDirty,
    additionalCraneAutomaticById,
    setAdditionalCranesAutomatic,
    appendAdditionalCraneAutomatic,
    replaceAdditionalCraneAutomatic,
    patchAdditionalCraneAutomatic,
    clearAdditionalCranesAutomatic,
    renderAdditionalCranesAutomaticCollection,
    renderAdditionalCranesAutomaticItemById,
  };
}

export type AdditionalCranesFields_Item_PresentFn = AdditionalCranesFields_ItemContent_PresentFn;
export function AdditionalCranesFields_Item({
  index,
  disabled,
  required,
  presentFn,
  value,
  onChange: handleChange,
  ...patchableProps
}: {
  index: number;
  id: string;
  presentFn: AdditionalCranesFields_Item_PresentFn;
  disabled: boolean;
  required: boolean;
} & PatchableEditProps<AdditionalCrane>): ReactNode {
  useFieldAdditionalCranesId(value?.id ?? 'new');

  const { mapAll } = useFormMappings(additionalCranesSubFormContext);
  const sync = useEffectEvent((formState: unknown) => {
    // Enforce a pseudo dependency on current form state since mapAll is a stable function.
    // This is usually provided as the form etag.
    if (!formState) {
      return;
    }

    // TODO: Should be done as part of the patching functions.
    //  Prevent accidental changes when the component is disabled.
    if (disabled) {
      return;
    }

    handleChange(mapAll('sync'));
  });

  const formEtag = useFormEtag(additionalCranesSubFormContext);
  const formDirty = useFormIsDirty(additionalCranesSubFormContext);
  useEffect(() => {
    if (!formDirty) {
      return;
    }

    sync(formEtag);
  }, [formDirty, formEtag, sync]);

  return (
    <AdditionalCranesFields_ItemContent
      index={index}
      presentFn={presentFn}
      disabled={disabled}
      required={required}
      value={value}
      onChange={handleChange}
      {...patchableProps}
    />
  );
}

type AdditionalCranesFields_ItemContent_PresentFn = (
  id: string,
  index: number,
  render: {
    renderAdditionalCranesCapacity: () => ReactNode;
    renderAdditionalCranesEquipmentKind: () => ReactNode;
    renderAdditionalCranesConfigurationKind: () => ReactNode;
    renderAdditionalCranesBoomConfiguration: () => ReactNode;
    renderAdditionalCranesActive: () => ReactNode;
  },
) => ReactNode;
function AdditionalCranesFields_ItemContent({
  index,
  presentFn,
  disabled,
  required,
  value,
  id,
}: {
  index: number;
  presentFn: AdditionalCranesFields_ItemContent_PresentFn;
  disabled: boolean;
  required: boolean;
} & PatchableEditProps<AdditionalCrane>) {
  const { additionalCranesActive, renderAdditionalCranesActive } = useFieldAdditionalCranesActive(value.deletedAt, disabled);
  const removed = !additionalCranesActive;

  const { renderAdditionalCranesCapacity, additionalCranesCapacity } = useFieldAdditionalCranesCapacity(
    value.capacity,
    disabled || removed,
    required,
  );
  const { renderAdditionalCranesEquipmentKind, additionalCranesEquipmentKind } = useFieldAdditionalCranesEquipmentKind(
    value.equipmentKind,
    disabled || removed,
    required,
  );
  const { renderAdditionalCranesConfigurationKind, additionalCranesConfigurationKind } = useFieldAdditionalCranesConfigurationKind(
    value.configurationKind,
    disabled || removed,
    required,
  );
  const hasSelectedRequiredFields = !!additionalCranesCapacity && !!additionalCranesEquipmentKind && !!additionalCranesConfigurationKind;
  const { renderAdditionalCranesBoomConfiguration } = useFieldAdditionalCranesBoomConfiguration(
    value.boomConfiguration,
    disabled || removed || !hasSelectedRequiredFields,
    required,
  );

  useResetAdditionalCranesBoomConfiguration(value);

  return presentFn(id, index, {
    renderAdditionalCranesCapacity: () =>
      renderAdditionalCranesCapacity(additionalCranesConfigurationKind?.code || null, additionalCranesEquipmentKind?.code ?? null, []),
    renderAdditionalCranesEquipmentKind: () =>
      renderAdditionalCranesEquipmentKind(additionalCranesCapacity?.capacity ?? null, additionalCranesConfigurationKind?.code ?? null, []),
    renderAdditionalCranesConfigurationKind: () =>
      renderAdditionalCranesConfigurationKind(additionalCranesCapacity?.capacity ?? null, additionalCranesEquipmentKind?.code ?? null, []),
    renderAdditionalCranesBoomConfiguration: () =>
      renderAdditionalCranesBoomConfiguration(
        additionalCranesCapacity?.capacity ?? 0,
        additionalCranesConfigurationKind?.code ?? 0,
        additionalCranesEquipmentKind?.code ?? 0,
      ),
    renderAdditionalCranesActive: () => renderAdditionalCranesActive(),
  });
}

function useResetAdditionalCranesBoomConfiguration(value: AdditionalCrane) {
  const { additionalCranesCapacity, additionalCranesCapacityIsDirty } = useFieldAdditionalCranesCapacity(value?.capacity, true, false);
  const { additionalCranesEquipmentKind, additionalCranesEquipmentKindIsDirty } = useFieldAdditionalCranesEquipmentKind(
    value?.equipmentKind,
    true,
    false,
  );
  const { additionalCranesConfigurationKind, additionalCranesConfigurationKindIsDirty } = useFieldAdditionalCranesConfigurationKind(
    value?.configurationKind,
    true,
    false,
  );
  const { setAdditionalCranesBoomConfiguration } = useFieldAdditionalCranesBoomConfiguration(value?.boomConfiguration, false, false);

  useEffect(() => {
    if (!additionalCranesCapacityIsDirty && !additionalCranesEquipmentKindIsDirty && !additionalCranesConfigurationKindIsDirty) {
      return;
    }

    setAdditionalCranesBoomConfiguration(null);
  }, [
    additionalCranesCapacityIsDirty,
    additionalCranesEquipmentKindIsDirty,
    additionalCranesConfigurationKindIsDirty,
    additionalCranesCapacity,
    additionalCranesEquipmentKind,
    additionalCranesConfigurationKind,
    setAdditionalCranesBoomConfiguration,
  ]);
}

function additionalCraneToPatchable(
  additionalCranes: AdditionalCrane[],
): ReadonlyArray<PatchOperationOfSaveAdditionalConfigurationInfoInput> | null | undefined {
  return undefinedIfEmpty(
    additionalCranes
      .filter((v) => hasChanged(v))
      .map((v) =>
        toPatchOperation(v, keySelector, (val) => ({
          deletedAt: val.deletedAt?.toJSON() ?? null,
          boomConfigurationId: val.boomConfiguration?.id ?? null,
          capacity: val.capacity?.capacity ?? null,
          equipmentKindCode: val.equipmentKind?.code ?? null,
          configurationKindCode: val.configurationKind?.code ?? null,
        })),
      ),
  );
}
