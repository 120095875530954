/**
 * @generated SignedSource<<c4468e2d4fd4d294ef3756f24cea23cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuoteKind = "accessoriesRental" | "bare" | "laborRental" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFieldsRequiredForQuoteKindsFragment$data = {
  readonly requiredForQuoteKinds: ReadonlyArray<QuoteKind>;
  readonly " $fragmentType": "AddendumTemplateFieldsRequiredForQuoteKindsFragment";
};
export type AddendumTemplateFieldsRequiredForQuoteKindsFragment$key = {
  readonly " $data"?: AddendumTemplateFieldsRequiredForQuoteKindsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsRequiredForQuoteKindsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFieldsRequiredForQuoteKindsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredForQuoteKinds",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "e05405accb8daf723128e58cf25b30a3";

export default node;
