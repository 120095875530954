import { WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { NatureOfWorkSubCategoryEnum } from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SalesQuestionKind } from '../../__enums__/SalesQuestionKind';
import { PowerlineProximity } from '../../__enums__/PowerlineProximity';

const button = {
  add: 'Ajouter',
  cancel: 'Annuler',
  clear: 'Effacer',
  clearFilters: 'Effacer les filtres',
  close: 'Fermer',
  confirm: 'Confirmer',
  copy: 'Copier',
  create: 'Créer',
  delay: 'Reporter',
  delete: 'Supprimer',
  done: 'Terminer',
  edit: 'Modifier',
  email: 'Courriel',
  filter: 'Filtrer',
  filters: 'Filtres',
  goBack: 'Retour',
  goHome: 'Retour à l’accueil',
  import: 'Importer',
  next: 'Suivant',
  notify: 'Notifier',
  ok: 'Ok',
  previous: 'Précédent',
  retry: 'Réessayer',
  returnToPreviousPage: 'Retour à la page précédente',
  save: 'Enregistrer',
  saveCopy: 'Enregistrer la copie',
  select: 'Sélectionner',
  submit: 'Soumettre',
  transfer: 'Transférer',
  update: 'Modifier',
};

const dateTime = {
  today: 'Aujourd’hui',
};

const field = {
  addendumTemplates: 'Addenda préconfigurés',
  duration: 'Durée',
  durationPerDay: 'Durée par jour',
  requirements: 'Exigences',
};

const placeholder = {
  deleteConfirmation: 'Voulez-vous vraiment supprimer cet élément\u00a0?',
  loading: 'Chargement…',
  noValue: 'Aucune valeur',
  noSuggestions: 'Aucune suggestion',
};

const unit = {
  full: {
    day_one: 'jour',
    day_other: 'jours',
    degree_one: 'degré',
    degree_other: 'degrés',
    hour_one: 'heure',
    hour_other: 'heures',
    pound_one: '{{ count }} livre',
    pound_other: '{{ count }} livres',
    ton_one: '{{ count }} Tonne',
    ton_other: '{{ count }} Tonnes',
    feetInches: 'pieds/pouces',
  },
  short: {
    pound: 'lb',
    ton: 'T',
  },
  mass: {
    short: {
      lbs: 'lbs',
      kg: 'kg',
    },
  },
  length: {
    short: {
      km: 'km',
      m: 'm',
      mm: 'mm',
      cm: 'cm',
      in: 'po',
      ft: 'pi',
      ftin: 'pi/po',
    },
  },
  angle: {
    short: {
      deg: '°',
    },
  },
  speed: {
    short: {
      kmh: 'km/h',
    },
  },
};

const yesNo = {
  yes: 'Oui',
  no: 'Non',
};

const workSchedules: Record<WorkScheduleEnum, string> = {
  fiveDaysEightHours: '5j x 8h',
  fiveDaysNineHours: '5j x 9h',
  fiveDaysTenHours: '5j x 10h',
  fourDaysTenHours: '4j x 10h',
  fourDaysTenHoursOneDayFiveHours: '4j x 10h + 1j x 5h',
};

const natureOfWorkSubCategories: Record<NatureOfWorkSubCategoryEnum, string> = {
  airports: 'Aéroports',
  landscaping: 'Aménagement paysager',
  antennas: 'Antennes',
  aqueductsSewer: 'Aqueduc et égouts',
  dams: 'Barrages',
  retentionBasins: 'Bassin de rétention',
  blade: 'Roulements de pales',
  cages: 'Cage de déménagement',
  powerPlants: 'Centrales',
  chambers: 'Chambres',
  demolition: 'Démolition',
  distribution: 'Distribution',
  pruning: 'Émondage',
  excavation: 'Excavation',
  gearboxes: 'Boîte de vitesses',
  generator: 'Génératrice',
  lifting: 'Levage d’équipement',
  lines: 'Lignes',
  accessory: 'Location d’accessoires',
  nonOperated: 'Location non-opérée',
  hand: 'Main',
  handling: 'Manutention',
  modules: 'Modules',
  gondola: 'Nacelle/Panier suspendu',
  newConstruction: 'Nouvelle construction/Agrandissement',
  bridges: 'Ponts/Viaducs',
  substations: 'Postes/Centrales',
  pylons: 'Pylônes',
  docks: 'Quais',
  refurbishment: 'Réfection',
  rotor: 'Rotor',
  roadsRails: 'Routes/Rails/Métro',
  shelters: 'Abris',
  towers: 'Tours',
  transformers: 'Transformateurs',
  transport: 'Transport',
  ventilationPlumbingElectrical: 'Ventilation/Plomberie/Électricité',
};

const autocomplete = {
  clearText: 'Effacer',
  closeText: 'Fermer',
  loadingText: 'Chargement…',
  noOptionsText: 'Aucune option',
  openText: 'Ouvrir',
  automaticSuggestions: 'Suggestions automatiques',
};

export const common = {
  autocomplete,
  automatic: 'Automatique',
  button,
  dateTime,
  dialog: {
    noResult: 'Aucun résultat trouvé',
  },
  displayedRows: '{{ info.from }}-{{ info.to }} de {{ info.count }}',
  errorMessages: {
    archivedError: 'La révision actuelle est archivée et en lecture seule',
    canceledError: 'La révision actuelle est annulée et en lecture seule',
    costLineIsRequired: 'Veuillez saisir une ligne de coût',
    errorOccurred: 'Une erreur s’est produite',
    errorDuringSave: 'Une erreur s’est produite pendant la sauvegarde',
    errorDuringCopy: 'Une erreur s’est produite pendant la copie',
    errorDuringCancel: 'Une erreur s’est produite pendant l’annulation',
    elementNotFound: {
      body: 'Le numéro d’identification de $t(errorMessages.elementNotFound.names.{{ element }}) n’existe pas, veuillez retourner à la page précédente.',
      names: {
        accessoryLine: 'la ligne d’accessoire',
        addendum: 'l’addendum',
        addendumTemplate: 'l’addendum préconfiguré',
        attachment: 'la pièce jointe',
        billingCodeRule: 'la règle de code de facturation',
        costLine: 'la ligne de coût',
        lift: 'levage',
        quote: 'la soumission',
        serviceCall: 'l’appel de service',
        workPlanning: 'la planification de travaux',
        rateStrategy: 'la stratégie de taux',
      },
      subtitle: 'Veuillez retourner à la page précédente',
      title: 'L’élément n’existe pas',
    },
    accessDeniedError: 'L’accès est refusé',
    entityNotFoundError: 'L’élément est introuvable',
    invalidJobStageBaseTransitionError: 'Transition de {{ typeFrom }} vers {{ typeTo }} invalide',
    invalidNumber: 'Nombre invalide',
    invalidPatchOperationError: 'Une opération sur une liste est invalide',
    jobRevisionIsNotTheLatestError: '{{ jobRevisionType }} n’est plus à jour, veuillez rafraichir la page',
    jobStageLockedError: 'La révision actuelle est verrouillée et en lecture seule',
    mustBeUnique: 'Doit être unique',
    patchOperationItemNotFoundError: 'Une opération sur une liste est invalide',
    requiredField: 'Champ requis',
    save: 'Une erreur s’est produite pendant la sauvegarde',
    somethingWentWrong: 'Un problème est survenu',
    unknownError: 'Erreur inconnue',
    unsavedChanges: 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées.',
    uploadFailed: 'Erreur lors du téléversement, veuillez réessayer plus tard.',
    validation: {
      automaticMustBeSubsetOfVisible: 'Les codes de facturations automatiques doivent provenir de la liste des codes visibles sélectionnés',
      billingCodeNotFound: 'Le code de facturation est introuvable',
      craneSelectorETagMismatch: 'Les résultats du sélecteur de grue ne sont plus à jour, veuillez les rafraichir',
      craneNotFoundOrInactive: 'La grue de la ligne de coût #{{ index }} est introuvable ou inactive',
      entityNotFound: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est introuvable',
      equal: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être égale à {{ comparisonValue }}',
      greaterThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus grande ou égale à {{ comparisonValue }}',
      invalidFavoriteETag: 'La sélection de configuration est invalide',
      invalidFileName: 'Le nom du fichier "{{ actualValue }}" est invalide',
      invalidPhoneNumber: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un numéro de téléphone valide',
      lessThan: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite que {{ comparisonValue }}',
      lessThanArrivalDate:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite que la date d\'arrivée',
      lessThanOrEqual:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite ou égale à {{ comparisonValue }}',
      lessThanOrEqualToReadyDate:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus petite ou égale à la date prête',
      maximumLength:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit avoir une longueur maximale de {{ comparisonValue }}',
      mismatchSalesRates: 'Le taux de vente d’une ligne de coût est absent ou invalide',
      mustHaveFavorite: 'Une configuration de grue préférée doit être sélectionnée',
      mustBePositiveInteger: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un entier positif',
      mustBeStrictlyPositiveInteger:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être un entier strictement positif',
      noSituationSelected: 'Une situation particulière applicable doit être sélectionnée au minimum',
      notEmpty: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être vide',
      notInThePast: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être dans le passé',
      notNull: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) ne doit pas être vide',
      shouldBeInStatus: 'Le statut doit être {{ comparisonValue }}',
      shouldBeUnderReview: 'Le statut doit être en approbation',
      shouldBeInWritingOrUnderReviewOrRevising: 'Le statut doit être en saisie, en approbation ou en revue',
      shouldBeInWritingOrWorksiteSelected: 'Le statut doit être en saisie ou chantier choisi',
      shouldBeReservedDelayedOrTransferred: 'Le statut doit être réservé, reporté ou transféré',
      ShouldBeInWritingReservedOrDelayed: 'Le statut doit être en saisie, réservé ou reporté',
      shouldNotBeTransferredOrDispatched: 'Le statut ne doit pas être transféré ou réparti',
      shouldNotBeInStatus: 'Le statut ne doit pas être $t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" })',
      shouldNotBeCanceledArchivedOrDispatched: 'Le statut ne doit pas être annulé, archivé, transféré ou réparti',
      softDeleted: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) est inactif',
      itemTypeCodeIsRequired: 'La main d’oeuvre est requise et doit être active',
      invalidPrice: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit exister dans la liste de prix',
      invalidQuantity:
        '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit être plus grand ou égal à la quantité minimum',
      notSpecified: '$t(common:propertyNames.{{ propertyName }}, { "index": "{{ index }}" }) doit avoir une valeur',
    },
  },
  field,
  fileUpload: {
    status: {
      queued: 'En attente',
      uploading: 'Téléversement',
      received: 'Reçu',
      processing: 'Traitement',
      error: 'Erreur',
      success: 'Succès',
    },
  },
  inactive: 'Inactif',
  salesQuestionKinds: {
    peopleLifting: 'Levage de personnes',
    hazardousTerrain: 'Pentes, dénivelés, chemin d’accès ou terrain est considéré à risque',
    powerlineProximity: 'Proximité de lignes électriques',
    craneOrObstacleProximity: 'Proximité d’obstacles ou de grues',
    excavationProximityLessThan10Feet: "Proximité d’une excavation - Moins de 10'",
    excavationProximityMoreThan10Feet: "Proximité d’une excavation - Plus de 10'",
    workOf80TOrMore: 'Travaux de 80T et plus',
    accidentScene: 'Scène d’accident',
    conventionalCrawlerCranesWithoutTravel: 'Grues conventionnelles sur chenilles - Sans déplacement',
    conventionalCrawlerCranesWithTravel: 'Grues conventionnelles sur chenilles - Avec déplacement',
    luffingJibOrExtensionInstallation: 'Installation d’un luffing / fix jib ou extension',
    movingCraneOrEquipmentWithoutPulling: 'Déplacement de grue ou autre équipement - Sans Tirage',
    movingCraneOrEquipmentWithPulling: 'Déplacement de grue ou autre équipement - Avec Tirage',
    tandemLiftingGuayCrane: 'Levage en tandem - Grue Guay',
    tandemLiftingClientCrane: 'Levage en tandem - Grue client',
    bargeWork: 'Travaux sur barge',
    unloadingOnQuay: 'Déchargement sur quai',
    jettyBridgeViaductDamSlabOrParkingDeck: 'Jetée, pont, viaduc, barrage, dalle ou stationnement étagé',
    windTurbines: 'Éoliens',
    clamShellBucketOrGrapple: 'Benne preneuse ou grappin',
    events: 'Événementiel',
    valueOf1MillionOrMore: 'Valeur d’un million $ et +',
    videoOrPhotoShooting: 'Tournage vidéo ou photo',
  } satisfies Record<SalesQuestionKind, string>,
  jobRevisionType: {
    quote: 'La soumission',
    serviceCall: 'L’appel de service',
    workPlanning: 'La planification de travaux',
  },
  jobStageKinds: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Grue non-opérée',
    laborRental: 'Location de main d’oeuvre',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    operatedHourly: 'Grue opérée (à l’heure)',
    operatedMonthly: 'Grue opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
  } satisfies Record<QuoteKind | ServiceCallKind, string>,
  natureOfWorkSubCategories,
  noResult: 'Aucun résultat trouvé',
  placeholder,
  propertyNames: {
    // Note: The case is important since they come as PascalCase from the backend...
    ActiveValidator: {
      AccessoryGroupId: 'L’accessoire',
      AssignedClientId: 'Le client',
      AssignedWorksiteId: 'Le chantier',
      BillingCodeId: 'Le code de facturation',
      Crane: 'La grue',
      CraneConfiguration: 'La configuration de grue',
      CraneConfigurationInfo: 'La configuration de grue',
      DispatchBranchId: 'La succursale de répartition',
      MatchingConfigurations: 'Le sélecteur de grue',
      ProjectManagerId: 'Le chargé de projet',
      SalesBranchId: 'La succursale de ventes',
    },
    Addenda: {
      Title: 'Le titre de l’addenda #{{ index }}',
      Description: 'La description de l’addenda #{{ index }}',
    },
    BoomLength: 'La longueur de flèche',
    Client: {
      InTheCareOf: 'A/S',
      OrderNumber: '# Commande',
      ProjectManagerId: 'Le chargé de projet',
    },
    ClientBase: {
      AssignedClientId: 'Le client',
      AssignedClientInfo: {
        Name: 'Le nom du client',
        'Location.Address': 'L’adresse du client',
        Contact: 'Le contact du client',
        PhoneNumber: 'Le téléphone du client',
      },
    },
    CostsBase: {
      CostLine: 'Une ligne de coût',
      CostLines: {
        Quantity: 'La case quantité de la ligne de coût #{{ index }}',
        WorkForceTypeCode: 'La case main d’oeuvre de la ligne de coût #{{ index }}',
        Rate: 'La case taux de la ligne de coût #{{ index }}',
      },
      AccessoryLines: {
        AccessoryGroupId: 'La case accessoire de la ligne d’accessoires #{{ index }}',
        BillingCodeId: 'La case facturable de la ligne d’accessoires #{{ index }}',
        Quantity: 'La case quantité de la ligne de d’accessoire #{{ index }}',
        AccessoryOutOfInventory: 'La case accessoire de la ligne d’accessoire #{{ index }}',
        Rate: 'La case taux de la ligne d’accessoire #{{ index }}',
      },
    },
    Counterweight: 'La masse du contrepoids',
    Description: 'Description',
    EquipmentBase: {
      CraneSelector: {
        AutomaticConfiguration: {
          MatchingConfigurations: 'Le sélecteur de grue',
        },
        ManualConfiguration: {
          AdditionalConfigurations: {
            Capacity: 'La capacité de la grue additionnelle',
            EquipmentKindCode: 'Le genre d’équipement de la grue additionnelle',
            ConfigurationKindCode: 'L’option de configuration de la grue additionnelle',
            BoomConfigurationId: 'La configuration de flèche de la grue additionnelle',
          },
          Configuration: {
            BoomLength: 'La longueur de flèche de la section équipement requis',
            Capacity: 'La capacité de la section équipement requis',
            ConfigurationKindCode: 'L’option de configuration de la section équipement requis',
            Counterweight: 'La valeur de contrepoids',
            EquipmentKindCode: 'Le genre d’équipement de la section équipement requis',
            MaxWeight: 'Le poids max de la section configuration',
            Radius: 'Le rayon de la section configuration',
            VehicleIds: 'La liste de modèles',
          },
          CraneConfigurations: 'Les configurations de grue',
        },
      },
    },
    JibLength: 'La longueur de fléchette',
    Project: {
      DepartureDate: 'La date de départ',
      DispatchBranchId: 'La succursale de répartition',
      NatureOfWorkCode: 'La nature du travail',
      NatureOfWorkSubCategory: 'La catégorie de la nature du travail',
      ReadyDate: 'La date prête',
      SalesBranchId: 'La succursale de ventes',
      WorkDescription: 'La description des travaux',
      WorkSchedule: 'L’horaire de travail',
      SchedulePeriod: {
        StartTime: 'L’heure de début',
        EndTime: 'L’heure de fin',
      },
    },
    ProjectBase: {
      ArrivalDate: 'La date d’arrivée',
      AssignedWorksiteId: 'Le chantier',
      AssignedWorksiteInfo: {
        Name: 'Le nom du chantier',
        'Location.Address': 'L’adresse du chantier',
        Contact: 'Le contact du chantier',
        PhoneNumber: 'Le téléphone du chantier',
      },
      Duration: {
        LengthInDays: 'La durée des travaux',
        HoursPerDay: 'La durée par jour',
      },
    },
    QuestionsBase: {
      SalesQuestions: {
        Option: 'La proximité de lignes électriques',
        Angle: 'L’angle de l’information sur l’excavation',
        Distance: 'La distance de l’information sur l’excavation',
      },
      QuestionsInternalBase: {
        HasWorksiteBeenVisited: 'Est-ce qu’une visite de chantier a été faite',
      },
    },
    RateStrategy: {
      LabelEn: 'L’étiquette anglaise',
      LabelFr: 'L’étiquette française',
    },
  },
  proximityToPowerline: {
    lessThan126KV: '125KV et moins: 3m',
    from126KVTo250KV: '126KV à 250KV: 5m',
    from251KVTo550KV: '251KV à 550KV: 8m',
    moreThan550KV: '551KV et +: 12m',
  } satisfies Record<PowerlineProximity, string>,
  requestSupport: 'Contactez l’équipe de support',
  searchResults: 'Résultats de recherche',
  suggestions: 'Suggestions',
  supportedLanguages: {
    french: 'Français',
    english: 'Anglais',
  },
  unit,
  warning: 'Avertissement',
  workSchedules,
  yesNo,
};
