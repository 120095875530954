/**
 * @generated SignedSource<<a8b366b0dde202ec14911c45c5e38913>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateDetailsPageFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFieldsKindFragment" | "AddendumTemplateFieldsRequiredForQuoteKindsFragment" | "AddendumTemplateFields_TemplateEnFragment" | "AddendumTemplateFields_TemplateFrFragment" | "AddendumTemplateFields_TitleEnFragment" | "AddendumTemplateFields_TitleFrFragment">;
  readonly " $fragmentType": "AddendumTemplateDetailsPageFragment";
};
export type AddendumTemplateDetailsPageFragment$key = {
  readonly " $data"?: AddendumTemplateDetailsPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateDetailsPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateDetailsPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TitleFrFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TitleEnFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TemplateFrFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFields_TemplateEnFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddendumTemplateFieldsRequiredForQuoteKindsFragment"
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "36b4286e56e1b0365dc50f440f06072f";

export default node;
