export const billingCodeRule = {
  dialog: {
    deleteTitle: 'Supprimer la règle',
    deleteMessage: 'Voulez-vous vraiment supprimer cette règle\u00a0?',
    confirmation: 'Ok',
  },
  newTitle: 'Nouvelle règle',
  editTitle: 'Modifier la règle',
  error: {
    errorDuringDelete: 'Une erreur s’est produite pendant la supression de la règle',
  },
  placeholder: {
    information: 'Information',
    kindListEmpty: 'Tous les types',
  },
  fields: {
    label: {
      clients: 'Clients',
      conditions: 'Conditions',
      billingCodes: 'Codes',
      description: 'Description',
      kinds: 'Types',
      capacities: 'Capacités',
      equipmentKinds: 'Genres',
      billingCodesVisible: 'Visibles',
      billingCodesAutomatic: 'Automatiques',
      natureOfWorks: 'Natures de travail',
      natureOfWorkSubCategory: 'Catégories',
      specialSituation: 'Situations particulières',
    },
    specialProjects: 'Projets spéciaux',
  },
  title: 'Règles de code de facturation',
};
