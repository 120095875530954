import graphql from 'babel-plugin-relay/macro';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { useFormMappings, useFormValidate } from '../common/utils/forms';
import { billingCodeRuleDetailsFormContext } from './BillingCodeRuleFields';
import { useCallback } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { BillingCodeRuleSaveButtonMutation, UpsertBillingCodeRuleInput } from './__generated__/BillingCodeRuleSaveButtonMutation.graphql';
import { DataID } from 'react-relay';
import { useNavigate } from 'react-router-dom';
import { resolvedLanguage } from '../i18n';
import { useErrorBanner } from '../common/components/ErrorBanner';
import { _throw } from '../common/utils/_throw';
import { NAVIGATE_PREVENT_BLOCK_STATE } from '../common/hooks/usePreventNavigationOnFormDirty';

declare module './BillingCodeRuleFields' {
  interface BillingCodeRuleFieldsMappings {
    save: UpsertBillingCodeRuleInput['request'];
  }
}

export function BillingCodeRuleSaveButton({ id }: { id: DataID | 'new' }) {
  const { t, i18n } = useAmbientTranslation();
  const navigate = useNavigate();
  const { dismissError, reportUnexpectedError, reportHandledError } = useErrorBanner();

  const [commit, isInFlight] = usePromiseMutation<BillingCodeRuleSaveButtonMutation>(graphql`
    mutation BillingCodeRuleSaveButtonMutation($lang: String!, $input: UpsertBillingCodeRuleInput!) {
      upsertBillingCodeRule(input: $input) {
        billingCodeRule {
          id
          ...BillingCodeRuleDetailsPageFormFragment @arguments(lang: $lang)
          ...BillingCodeRuleDetailsPageRootEtagFragment
          ...BillingCodeRuleListItemFragment
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
        }
      }
    }
  `);

  const { mapAll } = useFormMappings(billingCodeRuleDetailsFormContext);
  const validate = useFormValidate(billingCodeRuleDetailsFormContext);

  const handleClick = useCallback(async () => {
    if (!validate('save')) return;

    dismissError(true);

    const saveRequest = mapAll('save');

    try {
      const { response } = await commit({
        variables: { lang: resolvedLanguage(i18n), input: { id: id === 'new' ? null : id, request: saveRequest } },
      });

      if (response?.upsertBillingCodeRule.errors?.length) {
        reportHandledError(response.upsertBillingCodeRule.errors, () => t('errorMessages.save', { ns: 'common' }));
        return;
      }

      if (id === 'new' && response) {
        const newId =
          response?.upsertBillingCodeRule.billingCodeRule?.id ??
          _throw(new Error('Save mutation did not result in a valid billing code rule'));
        navigate(`/billing-code-rules/${newId}`, { replace: true, state: NAVIGATE_PREVENT_BLOCK_STATE });
      }
    } catch {
      reportUnexpectedError(() => t('errorMessages.save', { ns: 'common' }));
    }
  }, [commit, dismissError, i18n, id, mapAll, navigate, reportHandledError, reportUnexpectedError, t, validate]);
  return (
    <LoadingButton loadingPosition='start' startIcon={<SaveIcon />} onClick={handleClick} loading={isInFlight}>
      <span>{t('button.save', { ns: 'common' })}</span>
    </LoadingButton>
  );
}
