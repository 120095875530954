import {
  createFieldKey,
  createFormContext,
  FormProvider,
  SetValueFn,
  useField,
  useFieldMapper,
  useFieldValidation,
  WithFieldErrors,
} from '../common/utils/forms';
import { useCallback } from 'react';
import { Box, Chip, IconButton, TextField, TextFieldProps } from '@mui/material';
import { ForwardJobStageKindAutocompleteProps, JobStageKindAutocomplete } from '../common/components/JobStageKindAutocomplete';
import { CraneCapacityAutocomplete, ForwardCraneCapacityAutocompleteProps } from '../common/components/CraneCapacityAutocomplete';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { BillingCodeRuleFieldsDescriptionFragment$key } from './__generated__/BillingCodeRuleFieldsDescriptionFragment.graphql';
import { BillingCodeRuleFieldsKindsFragment$key } from './__generated__/BillingCodeRuleFieldsKindsFragment.graphql';
import { BillingCodeRuleFieldsBillingCodesVisibleFragment$key } from './__generated__/BillingCodeRuleFieldsBillingCodesVisibleFragment.graphql';
import { BillingCodeRuleFieldsEquipmentKindsFragment$key } from './__generated__/BillingCodeRuleFieldsEquipmentKindsFragment.graphql';
import { BillingCodeRuleFieldsCapacitiesFragment$key } from './__generated__/BillingCodeRuleFieldsCapacitiesFragment.graphql';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { CostBillingCodeAutocomplete, ForwardCostBillingCodeAutocompleteProps } from '../common/components/CostBillingCodeAutocomplete';
import {
  billingCodeRuleAutomatic_SubFormContext,
  BillingCodeRulesAutomaticRow,
  BillingCodesAutomatic_BillingCodeInput,
  BillingCodesAutomatic_WorkForceTypeInput,
  FieldBillingCodesAutomatic_BillingCodeInputType,
  FieldBillingCodesAutomatic_WorkForceTypeInputType,
  useFieldBillingCodesAutomatic_BillingCode,
  useFieldBillingCodesAutomatic_Id,
  useFieldBillingCodesAutomatic_WorkForceType,
} from './BillingCodeRuleAutomaticSubForm';
import { BillingCodeRuleFields_BillingCodeCollectionFragment$key } from './__generated__/BillingCodeRuleFields_BillingCodeCollectionFragment.graphql';
import { nanoid } from 'nanoid';
import { ResponsiveGridContent, ResponsiveGridHeader, ResponsiveGridItem } from '../common/components/ResponsiveGrid';
import { useTranslation } from 'react-i18next';
import { arrSetBy } from '../common/utils/patchable';
import { BillingCodeRuleFields_AutomaticGridFragment$key } from './__generated__/BillingCodeRuleFields_AutomaticGridFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import { UpsertAutomaticEffectInput } from './__generated__/BillingCodeRuleSaveButtonMutation.graphql';
import { BillingCodeRuleFieldsNatureOfWorkFragment$key } from './__generated__/BillingCodeRuleFieldsNatureOfWorkFragment.graphql';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../common/components/NatureOfWorkAutocomplete';
import { castNatureOfWorkSubCategoryEnum, natureOfWorkSubCategoryEnums } from '../__enums__/NatureOfWorkSubCategoryEnum';

import { BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment$key } from './__generated__/BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment.graphql';
import {
  ForwardNatureOfWorkSubCategoryAutocompletePops,
  NatureOfWorkSubCategoryAutocomplete,
} from '../common/components/NatureOfWorkSubCategoryAutocomplete';
import { SelectPicker, SelectPickerProps } from '../common/components/SelectPicker';
import { castSalesQuestionKind, SalesQuestionKind } from '../__enums__/SalesQuestionKind';
import { BillingCodeRuleFieldsQuestionsBase$key } from './__generated__/BillingCodeRuleFieldsQuestionsBase.graphql';
import { kindValues, specialProjects } from '../jobs/fields/QuestionsBaseFields';
import { BillingCodeRuleFieldsClientFragment$key } from './__generated__/BillingCodeRuleFieldsClientFragment.graphql';
import { ClientAutocomplete, ForwardClientAutocompleteProps } from '../common/components/ClientAutocomplete';

const EMPTY_VEHICLES: string[] = [];

export interface BillingCodeRuleFieldsMappings {}

export const billingCodeRuleDetailsFormContext = createFormContext<BillingCodeRuleFieldsMappings>();

const fieldDescriptionKey = createFieldKey<string>();

export function useFieldDescription($key: BillingCodeRuleFieldsDescriptionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsDescriptionFragment on BillingCodeRule {
        description
      }
    `,
    $key,
  );

  const [description, setDescription] = useField(billingCodeRuleDetailsFormContext, fieldDescriptionKey, $data?.description ?? '');

  const useValidationDescription = useFieldValidation(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useValidationDescription((v) => v.trim().length > 0, [], 'save:required');

  const useMapperDescription = useFieldMapper(billingCodeRuleDetailsFormContext, fieldDescriptionKey);
  useMapperDescription((val) => ({ description: val }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setDescription(e.target.value);
    },
    [setDescription],
  );
  const { t } = useAmbientTranslation();
  const renderDescription = useCallback(
    () => (
      <WithFieldErrors
        context={billingCodeRuleDetailsFormContext}
        fieldKey={fieldDescriptionKey}
        render={(errors) => (
          <TextField
            label={t('fields.label.description')}
            required={true}
            value={description}
            onChange={handleChange}
            error={!!errors['required']}
          />
        )}
      />
    ),
    [description, handleChange, t],
  );

  return { description, setDescription, renderDescription };
}

const fieldKindsKey = createFieldKey<string[]>();

export function useFieldKinds($key: BillingCodeRuleFieldsKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsKindsFragment on BillingCodeRule {
        conditions {
          kinds
        }
      }
    `,
    $key,
  );

  const [kinds, setKinds] = useField(billingCodeRuleDetailsFormContext, fieldKindsKey, $data?.conditions?.kinds ?? []);

  const useMapperKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldKindsKey);
  useMapperKinds((val) => ({ upsertConditions: { kinds: val } }), [], 'save');

  const handleChange = useCallback<NonNullable<ForwardJobStageKindAutocompleteProps<true, true>['onChange']>>(
    (_, value) => {
      setKinds(value);
    },
    [setKinds],
  );
  const { t } = useAmbientTranslation();
  const renderKinds = useCallback(
    () => (
      <JobStageKindAutocomplete
        multiple
        value={kinds.map((k) => k)}
        onChange={handleChange}
        textFieldProps={() => ({ label: t('fields.label.kinds') })}
      />
    ),
    [handleChange, kinds, t],
  );

  return { kinds, setKinds, renderKinds };
}

const fieldCapacitiesKey = createFieldKey<ForwardCraneCapacityAutocompleteProps<true>['value']>();

export function useFieldCapacities($key: BillingCodeRuleFieldsCapacitiesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsCapacitiesFragment on BillingCodeRule {
        conditions {
          capacities {
            capacity
            label
          }
        }
      }
    `,
    $key,
  );

  const [capacities, setCapacities] = useField(billingCodeRuleDetailsFormContext, fieldCapacitiesKey, $data?.conditions?.capacities ?? []);

  const useMapperCapacities = useFieldMapper(billingCodeRuleDetailsFormContext, fieldCapacitiesKey);
  useMapperCapacities((val) => ({ upsertConditions: { capacities: val?.map((c) => `${c.capacity}`) ?? [] } }), [], 'save');

  const handleChange = useCallback<NonNullable<ForwardCraneCapacityAutocompleteProps<true>['onChange']>>(
    (value) => {
      setCapacities(value);
    },
    [setCapacities],
  );
  const { t } = useAmbientTranslation();
  const renderCapacities = useCallback(
    () => (
      <CraneCapacityAutocomplete
        multiple
        vehicleIds={EMPTY_VEHICLES}
        equipmentKindCode={null}
        configurationKindCode={null}
        value={capacities}
        onChange={handleChange}
        textFieldProps={() => ({ label: t('fields.label.capacities') })}
      />
    ),
    [capacities, handleChange, t],
  );

  return { capacities, setCapacities, renderCapacities };
}

const fieldEquipmentKindsKey = createFieldKey<ForwardEquipmentKindAutocompleteProps<true>['value']>();

export function useFieldEquipmentKinds($key: BillingCodeRuleFieldsEquipmentKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsEquipmentKindsFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        conditions {
          equipmentKindLookups {
            id
            code
            label(lang: $lang)
          }
        }
      }
    `,
    $key,
  );

  const [equipmentKinds, setEquipmentKinds] = useField(
    billingCodeRuleDetailsFormContext,
    fieldEquipmentKindsKey,
    $data?.conditions?.equipmentKindLookups ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldEquipmentKindsKey);
  useMapperEquipmentKinds((val) => ({ upsertConditions: { equipmentKinds: val?.map((ek) => `${ek.code}`) ?? [] } }), [], 'save');

  const handleChange = useCallback<NonNullable<ForwardEquipmentKindAutocompleteProps<true>['onChange']>>(
    (value) => {
      setEquipmentKinds(value);
    },
    [setEquipmentKinds],
  );
  const { t } = useAmbientTranslation();
  const renderEquipmentKinds = useCallback(
    () => (
      <EquipmentKindAutocomplete
        multiple
        capacity={null}
        vehicleIds={EMPTY_VEHICLES}
        configurationKindCode={null}
        value={equipmentKinds}
        onChange={handleChange}
        textFieldProps={() => ({ label: t('fields.label.equipmentKinds') })}
      />
    ),
    [equipmentKinds, handleChange, t],
  );

  return { equipmentKinds, setEquipmentKinds, renderEquipmentKinds };
}

type Clients = NonNullable<ForwardClientAutocompleteProps<true>['value']>;
const fieldClientKey = createFieldKey<Clients>();
export function useFieldClients($key: BillingCodeRuleFieldsClientFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsClientFragment on BillingCodeRule {
        conditions {
          clientEntities {
            id
            name
            location {
              address
            }
            category
            number
            phoneNumber
            representative {
              name
            }
            ...CreditAlertIndicatorFragment
            deletedAt
            externalId
          }
        }
      }
    `,
    $key,
  );

  const [clients, setClients] = useField(billingCodeRuleDetailsFormContext, fieldClientKey, $data?.conditions?.clientEntities ?? []);

  const useMapperClients = useFieldMapper(billingCodeRuleDetailsFormContext, fieldClientKey);
  useMapperClients((val) => ({ upsertConditions: { clients: val?.map((client) => `${client.externalId}`) ?? [] } }), [], 'save');

  const renderClient = useCallback(() => <ClientInput value={clients} setValue={setClients} />, [clients, setClients]);

  return { clients, setClients, renderClient };
}

function ClientInput({ value, setValue }: { value: Clients; setValue: SetValueFn<Clients> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardClientAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );
  return (
    <ClientAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={`${option.number}-${option.name}`} size='small' />;
        })
      }
      textFieldProps={() => ({ label: t('fields.label.clients') })}
    />
  );
}

type NatureOfWork = NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['value']>;
const fieldNatureOfWorkKey = createFieldKey<NatureOfWork>();
export function useFieldNatureOfWorks($key: BillingCodeRuleFieldsNatureOfWorkFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsNatureOfWorkFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        conditions {
          natureOfWorkLookups {
            id
            code
            label(lang: $lang)
          }
        }
      }
    `,
    $key,
  );

  const [natureOfWorks, setNatureOfWorks] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorkKey,
    $data?.conditions?.natureOfWorkLookups ?? [],
  );

  const useMapperEquipmentKinds = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorkKey);
  useMapperEquipmentKinds((val) => ({ upsertConditions: { natureOfWorks: val?.map((noW) => `${noW.code}`) ?? [] } }), [], 'save');

  const renderNatureOfWorks = useCallback(
    () => <NatureOfWorkAutocompleteInput value={natureOfWorks} setValue={setNatureOfWorks} />,
    [natureOfWorks, setNatureOfWorks],
  );

  return { natureOfWorks, setNatureOfWorks, renderNatureOfWorks };
}

function NatureOfWorkAutocompleteInput({ value, setValue }: { value: NatureOfWork; setValue: SetValueFn<NatureOfWork> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkAutocompleteProps<true>['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <NatureOfWorkAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.natureOfWorks') })}
    />
  );
}

type NatureOfWorkSubCategories = NonNullable<ForwardNatureOfWorkSubCategoryAutocompletePops<true>['value']>;
const fieldNatureOfWorkSubCategories = createFieldKey<NatureOfWorkSubCategories>();
export function useFieldNatureOfWorkSubCategories($key: BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsNatureOfWorkSubCategoryFragment on BillingCodeRule {
        conditions {
          natureOfWorkSubCategories
        }
      }
    `,
    $key,
  );

  const [natureOfWorkSubCategories, setNatureOfWorkSubCategories] = useField(
    billingCodeRuleDetailsFormContext,
    fieldNatureOfWorkSubCategories,
    $data?.conditions.natureOfWorkSubCategories
      ? $data.conditions.natureOfWorkSubCategories.map((sc) => castNatureOfWorkSubCategoryEnum(sc))
      : [],
  );

  const useMapperNatureOfWorkSubCategories = useFieldMapper(billingCodeRuleDetailsFormContext, fieldNatureOfWorkSubCategories);
  useMapperNatureOfWorkSubCategories(
    (val) => ({ upsertConditions: { natureOfWorkSubCategories: val.map((noWSC) => `${noWSC}`) } }),
    [],
    'save',
  );

  const renderNatureOfWorkSubCategories = useCallback(
    () => <NatureOfWorkSubCategoryInput value={natureOfWorkSubCategories} setValue={setNatureOfWorkSubCategories} />,
    [natureOfWorkSubCategories, setNatureOfWorkSubCategories],
  );

  return { natureOfWorkSubCategories, setNatureOfWorkSubCategories, renderNatureOfWorkSubCategories };
}

function NatureOfWorkSubCategoryInput({
  value,
  setValue,
}: {
  value: NatureOfWorkSubCategories;
  setValue: SetValueFn<NatureOfWorkSubCategories>;
}) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkSubCategoryAutocompletePops<true>['onChange']>>(
    (v) => setValue(v),
    [setValue],
  );
  return (
    <NatureOfWorkSubCategoryAutocomplete
      multiple
      value={value}
      onChange={handleChange}
      options={natureOfWorkSubCategoryEnums}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('fields.label.natureOfWorkSubCategory'),
      })}
    />
  );
}

type QuestionsBase = NonNullable<SelectPickerProps<SalesQuestionKind, true>['value']>;
const fieldQuestionsBaseKey = createFieldKey<QuestionsBase>();
export function useFieldQuestionsBase($key: BillingCodeRuleFieldsQuestionsBase$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsQuestionsBase on BillingCodeRule {
        conditions {
          baseQuestions
        }
      }
    `,
    $key,
  );

  const [questionsBase, setQuestionsBase] = useField(
    billingCodeRuleDetailsFormContext,
    fieldQuestionsBaseKey,
    $data?.conditions.baseQuestions ? $data.conditions.baseQuestions.map((q) => castSalesQuestionKind(q)) : [],
  );

  const useMapperQuestionsBase = useFieldMapper(billingCodeRuleDetailsFormContext, fieldQuestionsBaseKey);
  useMapperQuestionsBase((val) => ({ upsertConditions: { baseQuestions: val.map((q) => `${q}`) } }), [], 'save');

  const renderQuestionsBase = useCallback(
    () => <QuestionsBaseInput value={questionsBase} setValue={setQuestionsBase} />,
    [questionsBase, setQuestionsBase],
  );

  return { questionsBase, setQuestionsBase, renderQuestionsBase };
}

function QuestionsBaseInput({ value, setValue }: { value: QuestionsBase; setValue: SetValueFn<QuestionsBase> }) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<SelectPickerProps<SalesQuestionKind, true>['onChange']>>((_, v) => setValue(v), [setValue]);
  return (
    <SelectPicker
      multiple
      value={value}
      options={kindValues}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`salesQuestionKinds.${o}`, { ns: 'common' })}
      groupBy={(o) => (specialProjects.includes(o) ? t('fields.specialProjects') : '')}
      onChange={handleChange}
      textFieldProps={() => ({
        label: t('fields.label.specialSituation'),
      })}
    />
  );
}
const fieldBillingCodesVisibleKey = createFieldKey<ForwardCostBillingCodeAutocompleteProps<true>['value']>();

export function useFieldBillingCodesVisible($key: BillingCodeRuleFieldsBillingCodesVisibleFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFieldsBillingCodesVisibleFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        effects {
          billingCodes {
            visible {
              code
              id
              label(lang: $lang)
            }
          }
        }
      }
    `,
    $key,
  );

  const codes = $data?.effects?.billingCodes?.visible ?? [];
  const initialValues = codes.filter((v): v is NonNullable<(typeof codes)[number]> => v != null);
  if (initialValues.length !== codes.length) {
    throw new Error('Unexpected null value. All billing code should be resolved correctly.');
  }

  const [billingCodesVisible, setBillingCodesVisible] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesVisibleKey,
    initialValues,
  );

  const useMapperBillingCodesVisible = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesVisibleKey);
  useMapperBillingCodesVisible((val) => ({ upsertEffects: { billingCodeIds: { visible: val?.map((bv) => bv.id) ?? [] } } }), [], 'save');

  const renderBillingCodesVisible = useCallback(
    () => <BillingCodeVisibleInput value={billingCodesVisible} setValue={setBillingCodesVisible} />,
    [billingCodesVisible, setBillingCodesVisible],
  );

  return { billingCodesVisible, setBillingCodesVisible, renderBillingCodesVisible };
}

function BillingCodeVisibleInput({
  value,
  setValue,
}: {
  value: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardCostBillingCodeAutocompleteProps<true>['value']>;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback<NonNullable<ForwardCostBillingCodeAutocompleteProps<true>['onChange']>>(
    (newValue) => {
      setValue(newValue);
    },
    [setValue],
  );

  return (
    <CostBillingCodeAutocomplete
      multiple
      value={value}
      onlyVisible={false}
      onChange={handleChange}
      textFieldProps={() => ({ label: t('fields.label.billingCodesVisible'), placeholder: undefined })}
      searchInput={{
        capacity: '',
        equipmentKindCode: '',
        jobKind: '',
        clientExternalId: '',
        natureOfWorkCode: '',
        natureOfWorkSubCategory: '',
        questionsBase: [],
      }}
    />
  );
}

const fieldBillingCodesAutomaticKey = createFieldKey<BillingCodeRulesAutomaticRow[]>();
export function useBillingCodeAutomaticCollection($key: BillingCodeRuleFields_BillingCodeCollectionFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_BillingCodeCollectionFragment on BillingCodeRule @argumentDefinitions(lang: { type: "String!" }) {
        effects {
          billingCodes {
            automatic {
              billingCode {
                id
                code
                label(lang: $lang)
              }
              workForceType {
                id
                code
                label(lang: $lang)
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection] = useField(
    billingCodeRuleDetailsFormContext,
    fieldBillingCodesAutomaticKey,
    $data?.effects?.billingCodes?.automatic.map(
      (x) =>
        ({
          id: nanoid(),
          billingCode: x.billingCode ?? undefined,
          workForceType: x.workForceType,
        }) satisfies BillingCodeRulesAutomaticRow,
    ) ?? [],
  );

  const useMapper = useFieldMapper(billingCodeRuleDetailsFormContext, fieldBillingCodesAutomaticKey);
  useMapper(
    (rows) => ({
      upsertEffects: {
        billingCodeIds: {
          automatic: rows
            .filter((x) => x.billingCode?.id != null)
            .map(
              (row) =>
                ({
                  billingCodeId: row.billingCode!.id,
                  itemTypeCode: row.workForceType?.code ?? null,
                }) satisfies UpsertAutomaticEffectInput,
            ),
        },
      },
    }),
    [],
    'save',
  );

  const replace = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      setBillingCodeAutomaticCollection(arrSetBy(row, (x) => x.id === row.id)(billingCodeAutomaticCollection));
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const append = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      setBillingCodeAutomaticCollection([...billingCodeAutomaticCollection, row]);
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  const remove = useCallback(
    (id: DataID) => {
      const index = billingCodeAutomaticCollection.findIndex((x) => x.id === id);
      if (index === -1) return;
      setBillingCodeAutomaticCollection(billingCodeAutomaticCollection.toSpliced(index, 1));
    },
    [billingCodeAutomaticCollection, setBillingCodeAutomaticCollection],
  );

  return { billingCodeAutomaticCollection, setBillingCodeAutomaticCollection, replace, append, remove };
}

export function BillingCodeAutomaticGrid({ $key }: { $key: BillingCodeRuleFields_AutomaticGridFragment$key | null | undefined }) {
  const $data = useFragment(
    graphql`
      fragment BillingCodeRuleFields_AutomaticGridFragment on BillingCodeRule {
        ...BillingCodeRuleFieldsBillingCodesVisibleFragment @arguments(lang: $lang)
        ...BillingCodeRuleFields_BillingCodeCollectionFragment @arguments(lang: $lang)
      }
    `,
    $key,
  );

  const { t } = useTranslation('serviceCall');
  const { billingCodesVisible } = useFieldBillingCodesVisible($data);
  const { billingCodeAutomaticCollection, replace, append, remove: handleRemove } = useBillingCodeAutomaticCollection($data);

  const handleRowChange = useCallback(
    (row: BillingCodeRulesAutomaticRow) => {
      if (billingCodeAutomaticCollection.find((x) => x.id === row.id)) {
        replace(row);
      } else {
        append(row);
      }
    },
    [append, billingCodeAutomaticCollection, replace],
  );

  return (
    <ResponsiveGridContent formMode gridTemplateColumns={'1fr 1fr 0.25fr'}>
      <ResponsiveGridHeader
        columnsDefinitions={[
          { id: 'billingCode', label: t('field.cost.billingCode') },
          { id: 'labor', label: t('field.cost.labor') },
          { id: 'actions', label: '' },
        ]}
        sx={{ '& > *': { px: '1rem' } }}
      />
      {billingCodeAutomaticCollection.map((automatic) => (
        <FormProvider context={billingCodeRuleAutomatic_SubFormContext} key={automatic.id}>
          <BillingCodeAutomaticRow
            id={automatic.id}
            value={automatic}
            billingCodesVisible={billingCodesVisible}
            onRowChange={handleRowChange}
            onRowDelete={handleRemove}
          />
        </FormProvider>
      ))}
      <FormProvider context={billingCodeRuleAutomatic_SubFormContext} key={`new-${billingCodeAutomaticCollection.length}`}>
        <BillingCodeAutomaticRow billingCodesVisible={billingCodesVisible} onRowChange={handleRowChange} id={null} value={null} />
      </FormProvider>
    </ResponsiveGridContent>
  );
}

function BillingCodeAutomaticRow({
  id,
  value,
  billingCodesVisible,
  onRowChange,
  onRowDelete: handleRowDelete,
}: {
  id: DataID | undefined | null;
  value: BillingCodeRulesAutomaticRow | undefined | null;
  billingCodesVisible: ForwardCostBillingCodeAutocompleteProps<true>['value'];
  onRowChange: (row: BillingCodeRulesAutomaticRow) => void;
  onRowDelete?: (id: DataID) => void;
}) {
  const { billingCodesAutomatic_Id } = useFieldBillingCodesAutomatic_Id(id ?? nanoid());
  const { billingCodesAutomatic_BillingCode, setBillingCodesAutomatic_BillingCode } = useFieldBillingCodesAutomatic_BillingCode(
    value?.billingCode,
  );
  const { billingCodesAutomatic_WorkForceType, setBillingCodesAutomatic_WorkForceType } = useFieldBillingCodesAutomatic_WorkForceType(
    value?.workForceType,
  );

  const handleBillingCodeChange = useCallback(
    (billingCode: FieldBillingCodesAutomatic_BillingCodeInputType) => {
      setBillingCodesAutomatic_WorkForceType(null);

      // Voluntary reset workforce to null
      onRowChange({ id: billingCodesAutomatic_Id, billingCode, workForceType: null });
    },
    [billingCodesAutomatic_Id, onRowChange, setBillingCodesAutomatic_WorkForceType],
  );

  const handleWorkForceTypeChange = useCallback(
    (workForceType: FieldBillingCodesAutomatic_WorkForceTypeInputType) => {
      onRowChange({ id: billingCodesAutomatic_Id, billingCode: billingCodesAutomatic_BillingCode, workForceType });
    },
    [billingCodesAutomatic_BillingCode, billingCodesAutomatic_Id, onRowChange],
  );
  const handleDelete = () => id != null && handleRowDelete?.(id);

  return (
    <ResponsiveGridItem id={billingCodesAutomatic_Id}>
      <BillingCodesAutomatic_BillingCodeInput
        billingCodesVisible={billingCodesVisible}
        value={id == null ? undefined : billingCodesAutomatic_BillingCode}
        setValue={id == null ? () => {} : setBillingCodesAutomatic_BillingCode}
        onChange={handleBillingCodeChange}
      />
      <BillingCodesAutomatic_WorkForceTypeInput
        billingCodeId={billingCodesAutomatic_BillingCode?.id}
        value={billingCodesAutomatic_WorkForceType}
        setValue={setBillingCodesAutomatic_WorkForceType}
        onChange={handleWorkForceTypeChange}
      />
      <Box>
        {id !== null && handleRowDelete && (
          <IconButton onClick={handleDelete} size='small' color='error'>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ResponsiveGridItem>
  );
}
