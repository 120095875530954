/**
 * @generated
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck


export const serviceCallStatuses = ["archived", "canceled", "delayed", "dispatched", "inWriting", "locked", "reserved", "transferred"] as const;
export type ServiceCallStatus = typeof serviceCallStatuses[number];

export function isServiceCallStatus(value: string): value is ServiceCallStatus {
  return serviceCallStatuses.includes(value)
}

export function asServiceCallStatus(value: string): ServiceCallStatus | null {
  return isServiceCallStatus(value) ? value : null;
}

export function castServiceCallStatus(value: string): ServiceCallStatus {
  if(!isServiceCallStatus(value)) {
    throw new Error(`Invalid Enum value for type "ServiceCallStatus": "${value}"`);
  }
  
  return value;
}
