import { Suspense, useCallback } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { EmptyLayout, ListLayout, SidebarContentProps } from '../layout/Layouts';
import { RequireAdmin } from '../auth/Authorization';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { namedPageTitle, usePageTitle } from '../common/hooks/usePageTitle';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { Button, Skeleton } from '@mui/material';
import { BillingCodeRuleList } from './BillingCodeRuleList';
import { BillingCodeRuleListPageContentQuery } from './__generated__/BillingCodeRuleListPageContentQuery.graphql';
import { BillingCodeRuleListPageQuery } from './__generated__/BillingCodeRuleListPageQuery.graphql';
import { ListPageErrorBoundary } from '../layout/ListPageErrorBoundary';
import { ListPageRootErrorBoundary } from '../layout/ListPageRootErrorBoundary';

export function BillingCodeRuleListPage() {
  const { t } = useAmbientTranslation();

  return (
    <ListPageErrorBoundary heading={t('title')}>
      <Suspense fallback={<EmptyLayout />}>
        <BillingCodeRuleListPageRoot />
      </Suspense>
    </ListPageErrorBoundary>
  );
}

function BillingCodeRuleListPageRoot() {
  const $data = useLazyLoadQuery<BillingCodeRuleListPageQuery>(
    graphql`
      query BillingCodeRuleListPageQuery {
        ...AuthorizationAdminFragment
        ...SidebarDrawerFragment
        ...LayoutsListLayoutFragment
        ...ListPageRootErrorBoundaryFragment
      }
    `,
    {},
  );

  const { t } = useAmbientTranslation();
  usePageTitle(namedPageTitle('sidebar.billingCodeRules'));
  const navigate = useNavigate();

  const handleCreateClick = useCallback(() => navigate('/billing-code-rules/new'), [navigate]);
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const heading = t('title');

  return (
    <ListPageRootErrorBoundary heading={heading} $key={$data}>
      <RequireAdmin $key={$data} fallback={<Navigate to='..' replace />}>
        <ListLayout
          heading={heading}
          sidebarProvider={sidebar}
          $key={$data}
          actions={<Button onClick={handleCreateClick}>{t('button.create', { ns: 'common' })}</Button>}>
          <Suspense fallback={<ContentSkeleton />}>
            <BillingCodeRuleListPageContent />
          </Suspense>
        </ListLayout>
      </RequireAdmin>
    </ListPageRootErrorBoundary>
  );
}

function BillingCodeRuleListPageContent() {
  const $data = useLazyLoadQuery<BillingCodeRuleListPageContentQuery>(
    graphql`
      query BillingCodeRuleListPageContentQuery($first: Int) {
        ...BillingCodeRuleListFragment @arguments(first: $first)
      }
    `,
    {},
    { fetchPolicy: 'store-and-network' },
  );

  return <BillingCodeRuleList $key={$data} />;
}

function ContentSkeleton() {
  return <Skeleton variant='rounded' height='30rem' />;
}
