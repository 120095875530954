import { Box, Chip, ListItemIcon, ListItemText, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataID, useFragment, useRefetchableFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { visuallyHidden } from '@mui/utils';
import { EllipsisedTypography } from '../common/components/EllipsisedTypography';
import {
  getNodeById,
  ResponsiveGrid,
  ResponsiveGridColumnDefinition,
  ResponsiveGridColumnOrderer,
  ResponsiveGridFilters,
  ResponsiveGridForwardProps,
  ResponsiveGridImperativeHandle,
  ResponsiveGridProps,
  useElementFactory,
  useSkeletonFactory,
} from '../common/components/ResponsiveGrid';
import { NullableCell } from '../common/components/NullableCell';
import { DateTime } from 'luxon';
import { dateFormat, isValidDate } from '../common/utils/dateTimeUtils';
import { useCallback, useRef } from 'react';
import { ServiceCallStatusChip } from './ServiceCallStatusChip';
import { ServiceCallListFragment$key } from './__generated__/ServiceCallListFragment.graphql';
import { ServiceCallListItemFragment$key } from './__generated__/ServiceCallListItemFragment.graphql';
import { ServiceCallJobRevisionFilterType } from './__generated__/ServiceCallListFragmentQuery.graphql';
import { castServiceCallKind, isServiceCallKind, ServiceCallKind, serviceCallKinds } from '../__enums__/ServiceCallKind';
import { ServiceCallListFiltersFragment$key } from './__generated__/ServiceCallListFiltersFragment.graphql';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ServiceCallListDelayButton } from './ServiceCallListDelayButton';
import { ServiceCallListActionsFragment$key } from './__generated__/ServiceCallListActionsFragment.graphql';
import { isServiceCallStatus, ServiceCallStatus, serviceCallStatuses } from '../__enums__/ServiceCallStatus';
import {
  ArrivalDateFilter,
  CraneCapacityFilter,
  DispatchBranchFilter,
  EquipmentKindFilter,
  JobKindFilter,
  JobStatusFilter,
  ProjectManagerFilter,
  RepresentativeFilter,
  TextSearchFilter,
} from '../jobs/JobFilters';
import { RequireWrite } from '../auth/Authorization';
import { AuthorizationWriteFragment$key } from '../auth/__generated__/AuthorizationWriteFragment.graphql';
import { languageToLocale, resolvedLanguage } from '../i18n';
import { convertToTsQuery } from '../common/utils/stringUtils';
import { ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { ForwardDispatchBranchAutocompleteProps } from '../common/components/DispatchBranchAutocomplete';
import { ForwardRepresentativeAutocompleteProps } from '../common/components/RepresentativeAutocomplete';
import { discriminate, isDefined } from '../common/utils/typeUtils';
import { SaleList_CopyButton } from '../jobs/SaleList.CopyButton';
import { useHideAccessorySection } from '../jobs/accessoryLines/AccessoryLinesFields';

const filterChipMaxWidth = '7rem';

const serviceCallListFragment = graphql`
  fragment ServiceCallListFragment on Query
  @refetchable(queryName: "ServiceCallListFragmentQuery")
  @argumentDefinitions(
    searchTerm: { type: "String" }
    representativeIds: { type: "[ID!]" }
    after: { type: "String" }
    before: { type: "String" }
    first: { type: "Int" }
    last: { type: "Int" }
    where: { type: "ServiceCallJobRevisionFilterType" }
  ) {
    searchServiceCalls(
      searchTerm: $searchTerm
      representativeIds: $representativeIds
      after: $after
      before: $before
      first: $first
      last: $last
      where: $where
      order: [
        { snapshot: { statusOrder: ASC } }
        { snapshot: { projectBase: { arrivalDate: { date: ASC } } } }
        { snapshot: { equipmentBase: { craneSelector: { favoriteConfiguration: { capacity: DESC } } } } }
        { id: ASC }
      ]
    ) {
      ...ResponsiveGridFragment
      edges {
        node {
          id
          lifeCycleBranchId
          ...ServiceCallListItemFragment
        }
      }
    }
  }
`;

export type ServiceCallSearchParamFilter = {
  searchTerm: string;
  capacity: readonly number[];
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  dispatchBranch: readonly { id: DataID }[];
  equipmentKind: readonly { code: number }[];
  kind: readonly ServiceCallKind[];
  projectManager: readonly { id: DataID }[];
  representative: readonly { id: DataID }[];
  status: readonly ServiceCallStatus[];
};

export type ServiceCallResponsiveGridFilters = {
  searchTerm: string;
  capacity: readonly number[];
  arrivalDate: {
    start: string;
    end: string;
  } | null;
  dispatchBranch: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['value']>;
  equipmentKind: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
  kind: readonly ServiceCallKind[];
  projectManager: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  representative: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  status: readonly ServiceCallStatus[];
};

export function deserializeURLParams(searchParams: URLSearchParams): ServiceCallSearchParamFilter {
  const arrivalDateStart = searchParams.get('arrivalDateStart');
  const arrivalDateEnd = searchParams.get('arrivalDateEnd');

  let arrivalDate;
  if (!isValidDate(arrivalDateStart) || !isValidDate(arrivalDateEnd)) {
    arrivalDate = null;
  } else {
    arrivalDate = { start: arrivalDateStart!, end: arrivalDateEnd! };
  }
  return {
    searchTerm: searchParams.get('searchTerm') ?? '',
    capacity: searchParams
      .getAll('capacity')
      .map((element) => parseInt(element))
      .filter((num) => !isNaN(num)),
    arrivalDate,
    dispatchBranch: searchParams.getAll('dispatchBranchId').map((b) => ({ id: b })),
    equipmentKind: searchParams
      .getAll('equipmentKindCode')
      .map((element) => parseInt(element))
      .filter((num) => !isNaN(num))
      .map((e) => ({ code: e })),
    kind: searchParams.getAll('kind').filter(isServiceCallKind),
    projectManager: searchParams.getAll('projectManagerId').map((pm) => ({ id: pm })),
    representative: searchParams.getAll('representativeId').map((rep) => ({ id: rep })),
    status: searchParams.getAll('status').filter(isServiceCallStatus),
  };
}

export function serializeFilters(filters: ServiceCallSearchParamFilter): ServiceCallJobRevisionFilterType {
  const simplify = (op: 'and' | 'or', fs: ServiceCallJobRevisionFilterType[]): ServiceCallJobRevisionFilterType => {
    const activeFilters = fs.filter((f) => Object.keys(f).length); // ignore empty filters
    return activeFilters.length > 1 ? { [op]: activeFilters } : activeFilters[0] || {};
  };
  const and = (fs: ServiceCallJobRevisionFilterType[]) => simplify('and', fs);
  const or = (fs: ServiceCallJobRevisionFilterType[]) => simplify('or', fs);

  return and([
    filters.capacity.length
      ? {
          snapshot: {
            equipmentBase: {
              craneSelector: { favoriteConfiguration: { capacity: { in: filters.capacity } } },
            },
          },
        }
      : {},
    filters.arrivalDate
      ? {
          snapshot: {
            projectBase: {
              arrivalDate: {
                date: {
                  gte: filters.arrivalDate.start,
                  lte: filters.arrivalDate.end,
                },
              },
            },
          },
        }
      : {},
    filters.dispatchBranch.length ? { snapshot: { project: { dispatchBranchId: { in: filters.dispatchBranch.map((d) => d.id) } } } } : {},
    filters.equipmentKind.length
      ? {
          snapshot: {
            equipmentBase: {
              craneSelector: {
                favoriteConfiguration: {
                  equipmentKindCode: { in: filters.equipmentKind.map((e) => e.code) },
                },
              },
            },
          },
        }
      : {},
    filters.kind.length ? { snapshot: { kind: { in: filters.kind } } } : {},
    filters.projectManager.length ? { snapshot: { client: { projectManagerId: { in: filters.projectManager.map((pm) => pm.id) } } } } : {},
    or(filters.status.map((s) => ({ snapshot: { [`${s}At`]: { neq: null } } }))),
  ]);
}

export interface ServiceCallListProps extends ResponsiveGridForwardProps {
  $key: ServiceCallListFragment$key;
  filters$key: ServiceCallListFiltersFragment$key;
  write$key: AuthorizationWriteFragment$key;
  filters: ServiceCallSearchParamFilter;
  onApplyFilters: (filters: ServiceCallResponsiveGridFilters) => void;
}

export function ServiceCallList({
  $key,
  filters$key,
  write$key,
  filters,
  onApplyFilters: handleApplyFilters,
  onItemClick,
  ...gridProps
}: ServiceCallListProps) {
  const gridRef = useRef<ResponsiveGridImperativeHandle | null>(null);

  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));

  const [data, refetch] = useRefetchableFragment(serviceCallListFragment, $key);
  const refetchFn = useCallback<ResponsiveGridProps['refetch']>(
    (vars, options) =>
      refetch(
        {
          ...vars,
          searchTerm: convertToTsQuery(filters.searchTerm),
          representativeIds: filters.representative.map((r) => r.id),
          where: serializeFilters(filters),
        },
        options,
      ),
    [refetch, filters],
  );

  const edges = data.searchServiceCalls?.edges;

  const mainColumns: ResponsiveGridColumnDefinition[] = [
    { id: 'friendlyId', label: t('list.column.friendlyId'), size: '7rem' },
    { id: 'client', label: t('list.column.client'), size: 'minmax(6rem, 2fr)' },
    { id: 'worksite', label: t('list.column.worksite'), size: 'minmax(6rem, 2fr)' },
    { id: 'kind', label: t('list.column.kind'), size: 'minmax(6rem, 1fr)' },
    { id: 'crane', label: t('list.column.crane'), size: 'auto', sx: { textAlign: 'center' } },
    { id: 'status', label: compact ? '' : t('list.column.status'), size: 'auto' },
    { id: 'date', label: t('list.column.date'), size: 'auto' },
  ];

  const allColumns: ResponsiveGridColumnDefinition[] = [
    ...mainColumns,
    {
      id: 'po',
      label: t('list.column.poNumber'),
      size: '8rem',
    },
    { id: 'actions', label: '', size: 'minmax(6rem, auto)' },
  ];

  const columns = compact ? mainColumns : allColumns;

  const rowElementFactory = useElementFactory(edges, (node, orderByColumns) => (
    <ServiceCallListRow
      $key={node}
      write$key={write$key}
      orderByColumns={orderByColumns}
      onRefresh={() => gridRef.current?.goToFirstPage()}
    />
  ));
  const listElementFactory = useElementFactory(edges, (node) => <ServiceCallListItem $key={node} />);

  const handleItemClick = useCallback((id: DataID) => onItemClick?.(getNodeById(id, edges).lifeCycleBranchId), [edges, onItemClick]);

  const rowSkeletonFactory = useSkeletonFactory(() => <RowSkeleton columns={columns} />);
  const listSkeletonFactory = useSkeletonFactory(() => <ListSkeleton />);

  return (
    data.searchServiceCalls && (
      <>
        <ServiceCallFiltersComponent $key={filters$key} filters={filters} onApplyFilters={handleApplyFilters} />
        <ResponsiveGrid
          ref={gridRef}
          connectionFragmentKey={data.searchServiceCalls}
          refetch={refetchFn}
          columnDefinitions={columns}
          rowElementFactory={rowElementFactory}
          listElementFactory={listElementFactory}
          rowSkeletonFactory={rowSkeletonFactory}
          listSkeletonFactory={listSkeletonFactory}
          listSx={{
            "&[data-mode='grid']": {
              // Adds a padding in cells to improve readability, specially when elipsing.
              'li.responsive-grid__header  > *': {
                px: '1rem',
              },
              'li:not(.responsive-grid__header)  > *': {
                px: '0.5rem',
              },
            },

            // Controls the gap between the content of list items and the status chip.
            "&[data-mode='list'] > li": {
              gap: '0.5rem',
            },
          }}
          onItemClick={handleItemClick}
          {...gridProps}
        />
      </>
    )
  );
}

export const emptyFilters: ServiceCallResponsiveGridFilters = {
  searchTerm: '',
  capacity: [],
  arrivalDate: null,
  dispatchBranch: [],
  equipmentKind: [],
  kind: [],
  projectManager: [],
  representative: [],
  status: [],
};

function ServiceCallFiltersComponent({
  $key,
  filters,
  onApplyFilters: handleApplyFilters,
}: {
  $key: ServiceCallListFiltersFragment$key;
  filters: ServiceCallSearchParamFilter;
  onApplyFilters: (filters: ServiceCallResponsiveGridFilters) => void;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  const queriedFilters = useFragment(
    graphql`
      fragment ServiceCallListFiltersFragment on Query
      @argumentDefinitions(
        dispatchBranchIds: { type: "[ID!]!" }
        equipmentKindCodes: { type: "[Int!]!" }
        projectManagerIds: { type: "[ID!]!" }
        representativeIds: { type: "[ID!]!" }
        lang: { type: "String!" }
      ) {
        selectedDispatchBranches: nodes(ids: $dispatchBranchIds) {
          __typename
          ... on Branch {
            id
            label(lang: $lang)
            deletedAt
          }
        }
        equipmentKinds(codes: $equipmentKindCodes) {
          ... on EquipmentKindLookup {
            id
            code
            label(lang: $lang)
          }
        }
        selectedProjectManagers: nodes(ids: $projectManagerIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
        selectedRepresentatives: nodes(ids: $representativeIds) {
          __typename
          ... on Representative {
            id
            label
            deletedAt
          }
        }
      }
    `,
    $key,
  );

  return (
    <ResponsiveGridFilters<ServiceCallResponsiveGridFilters>
      initialFilters={{
        ...filters,
        dispatchBranch: queriedFilters.selectedDispatchBranches.filter(isDefined).filter(discriminate('__typename', 'Branch')),
        equipmentKind: queriedFilters.equipmentKinds,
        projectManager: queriedFilters.selectedProjectManagers.filter(isDefined).filter(discriminate('__typename', 'Representative')),
        representative: queriedFilters.selectedRepresentatives.filter(isDefined).filter(discriminate('__typename', 'Representative')),
      }}
      emptyFilters={emptyFilters}
      onApply={handleApplyFilters}
      compact={compact}
      sx={{
        [theme.breakpoints.down('sm')]: { mx: '1rem' },
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-start' },
      }}
      elements={(mode, state, setState) => [
        mode === 'inline' && (
          <TextSearchFilter
            key='fts'
            value={state.searchTerm}
            placeHolder={t('search')}
            onChange={(searchTerm) => setState((prev) => ({ ...prev, searchTerm }))}></TextSearchFilter>
        ),
        mode === 'dialog' && (
          <JobKindFilter
            key='kind'
            value={state.kind}
            label={t('list.column.kind')}
            options={serviceCallKinds}
            onChange={(kind) => setState((prev) => ({ ...prev, kind }))}></JobKindFilter>
        ),
        mode === 'dialog' && (
          <CraneCapacityFilter
            key='capacity'
            value={state.capacity}
            onChange={(value) => setState((prev) => ({ ...prev, capacity: value.map((c) => c.capacity) }))}
          />
        ),
        mode === 'dialog' && (
          <EquipmentKindFilter
            key='equipmentKind'
            value={state.equipmentKind}
            onChange={(equipmentKind) => setState((prev) => ({ ...prev, equipmentKind }))}
          />
        ),
        mode === 'dialog' && (
          <JobStatusFilter
            key='status'
            value={state.status}
            options={serviceCallStatuses.filter((s) => s !== 'locked')}
            onChange={(status) => setState((prev) => ({ ...prev, status }))}
            label={t('list.column.status')}
            renderChip={(option, handleDelete) => (
              <ServiceCallStatusChip key={option} statuses={[option]} onDelete={handleDelete} sx={{ maxWidth: filterChipMaxWidth }} />
            )}
          />
        ),
        mode === 'dialog' && (
          <DispatchBranchFilter
            key='dispatchBranch'
            value={state.dispatchBranch}
            onChange={(dispatchBranch) => setState((prev) => ({ ...prev, dispatchBranch }))}
          />
        ),
        mode === 'dialog' && (
          <ProjectManagerFilter
            key='projectManager'
            value={state.projectManager}
            onChange={(projectManager) => setState((prev) => ({ ...prev, projectManager }))}
          />
        ),
        mode === 'dialog' && (
          <RepresentativeFilter
            key='representative'
            value={state.representative}
            onChange={(representative) => setState((prev) => ({ ...prev, representative }))}
          />
        ),
        mode === 'dialog' && (
          <ArrivalDateFilter
            key='arrivalDate'
            value={state.arrivalDate}
            onChange={(v: DateRange<DateTime>) => {
              const start = v[0]?.toISODate() ?? null;
              const end = v[1]?.toISODate() ?? null;

              if (!start || !end) {
                setState((prev) => ({ ...prev, arrivalDate: null }));
              } else {
                setState((prev) => ({ ...prev, arrivalDate: { start, end } }));
              }
            }}
          />
        ),
      ]}
    />
  );
}

const serviceCallListItemFragment = graphql`
  fragment ServiceCallListItemFragment on ServiceCallJobRevision {
    ...ServiceCallListActionsFragment
    id
    friendlyId
    snapshot {
      kind
      statuses
      clientBase {
        assignedClient {
          name
          category
        }
      }
      client {
        orderNumber
      }
      equipmentBase {
        craneSelector {
          favoriteConfiguration {
            capacity {
              capacity
              label
            }
            equipmentKind {
              abbreviation
            }
          }
        }
      }
      projectBase {
        assignedWorksiteInfo {
          name
        }
        arrivalDate {
          rawValue
          date
        }
      }
    }
  }
`;

export function ServiceCallListRow({
  $key,
  write$key,
  orderByColumns,
  onRefresh: handleRefresh,
}: {
  $key: ServiceCallListItemFragment$key;
  write$key: AuthorizationWriteFragment$key;
  orderByColumns: ResponsiveGridColumnOrderer;
  onRefresh: () => void;
}) {
  const { t, i18n } = useAmbientTranslation();

  const serviceCall = useFragment(serviceCallListItemFragment, $key);
  const { snapshot } = serviceCall;
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('lg'));

  const favouriteConfiguration = snapshot?.equipmentBase.craneSelector.favoriteConfiguration;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={serviceCall.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      {orderByColumns([
        <Typography key='friendlyId' variant='body2'>
          <NullableCell value={serviceCall.friendlyId?.toString()} />
        </Typography>,

        <Box key='client' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.25rem' }}>
          <EllipsisedTypography variant='body2' component='span' title={snapshot?.clientBase.assignedClient?.name}>
            <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
          </EllipsisedTypography>
          {snapshot?.clientBase.assignedClient && snapshot?.clientBase.assignedClient?.category !== 'NONE' && (
            <Chip variant='outlined' label={snapshot?.clientBase.assignedClient?.category} size='small' />
          )}
        </Box>,

        <EllipsisedTypography
          key='worksite'
          variant='body2'
          component='span'
          title={snapshot?.projectBase.assignedWorksiteInfo?.name ?? ''}>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>,

        <EllipsisedTypography key='kind' variant='body2' component='span'>
          {t(`kindShort.${snapshot?.kind}`)}
        </EllipsisedTypography>,

        <Typography key='crane' variant='body2' textAlign='center'>
          <NullableCell
            value={
              favouriteConfiguration
                ? {
                    capacity: favouriteConfiguration.capacity?.capacity,
                    equipmentKind: favouriteConfiguration.equipmentKind?.abbreviation,
                  }
                : null
            }
            formatter={({ capacity, equipmentKind }) => (
              <>
                {capacity}
                {capacity && equipmentKind && <span>&ndash;</span>}
                {equipmentKind}
              </>
            )}
          />
        </Typography>,

        <Box key='status' display='flex' alignItems='center'>
          <ServiceCallStatusChip statuses={statuses} compact={compact} />
        </Box>,

        <Typography
          key='date'
          variant='body2'
          title={DateTime.fromISO(snapshot?.projectBase.arrivalDate?.rawValue ?? '')
            .setLocale(languageToLocale[resolvedLanguage(i18n)])
            .toLocaleString({ month: 'long', day: 'numeric', year: 'numeric' })}>
          <NullableCell
            formatter={(v: string) =>
              DateTime.fromISO(v).setLocale(languageToLocale[resolvedLanguage(i18n)]).toLocaleString({
                month: 'short',
                day: 'numeric',
              })
            }
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>,

        <EllipsisedTypography key='po' variant='body2' title={snapshot?.client.orderNumber || ''}>
          <NullableCell value={snapshot?.client.orderNumber} />
        </EllipsisedTypography>,

        <ServiceCallListActions $key={serviceCall} write$key={write$key} onRefresh={handleRefresh} key='actions' />,
      ])}
    </>
  );
}

function ServiceCallListActions({
  $key,
  write$key,
  onRefresh: handleRefresh,
}: {
  $key: ServiceCallListActionsFragment$key | null;
  write$key: AuthorizationWriteFragment$key;
  onRefresh: () => void;
}) {
  const $data = useFragment(
    graphql`
      fragment ServiceCallListActionsFragment on ServiceCallJobRevision {
        ...ServiceCallListDelayButtonFragment
        ...SaleList_CopyButtonFragment
        snapshot {
          kind
        }
      }
    `,
    $key,
  );

  const hideAccessories = useHideAccessorySection(castServiceCallKind($data?.snapshot?.kind ?? ''));

  return (
    // need to fallback on an empty element to keep grid columns from displaying correctly
    <RequireWrite $key={write$key} fallback={<div />}>
      <Stack direction='row'>
        <ServiceCallListDelayButton fragmentKey={$data} onRefresh={handleRefresh} />
        <SaleList_CopyButton $key={$data} hideAccessories={hideAccessories} />
      </Stack>
    </RequireWrite>
  );
}

export function ServiceCallListItem({ $key }: { $key: ServiceCallListItemFragment$key }) {
  const { t } = useAmbientTranslation();

  const serviceCall = useFragment(serviceCallListItemFragment, $key);
  const { snapshot } = serviceCall;

  const capacityInTons = snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.capacity?.capacity ?? 0;
  const statuses = snapshot?.statuses.every(isServiceCallStatus) ? (snapshot?.statuses ?? []) : [];

  return (
    <>
      <h3 style={visuallyHidden}>
        <NullableCell value={serviceCall.friendlyId?.toString()} />
        <br />
        <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        <br />
        <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
      </h3>

      <ListItemText disableTypography={true}>
        <Typography color={'text.secondary'} fontSize='0.75rem'>
          <NullableCell value={serviceCall.friendlyId?.toString()} />
          <span> | </span>
          <NullableCell
            formatter={(v: string) => DateTime.fromISO(v).toFormat(dateFormat)}
            value={snapshot?.projectBase.arrivalDate?.rawValue}
          />
        </Typography>
        <EllipsisedTypography variant='subtitle2' component='p' color={'text.primary'}>
          <NullableCell value={snapshot?.clientBase.assignedClient?.name} />
        </EllipsisedTypography>
        <EllipsisedTypography variant='subtitle2' component='p' color={'text.primary'}>
          <NullableCell value={snapshot?.projectBase.assignedWorksiteInfo?.name} />
        </EllipsisedTypography>
        <Typography variant='body2' component='span' color={'text.secondary'}>
          {t('unit.full.ton', { ns: 'common', count: capacityInTons })}
          &nbsp;
          {snapshot?.equipmentBase.craneSelector.favoriteConfiguration?.equipmentKind?.abbreviation ?? ''}
        </Typography>
      </ListItemText>
      <ListItemIcon>{<ServiceCallStatusChip statuses={statuses} />}</ListItemIcon>
    </>
  );
}

function RowSkeleton({ columns }: { columns: ResponsiveGridColumnDefinition[] }) {
  return (
    <span style={{ gridColumn: `1 / span ${columns.length}` }}>
      <Skeleton variant='rounded' height='1.5rem' sx={{ my: '0.875rem' }} />
    </span>
  );
}

function ListSkeleton() {
  return (
    <>
      <ListItemText>
        <Skeleton variant='rounded' width='6rem' height='1rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='14rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='10rem' height='1.25rem' sx={{ mb: '0.25rem' }} />
        <Skeleton variant='rounded' width='6rem' height='1rem' />
      </ListItemText>
      <ListItemIcon>
        <Skeleton variant='rounded' width='4.5rem' height='1.5rem' sx={{ borderRadius: '1rem' }} />
      </ListItemIcon>
    </>
  );
}
