import {
  createFieldKey,
  createFormContext,
  FieldKey,
  useField,
  useFieldErrors,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { TextField, TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { AddendumKind, addendumKinds, isAddendumKind } from '../../__enums__/AddendumKind';
import { SelectPicker, SelectPickerProps } from '../../common/components/SelectPicker';
import { castQuoteKind, isQuoteKind, QuoteKind, quoteKinds } from '../../__enums__/QuoteKind';
import { AddendumTemplateFieldsRequiredForQuoteKindsFragment$key } from './__generated__/AddendumTemplateFieldsRequiredForQuoteKindsFragment.graphql';
import { AddendumTemplateFieldsKindFragment$key } from './__generated__/AddendumTemplateFieldsKindFragment.graphql';
import { AddendumTemplateFields_TitleFrFragment$key } from './__generated__/AddendumTemplateFields_TitleFrFragment.graphql';
import { AddendumTemplateFields_TitleEnFragment$key } from './__generated__/AddendumTemplateFields_TitleEnFragment.graphql';
import { AddendumTemplateFields_TemplateFrFragment$key } from './__generated__/AddendumTemplateFields_TemplateFrFragment.graphql';
import { AddendumTemplateFields_TemplateEnFragment$key } from './__generated__/AddendumTemplateFields_TemplateEnFragment.graphql';

/** Maximum length of an addendum template's description. */
export const MAXIMUM_TEMPLATE_LENGTH = 3000;

export interface AddendumTemplateFieldsMappings {}

export const addendumTemplateFormContext = createFormContext<AddendumTemplateFieldsMappings>();

const fieldTitleFrKey = createFieldKey<string>();
export function useAddendumTemplateTitleFr($key: AddendumTemplateFields_TitleFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleFrFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleFrKey, $data?.title.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleFrKey);
  useMapper((val) => ({ titleFr: val }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );

  const renderAddendumTemplateTitleFr = useCallback(
    () => <TitleInput fieldKey={fieldTitleFrKey} onChange={handleChange} value={title} labelKey={'addenda.field.titleFr'} />,
    [handleChange, title],
  );

  return { titleFr: title, setTitleFr: setTitle, renderAddendumTemplateTitleFr };
}

const fieldTitleEnKey = createFieldKey<string>();
export function useAddendumTemplateTitleEn($key: AddendumTemplateFields_TitleEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TitleEnFragment on AddendumTemplate {
        title
      }
    `,
    $key,
  );

  const [title, setTitle] = useField(addendumTemplateFormContext, fieldTitleEnKey, $data?.title.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTitleEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTitleEnKey);
  useMapper((val) => ({ titleEn: val }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTitle(e.target.value);
    },
    [setTitle],
  );

  const renderAddendumTemplateTitleEn = useCallback(
    () => <TitleInput fieldKey={fieldTitleEnKey} onChange={handleChange} value={title} labelKey={'addenda.field.titleEn'} />,
    [handleChange, title],
  );

  return { titleEn: title, setTitleEn: setTitle, renderAddendumTemplateTitleEn };
}
function TitleInput({
  fieldKey,
  onChange: handleChange,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return <TextField label={t(labelKey)} required value={value} onChange={handleChange} error={!!errors['required']} />;
}

const fieldTemplateFrKey = createFieldKey<string>();
export function useAddendumTemplateTemplateFr($key: AddendumTemplateFields_TemplateFrFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateFrFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateFrKey, $data?.template.fr ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateFrKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateFrKey);
  useMapper((val) => ({ templateFr: val }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTemplate(e.target.value);
    },
    [setTemplate],
  );

  const renderAddendumTemplateTemplateFr = useCallback(
    () => <TemplateInput fieldKey={fieldTemplateFrKey} value={template} onChange={handleChange} labelKey={'addenda.field.templateFr'} />,
    [template, handleChange],
  );

  return { templateFr: template, setTemplateFr: setTemplate, renderAddendumTemplateTemplateFr };
}

const fieldTemplateEnKey = createFieldKey<string>();
export function useAddendumTemplateTemplateEn($key: AddendumTemplateFields_TemplateEnFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFields_TemplateEnFragment on AddendumTemplate {
        template
      }
    `,
    $key,
  );

  const [template, setTemplate] = useField(addendumTemplateFormContext, fieldTemplateEnKey, $data?.template.en ?? '');
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldTemplateEnKey);
  useValidation((v) => v.trim().length > 0, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldTemplateEnKey);
  useMapper((val) => ({ templateEn: val }), [], 'save');

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setTemplate(e.target.value);
    },
    [setTemplate],
  );

  const renderAddendumTemplateTemplateEn = useCallback(
    () => <TemplateInput fieldKey={fieldTemplateEnKey} value={template} onChange={handleChange} labelKey={'addenda.field.templateEn'} />,
    [template, handleChange],
  );

  return { templateEn: template, setTemplateEn: setTemplate, renderAddendumTemplateTemplateEn };
}

function TemplateInput({
  fieldKey,
  onChange: handleChange,
  value,
  labelKey,
}: {
  fieldKey: FieldKey<string>;
  onChange: TextFieldProps['onChange'];
  value: string;
  labelKey: string;
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKey);

  return (
    <TextField
      onChange={handleChange}
      required
      multiline
      minRows={3}
      maxRows={10}
      value={value}
      inputProps={{ maxLength: MAXIMUM_TEMPLATE_LENGTH }}
      error={!!errors['required']}
      label={t(labelKey)}
    />
  );
}

const fieldKindKey = createFieldKey<AddendumKind>();

export function useAddendumTemplateKind($key: AddendumTemplateFieldsKindFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsKindFragment on AddendumTemplate {
        kind
      }
    `,
    $key,
  );

  const [kind, setKind] = useField(
    addendumTemplateFormContext,
    fieldKindKey,
    $data?.kind ? (isAddendumKind($data?.kind) ? $data?.kind : null) : null,
  );
  const useValidation = useFieldValidation(addendumTemplateFormContext, fieldKindKey);
  useValidation((v) => !!v, [], 'save:required');

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldKindKey);
  useMapper((val) => ({ kind: val }), [], 'save');

  const handleChange = useCallback<NonNullable<SelectPickerProps<AddendumKind>['onChange']>>(
    (_, v) => {
      setKind(v);
    },
    [setKind],
  );

  const renderAddendumTemplateKind = useCallback(() => <KindInput value={kind} onChange={handleChange} />, [kind, handleChange]);

  return { kind, setKind, renderAddendumTemplateKind };
}

function KindInput({
  value,
  onChange: handleChange,
}: {
  value: AddendumKind | null;
  onChange: SelectPickerProps<AddendumKind>['onChange'];
}) {
  const { t } = useAmbientTranslation();
  const errors = useFieldErrors(addendumTemplateFormContext, fieldKindKey);

  return (
    <SelectPicker
      value={value}
      onChange={handleChange}
      options={addendumKinds.filter((a) => a !== 'archived')}
      getOptionKey={(o) => o}
      getOptionLabel={(option) => t(`addendumKind.${option}`, { ns: 'jobs' })}
      textFieldProps={(params) => ({
        ...params,
        error: !!errors['required'],
        required: true,
        label: t('addenda.field.kind'),
      })}
    />
  );
}

const fieldRequiredForQuoteKindsKey = createFieldKey<ReadonlyArray<QuoteKind>>();

export function useAddendumTemplateRequiredForQuoteKinds($key: AddendumTemplateFieldsRequiredForQuoteKindsFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AddendumTemplateFieldsRequiredForQuoteKindsFragment on AddendumTemplate {
        requiredForQuoteKinds
      }
    `,
    $key,
  );

  const [requiredForQuoteKinds, setRequiredForQuoteKinds] = useField(
    addendumTemplateFormContext,
    fieldRequiredForQuoteKindsKey,
    $data?.requiredForQuoteKinds.filter((k) => isQuoteKind(k)).map((k) => castQuoteKind(k)) ?? [],
  );

  const useMapper = useFieldMapper(addendumTemplateFormContext, fieldRequiredForQuoteKindsKey);
  useMapper((val) => ({ requiredForQuoteKinds: val }), [], 'save');

  const handleChange = useCallback<NonNullable<SelectPickerProps<QuoteKind, true>['onChange']>>(
    (_, v) => {
      setRequiredForQuoteKinds(v);
    },
    [setRequiredForQuoteKinds],
  );

  const renderAddendumTemplateRequiredForQuoteKinds = useCallback(
    () => <RequiredForQuoteKindsInput value={requiredForQuoteKinds} onChange={handleChange} />,
    [requiredForQuoteKinds, handleChange],
  );

  return { requiredForQuoteKinds, setRequiredForQuoteKinds, renderAddendumTemplateRequiredForQuoteKinds };
}

function RequiredForQuoteKindsInput({
  value,
  onChange: handleChange,
}: {
  value: ReadonlyArray<QuoteKind>;
  onChange: SelectPickerProps<QuoteKind, true>['onChange'];
}) {
  const { t } = useAmbientTranslation();

  return (
    <SelectPicker
      multiple
      value={value}
      onChange={handleChange}
      options={quoteKinds}
      getOptionKey={(o) => o}
      getOptionLabel={(option) => t(`kind.${option}`, { ns: 'quote' })}
      textFieldProps={(params) => ({
        ...params,
        label: t('addenda.field.requiredForQuoteKinds'),
      })}
    />
  );
}
