import { Chip, Collapse, IconButton, InputAdornment, Paper, SxProps, TextField, Theme, useMediaQuery, useTheme } from '@mui/material';
import { CraneCapacityAutocomplete } from '../common/components/CraneCapacityAutocomplete';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { DateTime } from 'luxon';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange, MobileDateRangePicker, SingleInputDateRangeField, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { CustomActionBar } from '../common/components/MuiDatePicker';
import { dateFormat } from '../common/utils/dateTimeUtils';
import { useTranslation } from 'react-i18next';
import { ForwardRepresentativeAutocompleteProps, RepresentativeAutocomplete } from '../common/components/RepresentativeAutocomplete';
import { EquipmentKindAutocomplete, ForwardEquipmentKindAutocompleteProps } from '../common/components/EquipmentKindAutocomplete';
import { DispatchBranchAutocomplete, ForwardDispatchBranchAutocompleteProps } from '../common/components/DispatchBranchAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { SelectPicker } from '../common/components/SelectPicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSearchParams } from 'react-router-dom';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { JobFilters_useJobFiltersSearchParamsFragment$key } from './__generated__/JobFilters_useJobFiltersSearchParamsFragment.graphql';

const filterChipMaxWidth = '7rem';

// Used to prevent re-render of the autocomplete using the vehicleIds (Capacity & ConfigurationKind)
const emptyVehicleIds: string[] = [];

export function TextSearchFilter({
  value,
  placeHolder,
  onChange: handleChange,
}: {
  value: string;
  placeHolder: string;
  onChange: (value: string) => void;
}) {
  const theme = useTheme();

  return (
    <TextField
      sx={{ [theme.breakpoints.up('sm')]: { width: '24rem' } }}
      value={value}
      placeholder={placeHolder}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment position='end'>
            <IconButton onClick={() => handleChange('')}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}></TextField>
  );
}

export function JobKindFilter<T extends string>({
  value,
  options,
  label,
  onChange: handleChange,
  sx,
}: {
  value: readonly T[];
  options: readonly T[];
  label: string;
  onChange: (kind: readonly T[]) => void;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <SelectPicker
      multiple
      value={value}
      limitTags={compact ? -1 : 1}
      sx={sx}
      onChange={(_, newValue) => handleChange(newValue)}
      options={options}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`kind.${o}`)}
      textFieldProps={(params) => ({ ...params, label })}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index > 0 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return (
            <Chip
              key={option}
              onDelete={handleDelete}
              label={t(`kindShort.${option}`)}
              size='small'
              sx={{ maxWidth: filterChipMaxWidth }}
            />
          );
        })
      }
    />
  );
}

export function JobStatusFilter<T extends string>({
  value,
  options,
  onChange: handleChange,
  label,
  renderChip,
}: {
  value: readonly T[];
  options: readonly T[];
  onChange: (status: readonly T[]) => void;
  label: string;
  renderChip: (status: T, onDelete: (event: unknown) => void) => ReactNode;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <SelectPicker
      multiple
      value={value}
      limitTags={compact ? -1 : 1}
      onChange={(_, status) => handleChange(status)}
      options={options}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`status.${o}`)}
      textFieldProps={(params) => ({ ...params, label })}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index > 0 && !compact) return null;
          return renderChip(option, getTagProps({ index }).onDelete);
        })
      }
    />
  );
}

type CraneCapacityFilterProps = {
  value: readonly number[];
  onChange: (value: readonly { capacity: number }[]) => void;
  sx?: SxProps;
};

export function CraneCapacityFilter({ value, onChange: handleChange, sx }: CraneCapacityFilterProps) {
  const { t } = useTranslation('serviceCall');
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <CraneCapacityAutocomplete
      key='capacity'
      value={value.map((v) => ({ capacity: v, label: `${v}` }))}
      onChange={handleChange}
      limitTags={compact ? -1 : 1}
      sx={sx}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index > 0 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return (
            <Chip
              key={option.capacity}
              onDelete={handleDelete}
              label={option.capacity}
              size='small'
              sx={{ maxWidth: filterChipMaxWidth }}
            />
          );
        })
      }
      multiple
      vehicleIds={emptyVehicleIds}
      equipmentKindCode={null}
      configurationKindCode={null}
      textFieldProps={() => ({ label: t('field.equipment.capacity') })}
    />
  );
}

export function ArrivalDateFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: { start: string; end: string } | null;
  onChange: (value: DateRange<DateTime>) => void;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery('@media (pointer:fine)'); // https://mui.com/x/react-date-pickers/date-range-picker/ media query used by DateRangePicker to switch between Desktop and Mobile variant
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const today = DateTime.now();
  const shortcuts: PickersShortcutsItem<DateRange<DateTime>>[] = [
    {
      label: t('dateTime.sevenDays'),
      getValue: () => [today, today.plus({ day: 7 })],
    },
    {
      label: t('dateTime.fourteenDays'),
      getValue: () => [today, today.plus({ day: 14 })],
    },
    {
      label: t('dateTime.thirtyDays'),
      getValue: () => [today, today.plus({ day: 30 })],
    },
    {
      label: t('dateTime.sixtyDays'),
      getValue: () => [today, today.plus({ day: 60 })],
    },
  ];

  const pickerValue: DateRange<DateTime> = useMemo(() => {
    if (!value) return [null, null];
    return [DateTime.fromISO(value.start), DateTime.fromISO(value.end)];
  }, [value]);

  const [focused, setFocused] = useState(false);
  const handleInputFocus = useCallback(() => setFocused(true), []);
  const handlePositionChange = useCallback(
    (v: DateRange<DateTime>) => {
      if (v[0] && v[1]) {
        setFocused(false);
      }
      handleChange(v);
    },
    [handleChange],
  );

  const handleClear = useCallback(() => handleChange([null, null]), [handleChange]);
  const handleToggleCalendar = useCallback(() => setFocused((prev) => !prev), []);

  if (isDesktop || largeScreen) {
    return (
      <>
        <SingleInputDateRangeField<DateTime>
          value={pickerValue}
          onChange={handleChange}
          label={t('list.column.date')}
          onFocus={handleInputFocus}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                {value && (
                  <IconButton
                    onClick={handleClear}
                    sx={{
                      '.MuiTextField-root:not(:hover) &': {
                        display: 'none',
                      },
                    }}>
                    <CloseIcon fontSize={'small'} />
                  </IconButton>
                )}
                <IconButton onClick={handleToggleCalendar}>
                  <CalendarMonthIcon fontSize={'small'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse in={focused}>
          <Paper sx={{ mt: '-1rem', pb: '1rem' }}>
            <StaticDateRangePicker<DateTime>
              value={pickerValue}
              onChange={handlePositionChange}
              calendars={1}
              sx={{ display: 'flex', flexDirection: 'row', ...sx }}
              slotProps={{ shortcuts: { items: shortcuts }, toolbar: { hidden: true }, actionBar: { actions: [] } }}
            />
          </Paper>
        </Collapse>
      </>
    );
  }

  return (
    <MobileDateRangePicker<DateTime>
      value={pickerValue}
      onChange={handleChange}
      label={t('list.column.date')}
      slots={{
        field: SingleInputDateRangeField,
        actionBar: CustomActionBar,
      }}
      slotProps={{
        ...(!compact ? { shortcuts: { items: shortcuts } } : {}),
        actionBar: { actions: ['cancel', 'clear', 'accept'] },
        toolbar: { hidden: true },
      }}
      format={dateFormat}
      sx={sx}
    />
  );
}

export function DispatchBranchFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardDispatchBranchAutocompleteProps<true>['onChange']>;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DispatchBranchAutocomplete
      value={value}
      onChange={handleChange}
      limitTags={compact ? -1 : 1}
      sx={sx}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index >= 1 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' sx={{ maxWidth: filterChipMaxWidth }} />;
        })
      }
      textFieldProps={() => ({ label: t('field.project.branch.dispatchBranch') })}
      multiple
    />
  );
}

export function EquipmentKindFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardEquipmentKindAutocompleteProps<true>['onChange']>;
  sx?: SxProps;
}) {
  const { t } = useAmbientTranslation();
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <EquipmentKindAutocomplete
      value={value}
      onChange={handleChange}
      limitTags={compact ? -1 : 1}
      sx={sx}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index >= 1 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' sx={{ maxWidth: filterChipMaxWidth }} />;
        })
      }
      textFieldProps={(params) => ({ ...params, label: t('field.equipment.kind') })}
      multiple
      capacity={null}
      configurationKindCode={null}
      vehicleIds={emptyVehicleIds}
    />
  );
}

export function ProjectManagerFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardRepresentativeAutocompleteProps<true>['onChange']>;
  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      limitTags={compact ? -1 : 1}
      sx={sx}
      textFieldProps={() => ({ label: t('field.client.projectManager', { ns: 'serviceCall' }) })}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index >= 1 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' sx={{ maxWidth: filterChipMaxWidth }} />;
        })
      }
      multiple
    />
  );
}

export function RepresentativeFilter({
  value,
  onChange: handleChange,
  sx,
}: {
  value: NonNullable<ForwardRepresentativeAutocompleteProps<true>['value']>;
  onChange: NonNullable<ForwardRepresentativeAutocompleteProps<true>['onChange']>;

  sx?: SxProps<Theme>;
}) {
  const theme = useTheme();
  const compact = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useAmbientTranslation();

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      limitTags={compact ? -1 : 1}
      sx={sx}
      textFieldProps={() => ({ label: t('field.client.representative', { ns: 'serviceCall' }) })}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          // To avoid multiple selection creating multiline input on larger screens when input focused,
          // we only display the first chip and hide the rest since the selection is also visible in the popper.
          // On compact display, this is not an issue since it is displayed in a dialog.
          if (index >= 1 && !compact) return null;
          const handleDelete = getTagProps({ index }).onDelete;
          return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' sx={{ maxWidth: filterChipMaxWidth }} />;
        })
      }
      multiple
    />
  );
}

export function useJobFiltersSearchParams(
  $key: JobFilters_useJobFiltersSearchParamsFragment$key,
  defaultSearchParams: URLSearchParams,
  filtersDirty: boolean,
): { effectiveSearchParams: URLSearchParams } {
  const searchParamsHaveBeenSet = useRef<boolean>(false);

  const $data = useFragment(
    graphql`
      fragment JobFilters_useJobFiltersSearchParamsFragment on Employee {
        roles
      }
    `,
    $key,
  );
  const isSalesRepresentative = $data.roles.includes('salesRepresentative');
  const isSalesAdmin = $data.roles.includes('salesSupervisor');
  const hasSalesRole = isSalesAdmin || isSalesRepresentative;

  const [searchParams, setSearchParams] = useSearchParams();

  const effectiveSearchParams = useMemo(() => {
    if (searchParams.size === 0 && !filtersDirty && hasSalesRole) {
      return defaultSearchParams;
    }

    return searchParams;
  }, [defaultSearchParams, filtersDirty, hasSalesRole, searchParams]);

  useEffect(() => {
    if (searchParamsHaveBeenSet.current) return;
    setSearchParams(effectiveSearchParams);
    searchParamsHaveBeenSet.current = true;
  }, [effectiveSearchParams, setSearchParams]);

  return { effectiveSearchParams };
}
