/**
 * @generated SignedSource<<a70fba671b875def6b7a3883dc821798>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SyncableEntity = "accessoryGroup" | "accessoryType" | "billing" | "boomConfiguration" | "boomConfigurationAdditional" | "branch" | "client" | "configurationType" | "craneConfiguration" | "craneConfigurationFlatbed" | "descriptorType" | "employee" | "equipment" | "representative" | "requirement" | "workForceType" | "workSite" | "%future added value";
export type TabDataSyncResyncMutation$variables = {
  entity: SyncableEntity;
};
export type TabDataSyncResyncMutation$data = {
  readonly resyncEntity: {
    readonly boolean: boolean | null | undefined;
  };
};
export type TabDataSyncResyncMutation = {
  response: TabDataSyncResyncMutation$data;
  variables: TabDataSyncResyncMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entity"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "entity",
            "variableName": "entity"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "ResyncEntityPayload",
    "kind": "LinkedField",
    "name": "resyncEntity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boolean",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TabDataSyncResyncMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TabDataSyncResyncMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1ea8078d8cde0b139dce9c7c22caadbd",
    "id": null,
    "metadata": {},
    "name": "TabDataSyncResyncMutation",
    "operationKind": "mutation",
    "text": "mutation TabDataSyncResyncMutation(\n  $entity: SyncableEntity!\n) {\n  resyncEntity(input: {entity: $entity}) {\n    boolean\n  }\n}\n"
  }
};
})();

(node as any).hash = "f9af5fd32e5a084364cb2036e8f75fc7";

export default node;
