/**
 * @generated SignedSource<<169b867fa2c2be2147fc1a43e1fc3221>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as configurationKindLookupLabelResolverType } from "../../../__resolvers__/ConfigurationKindLookupLabelResolver";
export type SaleCraneSelectorFields_CraneConfigurationKindFragment$data = {
  readonly configurationKind: {
    readonly code: number;
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleCraneSelectorFields_CraneConfigurationKindFragment";
};
export type SaleCraneSelectorFields_CraneConfigurationKindFragment$key = {
  readonly " $data"?: SaleCraneSelectorFields_CraneConfigurationKindFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneConfigurationKindFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleCraneSelectorFields_CraneConfigurationKindFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ConfigurationKindLookup",
      "kind": "LinkedField",
      "name": "configurationKind",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "lang",
              "variableName": "lang"
            }
          ],
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ConfigurationKindLookupLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
          "path": "configurationKind.label"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};

(node as any).hash = "c66326d3c679d5a6b6c0e8c7ec166199";

export default node;
