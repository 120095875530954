/**
 * @generated SignedSource<<90f87d33ba669ad494db2ef7813508c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as branchLabelResolverType } from "../../../__resolvers__/BranchLabelResolver";
export type SaleProjectFields_DispatchBranchFragment$data = {
  readonly dispatchBranch: {
    readonly deletedAt: string | null | undefined;
    readonly id: string;
    readonly label: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "SaleProjectFields_DispatchBranchFragment";
};
export type SaleProjectFields_DispatchBranchFragment$key = {
  readonly " $data"?: SaleProjectFields_DispatchBranchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_DispatchBranchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Branch",
      "kind": "LinkedField",
      "name": "dispatchBranch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "lang",
              "variableName": "lang"
            }
          ],
          "fragment": {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BranchLabelResolver"
          },
          "kind": "RelayResolver",
          "name": "label",
          "resolverModule": require('./../../../__resolvers__/BranchLabelResolver').label,
          "path": "dispatchBranch.label"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};

(node as any).hash = "4d646262b9272f7182b3a4c135950d2e";

export default node;
