/**
 * @generated SignedSource<<6b9989aba8203ed7917ed4d53b876ea8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageFormFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageConditionsFragment" | "BillingCodeRuleDetailsPageEffectsFragment" | "BillingCodeRuleDetailsPageInformationFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageFormFragment";
};
export type BillingCodeRuleDetailsPageFormFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageFormFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageFormFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageInformationFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageConditionsFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleDetailsPageEffectsFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "98681e805ae16a3aad630550de5d81b3";

export default node;
