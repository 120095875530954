/**
 * @generated SignedSource<<12f1dc26f350596555ffd4c70b5b3c59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleFields_AutomaticGridFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsBillingCodesVisibleFragment" | "BillingCodeRuleFields_BillingCodeCollectionFragment">;
  readonly " $fragmentType": "BillingCodeRuleFields_AutomaticGridFragment";
};
export type BillingCodeRuleFields_AutomaticGridFragment$key = {
  readonly " $data"?: BillingCodeRuleFields_AutomaticGridFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFields_AutomaticGridFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleFields_AutomaticGridFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsBillingCodesVisibleFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_BillingCodeCollectionFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};
})();

(node as any).hash = "b523026670d21b51b3fbceb4798d13ae";

export default node;
