/**
 * @generated SignedSource<<03865c8bf5683135065caf7b3eb2b6c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
import { label as configurationKindLookupLabelResolverType } from "../../../__resolvers__/ConfigurationKindLookupLabelResolver";
export type CraneConfigurationKindAutocompleteFragment$data = {
  readonly searchResults: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: number;
        readonly id: string;
        readonly label: string | null | undefined;
      };
    }> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CraneConfigurationKindAutocompleteFragment";
};
export type CraneConfigurationKindAutocompleteFragment$key = {
  readonly " $data"?: CraneConfigurationKindAutocompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CraneConfigurationKindAutocompleteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "searchResults"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "capacity"
    },
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "equipmentKindCode"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "vehicleIds"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CraneConfigurationKindAutocompleteFragmentQuery.graphql')
    }
  },
  "name": "CraneConfigurationKindAutocompleteFragment",
  "selections": [
    {
      "alias": "searchResults",
      "args": [
        {
          "kind": "Variable",
          "name": "capacity",
          "variableName": "capacity"
        },
        {
          "kind": "Variable",
          "name": "equipmentKindCode",
          "variableName": "equipmentKindCode"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        },
        {
          "kind": "Variable",
          "name": "vehicleIds",
          "variableName": "vehicleIds"
        }
      ],
      "concreteType": "SearchCraneConfigurationKindsConnection",
      "kind": "LinkedField",
      "name": "__CraneConfigurationKindAutocompleteFragment_searchResults_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchCraneConfigurationKindsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ConfigurationKindLookup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "lang",
                      "variableName": "lang"
                    }
                  ],
                  "fragment": {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ConfigurationKindLookupLabelResolver"
                  },
                  "kind": "RelayResolver",
                  "name": "label",
                  "resolverModule": require('./../../../__resolvers__/ConfigurationKindLookupLabelResolver').label,
                  "path": "searchResults.edges.node.label"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "69bed0a02fab6ba558e32013229b4f3a";

export default node;
