import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import graphql from 'babel-plugin-relay/macro';
import { BillingCodeLabelResolver$key } from './__generated__/BillingCodeLabelResolver.graphql';

/**
 * @RelayResolver BillingCode.label(lang: String!): String
 * @rootFragment BillingCodeLabelResolver
 *
 * A label for BillingCodes which includes microDescription and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(billingCode$key: BillingCodeLabelResolver$key, { lang }: { lang: string }): string {
  const billingCode$data = readFragment(
    graphql`
      fragment BillingCodeLabelResolver on BillingCode {
        microDescription
        description
      }
    `,
    billingCode$key,
  );
  return billingCode$data.microDescription?.[lang] ?? billingCode$data.description[lang];
}
