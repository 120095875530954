/**
 * @generated SignedSource<<a90d2f7a50023d8249be6122d176b3b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingCodeRuleDetailsPageEffectsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleFieldsBillingCodesVisibleFragment" | "BillingCodeRuleFields_AutomaticGridFragment">;
  readonly " $fragmentType": "BillingCodeRuleDetailsPageEffectsFragment";
};
export type BillingCodeRuleDetailsPageEffectsFragment$key = {
  readonly " $data"?: BillingCodeRuleDetailsPageEffectsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingCodeRuleDetailsPageEffectsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingCodeRuleDetailsPageEffectsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "lang",
          "variableName": "lang"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFieldsBillingCodesVisibleFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingCodeRuleFields_AutomaticGridFragment"
    }
  ],
  "type": "BillingCodeRule",
  "abstractKey": null
};

(node as any).hash = "e36738fd97e465f8f755a9dc22fc7215";

export default node;
