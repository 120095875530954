import { Suspense, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyLoadQuery } from 'react-relay';
import { FormProvider } from 'react-hook-form';
import { getPatchOperationAction, useForm } from '../common/utils/formUtils';
import { ListLayout, SidebarContentProps } from '../layout/Layouts';
import { LoadingButton } from '../common/components/LoadingButton';
import { CompetitorFieldValues, CompetitorsFormValues, CompetitorsList } from './CompetitorsList';
import { ConfigurationTabs } from './ConfigurationTabs';
import graphql from 'babel-plugin-relay/macro';
import { PatchOperationAction } from '../__enums__/PatchOperationAction';
import { TabCompetitorsQuery } from './__generated__/TabCompetitorsQuery.graphql';
import { TabCompetitorsSaveMutation, UpdateCompetitorListInput } from './__generated__/TabCompetitorsSaveMutation.graphql';
import { Skeleton } from '@mui/material';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { RequireAdmin, UnauthorizedFallback } from '../auth/Authorization';
import { useOutletContext } from 'react-router-dom';
import { ConfigurationPageQuery$data } from './__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../layout/SidebarDrawer';
import { ErrorBanner, useErrorBanner } from '../common/components/ErrorBanner';

function CompetitorsContent() {
  const key = useLazyLoadQuery<TabCompetitorsQuery>(
    graphql`
      query TabCompetitorsQuery {
        ...CompetitorsListFragment
      }
    `,
    {},
  );

  return <CompetitorsList fragmentKey={key} />;
}

function CompetitorsSkeletons() {
  return (
    <>
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
      <Skeleton sx={{ fontSize: '2rem' }} />
    </>
  );
}

function mapCompetitorsToPayload(
  competitors: CompetitorFieldValues[],
  deletedCompetitors: CompetitorFieldValues[],
  isCompetitorDirty: (i: number) => boolean,
): UpdateCompetitorListInput {
  return {
    competitors: [
      ...competitors
        .filter((c, index) => {
          if (c.name === '' && c.dataId === 'new') return false; // do not send last element
          return isCompetitorDirty(index) || c.dataId === 'new';
        })
        .map((c) => {
          return {
            action: getPatchOperationAction(c.name === '', c.dataId === 'new'),
            id: c.dataId !== 'new' ? c.id : null,
            value: {
              name: c.name,
            },
          };
        }),
      ...deletedCompetitors.map((c) => {
        return {
          action: 'delete' as PatchOperationAction,
          id: c.dataId !== 'new' ? c.id : null,
          value: {
            name: c.name,
          },
        };
      }),
    ],
  };
}

export const tabCompetitorsSaveMutation = graphql`
  mutation TabCompetitorsSaveMutation($data: UpdateCompetitorListInput!) {
    updateCompetitors(input: { updateCompetitorList: $data }) {
      query {
        ...CompetitorsListFragment
      }
      errors {
        __typename
      }
    }
  }
`;

export function TabCompetitors() {
  const { t } = useTranslation('configuration');
  const methods = useForm<CompetitorsFormValues>({ defaultValues: { competitors: [], deletedCompetitors: [] } });
  const [commitSaveCompetitors, isCurrentlySaving] = usePromiseMutation<TabCompetitorsSaveMutation>(tabCompetitorsSaveMutation);
  const { reportUnexpectedError, reportHandledError } = useErrorBanner();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  const handleSubmit = useCallback(async () => {
    const data = mapCompetitorsToPayload(
      methods.getValues('competitors'),
      methods.getValues('deletedCompetitors'),
      (i) => methods.getFieldState(`competitors.${i}.name`)?.isDirty,
    );

    try {
      const { response } = await commitSaveCompetitors({
        variables: {
          data: data,
        },
      });

      if (response?.updateCompetitors?.errors?.length) {
        reportHandledError(response?.updateCompetitors?.errors, () => t('error.errorDuringDelete'));
        return;
      }

      window.location.reload();
    } catch {
      reportUnexpectedError(() => t('error.errorDuringSaveCompetitor'));
    }
  }, [methods, commitSaveCompetitors, reportHandledError, t, reportUnexpectedError]);

  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <RequireAdmin
          $key={$data}
          fallback={
            <ListLayout heading={t('configuration')} sidebarProvider={sidebar} $key={$data}>
              <UnauthorizedFallback />
            </ListLayout>
          }>
          <ListLayout
            heading={t('configuration')}
            sidebarProvider={sidebar}
            $key={$data}
            actions={
              <LoadingButton isLoading={isCurrentlySaving} type='submit'>
                {t('button.save', { ns: 'common' })}
              </LoadingButton>
            }>
            <ErrorBanner />
            <ConfigurationTabs tab='competitors'></ConfigurationTabs>

            <Suspense fallback={<CompetitorsSkeletons />}>
              <CompetitorsContent />
            </Suspense>
          </ListLayout>
        </RequireAdmin>
      </form>
    </FormProvider>
  );
}
