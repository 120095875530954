import { ElementType, forwardRef, ReactElement, Ref, RefAttributes } from 'react';
import { ChipTypeMap } from '@mui/material';
import { serviceCallKinds } from '../../__enums__/ServiceCallKind';
import { useTranslation } from 'react-i18next';
import { quoteKinds } from '../../__enums__/QuoteKind';
import { ForwardAutocompleteProps, SelectPicker, SelectPickerProps } from './SelectPicker';

export type ForwardJobStageKindAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<string, Multiple, DisableClearable, false, ChipComponent> &
    ForwardAutocompleteProps<string, Multiple, DisableClearable, false, ChipComponent>,
  'ref' | 'options' | 'getOptionLabel' | 'getOptionKey'
>;

const jobStageKinds = [...quoteKinds, ...serviceCallKinds].reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), [] as string[]);

export const JobStageKindAutocomplete = forwardRef<HTMLInputElement, ForwardJobStageKindAutocompleteProps<false, false, ElementType>>(
  function JobStageKindAutocomplete<
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
  >(props: ForwardJobStageKindAutocompleteProps<Multiple, DisableClearable, ChipComponent>, ref: Ref<HTMLInputElement>) {
    const { t } = useTranslation('common');

    return (
      <SelectPicker
        ref={ref}
        options={jobStageKinds}
        getOptionKey={(o) => o}
        getOptionLabel={(option) => t(`jobStageKinds.${option}`, { ns: 'common' })}
        {...props}
      />
    );
  },
) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardJobStageKindAutocompleteProps<Multiple, DisableClearable, ChipComponent> & RefAttributes<HTMLInputElement>,
) => ReactElement;
