import { ChangeEvent, FocusEvent, ForwardedRef, forwardRef, ReactElement, RefAttributes, useMemo } from 'react';
import { InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { AdornmentSelect } from './AdornmentSelect';
import { NumberInput } from './NumberInput';

interface Props<T> {
  supportedUnits: readonly T[];
  onScalarChange: (value: number | null, event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onUnitChange: (event: SelectChangeEvent) => void;
  fieldLabel: string;
  onBlur: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  scalar: number | undefined;
  unit: T;
  renderMenuValue: (val: T) => string;
  renderAdornmentValue: (val: T) => string;
  min?: number;
  max?: number;
  step?: number;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
}

export const UnitInput = forwardRef<HTMLTextAreaElement | HTMLInputElement, Props<string>>(function UnitInput<T extends string>(
  {
    supportedUnits,
    onScalarChange: handleScalarChange,
    fieldLabel,
    scalar,
    onBlur: handleBlur,
    unit,
    onUnitChange: handleUnitChange,
    renderMenuValue,
    renderAdornmentValue,
    min,
    max,
    step,
    required,
    disabled,
    error,
  }: Props<T>,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>,
) {
  const UnitMenu = useMemo((): JSX.Element[] => {
    return supportedUnits.map((u) => {
      return (
        <MenuItem key={u} value={u}>
          {renderMenuValue(u)}
        </MenuItem>
      );
    });
  }, [renderMenuValue, supportedUnits]);

  return (
    <NumberInput
      inputRef={ref}
      onChange={handleScalarChange}
      label={fieldLabel}
      value={scalar ?? null}
      min={min}
      max={max}
      step={step}
      required={required}
      disabled={disabled}
      onBlur={handleBlur}
      error={error}
      data-testid={'unitTextBox'}
      sx={{
        '.MuiOutlinedInput-root': {
          paddingRight: '0.25rem',
        },
      }}
      InputProps={{
        inputProps: {
          min: 0,
        },
        endAdornment: (
          <InputAdornment position={'end'}>
            <AdornmentSelect<T>
              data-testid={'unitSelect'}
              value={unit}
              defaultValue={supportedUnits[0]}
              onChange={handleUnitChange}
              variant={'standard'}
              disabled={disabled}
              renderValue={renderAdornmentValue}>
              {UnitMenu}
            </AdornmentSelect>
          </InputAdornment>
        ),
      }}
    />
  );
}) as <T extends string>(props: Props<T> & RefAttributes<HTMLTextAreaElement | HTMLInputElement>) => ReactElement;
