/**
 * @generated SignedSource<<38ad6354c71489379b67b3cf71bb5edc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquipmentKindLookupLabelResolver$data = {
  readonly code: number;
  readonly description: Record<string, string>;
  readonly " $fragmentType": "EquipmentKindLookupLabelResolver";
};
export type EquipmentKindLookupLabelResolver$key = {
  readonly " $data"?: EquipmentKindLookupLabelResolver$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquipmentKindLookupLabelResolver">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquipmentKindLookupLabelResolver",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "EquipmentKindLookup",
  "abstractKey": null
};

(node as any).hash = "09f69c2c71ed86637c1d2482d15b2820";

export default node;
