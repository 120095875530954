import { useFragment } from 'react-relay';
import { RefObject, useCallback } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { usePromiseMutation } from '../common/hooks/usePromiseMutation';
import { ServiceCallDelayDialogRef } from './ServiceCallDelayDialog';
import { Typography } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import MenuItem from '@mui/material/MenuItem';
import { resolvedLanguage } from '../i18n';
import { useErrorBanner } from '../common/components/ErrorBanner';
import { useFormMappings } from '../common/utils/forms';
import { jobStageBaseFormContext } from '../jobs/JobStageBaseFields';
import { saleFormContext } from '../jobs/SaleFields';
import { merge } from 'ts-deepmerge';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { ServiceCallDelayButtonFragment$key } from './__generated__/ServiceCallDelayButtonFragment.graphql';
import { ServiceCallDelayButtonMutation } from './__generated__/ServiceCallDelayButtonMutation.graphql';
import { useOperations } from '../AppSharedState';
import { SaveServiceCallInput } from './__generated__/ServiceCallCancelButtonMutation.graphql';

export const DELAY_OPERATION_KEY = 'serviceCallDelayButtonMutation';

declare module '../jobs/JobStageBaseFields' {
  interface JobStageBaseFieldsMappings {
    save: Pick<SaveServiceCallInput, 'attachments' | 'costsBase' | 'clientBase' | 'equipmentBase' | 'projectBase' | 'questionsBase'>;
  }
}

declare module '../jobs/SaleFields' {
  interface SaleFieldsMappings {
    save: Pick<SaveServiceCallInput, 'addenda' | 'client' | 'equipmentSale' | 'project'>;
  }
}

export function ServiceCallDelayButton({
  delayDialogRef,
  fragmentKey,
  disabled,
  onClick,
}: {
  delayDialogRef: RefObject<ServiceCallDelayDialogRef>;
  fragmentKey: ServiceCallDelayButtonFragment$key | null | undefined;
  disabled: boolean;
  onClick?: () => void;
}) {
  const { t, i18n } = useAmbientTranslation();
  const lang = resolvedLanguage(i18n);
  const { dismissError, reportUnexpectedError, reportHandledError } = useErrorBanner();
  const { startOperation, endOperation, shouldNotify, hasOperationInFlight } = useOperations(DELAY_OPERATION_KEY);

  const jobRevision = useFragment(
    graphql`
      fragment ServiceCallDelayButtonFragment on ServiceCallJobRevision {
        id
        snapshot {
          transferredAt
        }
      }
    `,
    fragmentKey,
  );
  const revisionId = jobRevision?.id;

  const [commitDelay] = usePromiseMutation<ServiceCallDelayButtonMutation>(graphql`
    mutation ServiceCallDelayButtonMutation($revisionId: ID!, $snapshot: SaveServiceCallInput!, $lang: String!) {
      delayServiceCall(input: { revisionId: $revisionId, snapshot: $snapshot }) {
        lifeCycleBranch {
          latestServiceCall {
            id
            lifeCycleBranchId
            ...ServiceCallDetails_PageFragment
              @arguments(lang: $lang, isCopy: false, skipAccessories: false, skipAddenda: false, skipAttachments: false)
          }
        }
        errors {
          __typename
          ... on SalesApiValidationError {
            ...ErrorBannerValidationErrorFragment
          }
          ... on InvalidJobStageBaseTransitionError {
            ...ErrorBannerInvalidTransitionErrorFragment
          }
          ... on JobRevisionIsNotTheLatestError {
            ...ErrorBannerJobRevisionIsNotTheLatestErrorFragment
          }
        }
      }
    }
  `);

  const { mapDirty: mapDirtyBase } = useFormMappings(jobStageBaseFormContext);
  const { mapDirty: mapDirtySale } = useFormMappings(saleFormContext);

  const isTransferred = jobRevision?.snapshot?.transferredAt;
  const isDisabled = shouldNotify || disabled || !revisionId || !isTransferred;

  const handleConfirm = useCallback(async () => {
    // forbid save commands from running in parallel and cannot delay if there is no revision
    if (isDisabled || hasOperationInFlight) return;
    startOperation();
    dismissError(true);

    const requestBase = mapDirtyBase('save');
    const requestSale = mapDirtySale('save');

    const request = merge({}, requestBase, requestSale);
    try {
      const { response } = await commitDelay({
        variables: {
          revisionId: revisionId,
          snapshot: request,
          lang,
        },
      });

      if (response?.delayServiceCall?.errors?.length) {
        reportHandledError(response?.delayServiceCall.errors, () => t('error.errorDuringDelay'));
        return;
      }
    } catch {
      reportUnexpectedError(() => t('error.errorDuringDelay'));
    } finally {
      endOperation();
      delayDialogRef.current?.close();
    }
  }, [
    isDisabled,
    hasOperationInFlight,
    startOperation,
    dismissError,
    mapDirtyBase,
    mapDirtySale,
    commitDelay,
    revisionId,
    lang,
    reportHandledError,
    t,
    reportUnexpectedError,
    endOperation,
    delayDialogRef,
  ]);

  const handleClick = useCallback(() => {
    // forbid save commands from running in parallel and cannot delay if there is no revision
    if (isDisabled) return;

    onClick?.();
    delayDialogRef.current?.open(handleConfirm);
  }, [delayDialogRef, handleConfirm, isDisabled, onClick]);

  return (
    <MenuItem onClick={handleClick} disabled={isDisabled}>
      <SkipNextIcon sx={{ mr: '0.5rem' }} />
      <Typography>{t('button.delay', { ns: 'common' })}</Typography>
    </MenuItem>
  );
}
