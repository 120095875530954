/**
 * @generated SignedSource<<801e636678173a831836ce25020fd227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CreditCategory = "A" | "AA" | "AAA" | "B" | "C" | "D" | "E" | "F" | "NONE" | "%future added value";
export type ServiceCallKind = "accessoriesRental" | "bare" | "laborRental" | "liftingPlan" | "liftingTest" | "operatedHourly" | "operatedMonthly" | "rollingEquipment" | "%future added value";
export type ServiceCallStatus = "archived" | "canceled" | "delayed" | "dispatched" | "inWriting" | "locked" | "reserved" | "transferred" | "%future added value";
import { FragmentRefs } from "relay-runtime";
import { label as craneCapacityLabelResolverType } from "../../__resolvers__/CraneCapacityLabelResolver";
export type ServiceCallListItemFragment$data = {
  readonly friendlyId: string | null | undefined;
  readonly id: string;
  readonly snapshot: {
    readonly client: {
      readonly orderNumber: string | null | undefined;
    };
    readonly clientBase: {
      readonly assignedClient: {
        readonly category: CreditCategory;
        readonly name: string;
      } | null | undefined;
    };
    readonly equipmentBase: {
      readonly craneSelector: {
        readonly favoriteConfiguration: {
          readonly capacity: {
            readonly capacity: number;
            readonly label: string | null | undefined;
          } | null | undefined;
          readonly equipmentKind: {
            readonly abbreviation: string;
          } | null | undefined;
        } | null | undefined;
      };
    };
    readonly kind: ServiceCallKind;
    readonly projectBase: {
      readonly arrivalDate: {
        readonly date: string;
        readonly rawValue: string;
      } | null | undefined;
      readonly assignedWorksiteInfo: {
        readonly name: string | null | undefined;
      };
    };
    readonly statuses: ReadonlyArray<ServiceCallStatus>;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallListActionsFragment">;
  readonly " $fragmentType": "ServiceCallListItemFragment";
};
export type ServiceCallListItemFragment$key = {
  readonly " $data"?: ServiceCallListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceCallListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceCallListItemFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ServiceCallListActionsFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "friendlyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCall",
      "kind": "LinkedField",
      "name": "snapshot",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statuses",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClientInternalBase",
          "kind": "LinkedField",
          "name": "clientBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Client",
              "kind": "LinkedField",
              "name": "assignedClient",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "category",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ServiceCallClientInternal",
          "kind": "LinkedField",
          "name": "client",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orderNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquipmentInternalBase",
          "kind": "LinkedField",
          "name": "equipmentBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CraneSelectorInternal",
              "kind": "LinkedField",
              "name": "craneSelector",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FavoriteConfigurationInfo",
                  "kind": "LinkedField",
                  "name": "favoriteConfiguration",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CraneCapacity",
                      "kind": "LinkedField",
                      "name": "capacity",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "capacity",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "fragment": {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CraneCapacityLabelResolver"
                          },
                          "kind": "RelayResolver",
                          "name": "label",
                          "resolverModule": require('./../../__resolvers__/CraneCapacityLabelResolver').label,
                          "path": "snapshot.equipmentBase.craneSelector.favoriteConfiguration.capacity.label"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EquipmentKindLookup",
                      "kind": "LinkedField",
                      "name": "equipmentKind",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "abbreviation",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectInternalBase",
          "kind": "LinkedField",
          "name": "projectBase",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "WorksiteInfoInternal",
              "kind": "LinkedField",
              "name": "assignedWorksiteInfo",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DateTimeInTimeZone",
              "kind": "LinkedField",
              "name": "arrivalDate",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rawValue",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceCallJobRevision",
  "abstractKey": null
};
})();

(node as any).hash = "a57b117cc644dab60590db2ac66f01cb";

export default node;
