import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { ForwardRepresentativeAutocompleteProps, RepresentativeAutocomplete } from '../../common/components/RepresentativeAutocomplete';
import { useFragment, useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useCallback } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { InactiveStartAdornment } from '../../common/InactiveStartAdornment';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { saleFormContext } from '../SaleFields';
import { SaleClientFields_RepresentativeFragment$key } from './__generated__/SaleClientFields_RepresentativeFragment.graphql';
import { SaleClientFields_InTheCareOfFragment$key } from './__generated__/SaleClientFields_InTheCareOfFragment.graphql';
import { SaleClientFields_OrderNumberFragment$key } from './__generated__/SaleClientFields_OrderNumberFragment.graphql';
import { SaleClientFields_ClientRepresentativeInput_SuggestionsFragment$key } from './__generated__/SaleClientFields_ClientRepresentativeInput_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { useSuggestions } from '../useSuggestions';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key } from './__generated__/SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment.graphql';
import { SaleClientFields_ClientInTheCareOf_SuggestionsQuery } from './__generated__/SaleClientFields_ClientInTheCareOf_SuggestionsQuery.graphql';
import { CanWrite } from '../../auth/Authorization';
import { SaleClientFields_ClientOrderNumberInputFragment$key } from './__generated__/SaleClientFields_ClientOrderNumberInputFragment.graphql';

const fieldRepresentativeKey = createFieldKey<ForwardRepresentativeAutocompleteProps['value']>();
export function useFieldRepresentative(
  $key: SaleClientFields_RepresentativeFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleClientFields_RepresentativeFragment on ISaleClient {
        projectManager {
          id
          label
          deletedAt
        }
      }
    `,
    $key,
  );

  const [representative, setRepresentative] = useField(saleFormContext, fieldRepresentativeKey, $data?.projectManager ?? null);

  const useValidation = useFieldValidation(saleFormContext, fieldRepresentativeKey);
  useValidation((v) => !!v, [], 'transferable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldRepresentativeKey);
  useMapper((v) => ({ client: { projectManagerId: v?.id ?? null } }), [], 'save');

  const isRepresentativeDirty = useFieldIsDirty(saleFormContext, fieldRepresentativeKey);

  const renderRepresentative = useCallback(
    (input$key: SaleClientFields_ClientRepresentativeInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <ClientRepresentativeInput
        $key={input$key}
        value={representative}
        setValue={setRepresentative}
        saleKind={saleKind}
        disabled={disabled}
        required={required}
      />
    ),
    [representative, setRepresentative, disabled, required],
  );

  return { representative, setRepresentative, renderRepresentative, isRepresentativeDirty };
}
function ClientRepresentativeInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  required,
}: {
  $key: SaleClientFields_ClientRepresentativeInput_SuggestionsFragment$key | null | undefined;
  value: ForwardRepresentativeAutocompleteProps['value'];
  setValue: SetValueFn<ForwardRepresentativeAutocompleteProps['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldRepresentativeKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldRepresentativeKey);

  const $data = useFragment(
    graphql`
      fragment SaleClientFields_ClientRepresentativeInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardRepresentativeAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <RepresentativeAutocomplete
      value={value}
      onChange={handleChange}
      disabled={disabled}
      suggestionPromptInput={suggestionPromptInput}
      suggestible
      textFieldProps={(params) => ({
        label: t('field.client.projectManager'),
        required: required,
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

const fieldInTheCareOfKey = createFieldKey<string>();
export function useFieldInTheCareOf($key: SaleClientFields_InTheCareOfFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleClientFields_InTheCareOfFragment on ISaleClient {
        inTheCareOf
      }
    `,
    $key,
  );

  const [inTheCareOf, setInTheCareOf] = useField(saleFormContext, fieldInTheCareOfKey, $data?.inTheCareOf ?? '');

  const useValidation = useFieldValidation(saleFormContext, fieldInTheCareOfKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldInTheCareOfKey);
  useMapper((v) => ({ client: { inTheCareOf: v || null } }), [], 'save');

  const renderInTheCareOf = useCallback(
    (input$key: SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <ClientInTheCareOfInput $key={input$key} value={inTheCareOf} setValue={setInTheCareOf} saleKind={saleKind} disabled={disabled} />
    ),
    [disabled, setInTheCareOf, inTheCareOf],
  );

  return { inTheCareOf, setInTheCareOf, renderInTheCareOf };
}
function ClientInTheCareOfInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment$key | null | undefined;
  value: string;
  setValue: SetValueFn<string>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldInTheCareOfKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldInTheCareOfKey);

  const $data = useFragment(
    graphql`
      fragment SaleClientFields_ClientInTheCareOfInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <TextField
      error={hasErrors}
      label={t('field.client.inTheCareOf')}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{ maxLength: 50 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Suggestions
              disabled={disabled}
              content={(close) => (
                <ClientInTheCareOfSuggestions
                  suggestionPromptInput={suggestionPromptInput}
                  onChange={(v) => {
                    setValue(v);
                    close();
                  }}
                />
              )}
            />
          </InputAdornment>
        ),
      }}
      helperText={message ? t(message, { ns: 'common', ...errorParams }) : ''}
      required
    />
  );
}

function ClientInTheCareOfSuggestions({
  onChange: handleChange,
  suggestionPromptInput,
}: {
  onChange: (selectedValue: string) => void;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const results = useLazyLoadQuery<SaleClientFields_ClientInTheCareOf_SuggestionsQuery>(
    graphql`
      query SaleClientFields_ClientInTheCareOf_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedInTheCareOf(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
  );

  const data = results.suggestedInTheCareOf ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: string) => handleChange(selectedValue)} />
  );
}

const fieldOrderNumberKey = createFieldKey<string>();
export function useFieldOrderNumber(
  $key: SaleClientFields_OrderNumberFragment$key | null | undefined,
  disabled: boolean,
  canEditDisabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleClientFields_OrderNumberFragment on ISaleClient {
        orderNumber
      }
    `,
    $key,
  );

  const [orderNumber, setOrderNumber] = useField(saleFormContext, fieldOrderNumberKey, $data?.orderNumber ?? '');
  const useMapper = useFieldMapper(saleFormContext, fieldOrderNumberKey);
  useMapper((v) => ({ client: { orderNumber: v || null } }), [], 'save');

  const renderOrderNumber = useCallback(
    (input$key: SaleClientFields_ClientOrderNumberInputFragment$key) => (
      <ClientOrderNumberInput
        $key={input$key}
        value={orderNumber}
        setValue={setOrderNumber}
        disabled={disabled}
        canEditDisabled={canEditDisabled}
      />
    ),
    [orderNumber, setOrderNumber, disabled, canEditDisabled],
  );

  return { orderNumber, setOrderNumber, renderOrderNumber };
}
function ClientOrderNumberInput({
  $key,
  value,
  setValue,
  disabled,
  canEditDisabled,
}: {
  $key: SaleClientFields_ClientOrderNumberInputFragment$key;
  value: string;
  setValue: SetValueFn<string>;
  disabled: boolean;
  canEditDisabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const handleChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const $data = useFragment(
    graphql`
      fragment SaleClientFields_ClientOrderNumberInputFragment on Query {
        ...AuthorizationWriteFragment
      }
    `,
    $key,
  );

  return (
    <CanWrite
      $key={$data}
      render={(can) => (
        <TextField
          label={t('field.client.orderNumber')}
          value={value}
          onChange={handleChange}
          disabled={canEditDisabled ? !can : disabled}
          inputProps={{ maxLength: 50 }}
        />
      )}
    />
  );
}
