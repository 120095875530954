/**
 * @generated SignedSource<<6050aa275e053311e0b7f9c98905f568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorFavoriteFragment$data = {
  readonly manualConfiguration: {
    readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorManualFavoriteFragment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorAutomaticFavoriteFragment" | "SaleCraneSelectorFields_CraneSelectorModeFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorFavoriteFragment";
};
export type JobEquipment_useCraneSelectorFavoriteFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorFavoriteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorFavoriteFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneSelectorModeFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "JobEquipment_useCraneSelectorAutomaticFavoriteFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ManualConfigurationInternal",
      "kind": "LinkedField",
      "name": "manualConfiguration",
      "plural": false,
      "selections": [
        {
          "args": (v0/*: any*/),
          "kind": "FragmentSpread",
          "name": "JobEquipment_useCraneSelectorManualFavoriteFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};
})();

(node as any).hash = "959450d96876c04db826522059acc7f4";

export default node;
