import graphql from 'babel-plugin-relay/macro';
import { DataID, useFragment } from 'react-relay';
import {
  ResponsiveGridColumnDefinition,
  ResponsiveGridContent,
  ResponsiveGridHeader,
  ResponsiveGridItem,
} from '../../common/components/ResponsiveGrid';
import { EllipsisedTypography } from '../../common/components/EllipsisedTypography';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { RateStrategyListFragment$key } from './__generated__/RateStrategyListFragment.graphql';
import { EmptyList } from '../../layout/components/form/FormList';

export function RateStrategyList({ $key }: { $key: RateStrategyListFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();

  const $data = useFragment(
    graphql`
      fragment RateStrategyListFragment on Query @argumentDefinitions(lang: { type: "String!" }) {
        rateStrategies {
          id
          label(lang: $lang) @required(action: THROW)
        }
      }
    `,
    $key,
  );

  const columns: ResponsiveGridColumnDefinition[] = [{ id: 'description', label: t('rateStrategies.label'), size: 'minmax(1rem, 1fr)' }];
  return $data?.rateStrategies?.length ? (
    <ResponsiveGridContent
      gridMode
      gridTemplateColumns='minmax(1rem, 1fr)'
      listSx={{
        // Adds a padding in cells to improve readability, specially when elipsing.
        'li.responsive-grid__header  > *': {
          px: '1rem',
        },
        'li:not(.responsive-grid__header)  > *': {
          px: '0.5rem',
        },
      }}>
      <ResponsiveGridHeader columnsDefinitions={columns} />
      {$data?.rateStrategies.map((rateStrategy) => (
        <RateStrategyListRow value={rateStrategy.label} rateStrategyId={rateStrategy.id} key={rateStrategy.id} />
      ))}
    </ResponsiveGridContent>
  ) : (
    <EmptyList />
  );
}

function RateStrategyListRow({ value, rateStrategyId }: { value: string; rateStrategyId: DataID }) {
  const navigate = useNavigate();

  const handleItemClick = useCallback((id: DataID) => navigate(`/configuration/rate-strategies/${id}`), [navigate]);

  return (
    <ResponsiveGridItem id={rateStrategyId} onItemClick={handleItemClick}>
      <EllipsisedTypography key='description' variant='body2' component='div'>
        {value}
      </EllipsisedTypography>
    </ResponsiveGridItem>
  );
}
