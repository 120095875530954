import graphql from 'babel-plugin-relay/macro';
import { readFragment } from 'relay-runtime/lib/store/ResolverFragments';
import { ConfigurationKindLookupLabelResolver$key } from './__generated__/ConfigurationKindLookupLabelResolver.graphql';

/**
 * @RelayResolver ConfigurationKindLookup.label(lang: String!): String
 * @rootFragment ConfigurationKindLookupLabelResolver
 *
 * A label for ConfigurationKindLookup which includes code and description.
 * Used dynamically by Autocomplete component to resolve the option label
 */
export function label(configurationKindLookup$key: ConfigurationKindLookupLabelResolver$key, { lang }: { lang: string }): string {
  const configurationKindLookup$data = readFragment(
    graphql`
      fragment ConfigurationKindLookupLabelResolver on ConfigurationKindLookup {
        code
        description
      }
    `,
    configurationKindLookup$key,
  );
  return `${configurationKindLookup$data.code} - ${configurationKindLookup$data.description[lang]}`;
}
