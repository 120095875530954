/**
 * @generated SignedSource<<d446fddd9e7a4331abfda14b90094035>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_UseDispatchBranchChangeFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_DispatchBranchFragment" | "SaleProjectFields_SalesBranchFragment">;
  readonly " $fragmentType": "SaleProjectFields_UseDispatchBranchChangeFragment";
};
export type SaleProjectFields_UseDispatchBranchChangeFragment$key = {
  readonly " $data"?: SaleProjectFields_UseDispatchBranchChangeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_UseDispatchBranchChangeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_UseDispatchBranchChangeFragment",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_DispatchBranchFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleProjectFields_SalesBranchFragment"
    }
  ],
  "type": "ISaleProject",
  "abstractKey": "__isISaleProject"
};
})();

(node as any).hash = "8a26a82d6f6a8b3527d7d9ab8bba6086";

export default node;
