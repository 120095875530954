import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { DateTime } from 'luxon';
import { fetchQuery, useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SaleProjectFields_DepartureDateFragment$key } from './__generated__/SaleProjectFields_DepartureDateFragment.graphql';
import { SaleProjectFields_ReadyDateFragment$key } from './__generated__/SaleProjectFields_ReadyDateFragment.graphql';
import { SaleProjectFields_IsEstablishedScheduleFragment$key } from './__generated__/SaleProjectFields_IsEstablishedScheduleFragment.graphql';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel, InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';
import { SaleProjectFields_WorkDescriptionFragment$key } from './__generated__/SaleProjectFields_WorkDescriptionFragment.graphql';
import { DispatchBranchAutocomplete, ForwardDispatchBranchAutocompleteProps } from '../../common/components/DispatchBranchAutocomplete';
import { SaleProjectFields_DispatchBranchFragment$key } from './__generated__/SaleProjectFields_DispatchBranchFragment.graphql';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { InactiveStartAdornment } from '../../common/InactiveStartAdornment';
import { ForwardSalesBranchAutocompleteProps, SalesBranchAutocomplete } from '../../common/components/SalesBranchAutocomplete';
import { SaleProjectFields_SalesBranchFragment$key } from './__generated__/SaleProjectFields_SalesBranchFragment.graphql';
import { SaleProjectFields_IsSecondServingFragment$key } from './__generated__/SaleProjectFields_IsSecondServingFragment.graphql';
import { dateTimeFormat, parseDateTime, parseTimeOnly, roundDateTimeToMinuteStep } from '../../common/utils/dateTimeUtils';
import { RoundedDateTimePicker } from '../../common/components/FormDateTimePicker';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers-pro';
import { SaleProjectFields_UseDispatchBranchChangeFragment$key } from './__generated__/SaleProjectFields_UseDispatchBranchChangeFragment.graphql';
import { ForwardNatureOfWorkAutocompleteProps, NatureOfWorkAutocomplete } from '../../common/components/NatureOfWorkAutocomplete';
import { SaleProjectFields_NatureOfWorkFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkFragment.graphql';
import {
  castNatureOfWorkSubCategoryEnum,
  isNatureOfWorkSubCategoryEnum,
  NatureOfWorkSubCategoryEnum,
} from '../../__enums__/NatureOfWorkSubCategoryEnum';
import { SaleProjectFields_NatureOfWorkSubCategoryFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoryFragment.graphql';
import { SelectPicker } from '../../common/components/SelectPicker';
import { NatureOfWorkSubCategoryAutocomplete } from '../../common/components/NatureOfWorkSubCategoryAutocomplete';
import { castWorkScheduleEnum, isWorkScheduleEnum, WorkScheduleEnum } from '../../__enums__/WorkScheduleEnum';
import { SaleProjectFields_WorkScheduleFragment$key } from './__generated__/SaleProjectFields_WorkScheduleFragment.graphql';
import { saleFormContext } from '../SaleFields';
import { useSuggestions } from '../useSuggestions';
import { SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkInput_SuggestionsFragment.graphql';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment.graphql';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import {
  AutomaticSuggestionList,
  emptySuggestionPromptInput,
  scoreThreshold,
  suggestionCount,
  Suggestions,
} from '../../common/components/Suggestions';
import { SaleProjectFields_WorkDescription_SuggestionsQuery } from './__generated__/SaleProjectFields_WorkDescription_SuggestionsQuery.graphql';
import { SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_WorkDescriptionInput_SuggestionsFragment.graphql';
import { SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_DispatchBranchInput_SuggestionsFragment.graphql';
import { SaleProjectFields_SaleBranchInput_SuggestionsFragment$key } from './__generated__/SaleProjectFields_SaleBranchInput_SuggestionsFragment.graphql';
import { SaleProjectFields_NatureOfWorkSubCategoriesFragment$key } from './__generated__/SaleProjectFields_NatureOfWorkSubCategoriesFragment.graphql';
import { SaleProjectFields_WorkSchedulesFragment$key } from './__generated__/SaleProjectFields_WorkSchedulesFragment.graphql';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { SaleProjectFields_useFieldSchedulePeriodFragment$key } from './__generated__/SaleProjectFields_useFieldSchedulePeriodFragment.graphql';
import { SaleProjectFields_useFieldScheduleStartTimeFragment$key } from './__generated__/SaleProjectFields_useFieldScheduleStartTimeFragment.graphql';
import { SaleProjectFields_useFieldScheduleEndTimeFragment$key } from './__generated__/SaleProjectFields_useFieldScheduleEndTimeFragment.graphql';
import { SaleProjectFields_IncludeWeekendsAndHolidaysFragment$key } from './__generated__/SaleProjectFields_IncludeWeekendsAndHolidaysFragment.graphql';
import { useFieldArrivalDate } from './ProjectBaseFields';
import { useOperations, useOperationsError } from '../../AppSharedState';
import { useCancellableSubscription } from '../../common/hooks/useCancellableSubscription';
import { useEffectEvent } from '../../common/utils/effectUtils';
import * as Sentry from '@sentry/react';
import { SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key } from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment.graphql';
import { SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$key } from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment.graphql';
import {
  SaleProjectFields_useIncludeWeekendsAndHolidaysQuery,
  SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables,
} from './__generated__/SaleProjectFields_useIncludeWeekendsAndHolidaysQuery.graphql';

function ProjectCheckboxInput({
  value,
  setValue,
  labelKey,
  disabled,
}: {
  value: boolean;
  setValue: SetValueFn<boolean>;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((_: SyntheticEvent, v: boolean) => setValue(v), [setValue]);

  return (
    <FormControlLabel
      value={value}
      onChange={handleChange}
      checked={value}
      control={<Checkbox />}
      label={t(labelKey)}
      disabled={disabled}
    />
  );
}

const fieldDepartureDateKey = createFieldKey<DateTime | null>();

export function useFieldDepartureDate($key: SaleProjectFields_DepartureDateFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DepartureDateFragment on ISaleProject @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        departureDate @skip(if: $isCopy)
      }
    `,
    $key,
  );

  const [departureDate, setDepartureDate] = useField(saleFormContext, fieldDepartureDateKey, parseDateTime($data?.departureDate));

  const useValidationDepartureDate = useFieldValidation(saleFormContext, fieldDepartureDateKey);

  const today = useMemo(() => DateTime.now(), []);
  useValidationDepartureDate(
    (val, { isDirty }) => (isDirty && val != null && val.diff(today).as('milliseconds') < 0 ? 'error.dateInThePast' : true),
    [today],
    'change:past',
  );

  const useMapperDepartureDate = useFieldMapper(saleFormContext, fieldDepartureDateKey);
  useMapperDepartureDate((val) => ({ project: { departureDate: val?.toJSON() ?? null } }), [], 'save');

  const isDepartureDateDirty = useFieldIsDirty(saleFormContext, fieldDepartureDateKey);

  const renderDepartureDate = useCallback(
    () => <DepartureDate value={departureDate} setValue={setDepartureDate} disabled={disabled} />,
    [departureDate, disabled, setDepartureDate],
  );

  return { departureDate, setDepartureDate, renderDepartureDate, isDepartureDateDirty, useValidationDepartureDate };
}

function DepartureDate({
  value,
  setValue,
  disabled,
}: {
  value: DateTime | null;
  setValue: SetValueFn<DateTime | null>;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasError = useFieldHasErrors(saleFormContext, fieldDepartureDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldDepartureDateKey);

  const handleChange = useCallback((v: DateTime | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => setValue(v), [setValue]);

  return (
    <RoundedDateTimePicker
      label={t('field.project.date.departure')}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          error: hasError,
          helperText: message ? t(message, { ...errorParams }) : '',
        },
      }}
      disablePast
    />
  );
}

const fieldReadyDateKey = createFieldKey<DateTime | null>();

export function useFieldReadyDate($key: SaleProjectFields_ReadyDateFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_ReadyDateFragment on ISaleProject @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        readyDate @skip(if: $isCopy)
      }
    `,
    $key,
  );

  const [readyDate, setReadyDate] = useField(saleFormContext, fieldReadyDateKey, parseDateTime($data?.readyDate));

  const useValidationReadyDate = useFieldValidation(saleFormContext, fieldReadyDateKey);

  const today = useMemo(() => DateTime.now(), []);
  useValidationReadyDate(
    (val, { isDirty }) => (isDirty && val != null && val.diff(today).as('milliseconds') < 0 ? 'error.dateInThePast' : true),
    [today],
    'change:past',
  );

  const useMapperReadyDate = useFieldMapper(saleFormContext, fieldReadyDateKey);
  useMapperReadyDate((val) => ({ project: { readyDate: val?.toJSON() ?? null } }), [], 'save');

  const renderReadyDate = useCallback(
    () => <ReadyDate value={readyDate} setValue={setReadyDate} disabled={disabled} />,
    [disabled, readyDate, setReadyDate],
  );

  return { readyDate, setReadyDate, renderReadyDate, useValidationReadyDate };
}

function ReadyDate({ value, setValue, disabled }: { value: DateTime | null; setValue: SetValueFn<DateTime | null>; disabled: boolean }) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldReadyDateKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldReadyDateKey);

  const handleChange = useCallback((v: DateTime | null, _: PickerChangeHandlerContext<DateTimeValidationError>) => setValue(v), [setValue]);

  return (
    <RoundedDateTimePicker
      label={t('field.project.date.ready')}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ampm={false}
      format={dateTimeFormat}
      slotProps={{
        textField: {
          error: hasErrors,
          helperText: message && t(message, { ...errorParams }),
        },
      }}
      disablePast
    />
  );
}

const fieldIsEstablishedScheduleKey = createFieldKey<boolean>();

export function useFieldIsEstablishedSchedule(
  $key: SaleProjectFields_IsEstablishedScheduleFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IsEstablishedScheduleFragment on ISaleProject {
        isEstablishedSchedule
      }
    `,
    $key,
  );

  const [isEstablishedSchedule, setIsEstablishedSchedule] = useField(
    saleFormContext,
    fieldIsEstablishedScheduleKey,
    $data?.isEstablishedSchedule ?? false,
  );
  const useMapper = useFieldMapper(saleFormContext, fieldIsEstablishedScheduleKey);
  useMapper((v) => ({ project: { isEstablishedSchedule: v } }), [], 'save');

  const renderIsEstablishedSchedule = useCallback(
    () => (
      <ProjectCheckboxInput
        value={isEstablishedSchedule}
        setValue={setIsEstablishedSchedule}
        labelKey='field.project.work.isEstablishedSchedule'
        disabled={disabled}
      />
    ),
    [disabled, isEstablishedSchedule, setIsEstablishedSchedule],
  );

  return { isEstablishedSchedule, setIsEstablishedSchedule, renderIsEstablishedSchedule };
}

export function useFieldSchedulePeriod($key: SaleProjectFields_useFieldSchedulePeriodFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldSchedulePeriodFragment on ISaleProject {
        ...SaleProjectFields_IsEstablishedScheduleFragment
        schedulePeriod {
          ...SaleProjectFields_useFieldScheduleStartTimeFragment
          ...SaleProjectFields_useFieldScheduleEndTimeFragment
        }
      }
    `,
    $key,
  );

  const { renderStartTime, useValidationScheduleStartTime, ...restStartTime } = useFieldScheduleStartTime($data?.schedulePeriod, disabled);
  const { renderEndTime, useValidationScheduleEndTime, ...restEndTime } = useFieldScheduleEndTime($data?.schedulePeriod, disabled);
  const { isEstablishedSchedule } = useFieldIsEstablishedSchedule($data, disabled);
  useValidationScheduleStartTime(
    (val) => (isEstablishedSchedule ? !!val : true),
    [isEstablishedSchedule],
    'transferable:submittable:required',
  );
  useValidationScheduleEndTime(
    (val) => (isEstablishedSchedule ? !!val : true),
    [isEstablishedSchedule],
    'transferable:submittable:required',
  );

  const renderSchedulePeriod = useCallback(() => {
    return isEstablishedSchedule ? (
      <>
        <Box sx={(theme) => ({ gridColumn: '3', [theme.breakpoints.down('xl')]: { gridColumn: '1' } })}>{renderStartTime()}</Box>
        <Box sx={(theme) => ({ gridColumn: '4', [theme.breakpoints.down('xl')]: { gridColumn: '2' } })}>{renderEndTime()}</Box>
      </>
    ) : null;
  }, [isEstablishedSchedule, renderEndTime, renderStartTime]);

  return { renderSchedulePeriod, ...restStartTime, ...restEndTime };
}

const fieldScheduleStartTimeKey = createFieldKey<DateTime<boolean> | null>();

export function useFieldScheduleStartTime(
  $key: SaleProjectFields_useFieldScheduleStartTimeFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldScheduleStartTimeFragment on TimeRange {
        startTime
      }
    `,
    $key,
  );

  const [startTime, setStartTime] = useField(saleFormContext, fieldScheduleStartTimeKey, parseTimeOnly($data?.startTime));
  const useMapper = useFieldMapper(saleFormContext, fieldScheduleStartTimeKey);
  useMapper((v) => ({ project: { schedulePeriod: { startTime: v ? `PT${v.hour}H${v.minute}M` : null } } }), [], 'save');

  const useValidationScheduleStartTime = useFieldValidation(saleFormContext, fieldScheduleStartTimeKey);
  const hasErrors = useFieldHasErrors(saleFormContext, fieldScheduleStartTimeKey);

  const renderStartTime = useCallback(
    () => (
      <TimeInput
        value={startTime}
        setValue={setStartTime}
        labelKey='field.project.schedulePeriod.startTime'
        disabled={disabled}
        required={true}
        hasErrors={hasErrors}
      />
    ),
    [startTime, setStartTime, disabled, hasErrors],
  );

  return { startTime, setStartTime, renderStartTime, useValidationScheduleStartTime };
}

const fieldScheduleEndTimeKey = createFieldKey<DateTime<boolean> | null>();

export function useFieldScheduleEndTime($key: SaleProjectFields_useFieldScheduleEndTimeFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_useFieldScheduleEndTimeFragment on TimeRange {
        endTime
      }
    `,
    $key,
  );

  const [endTime, setEndTime] = useField(saleFormContext, fieldScheduleEndTimeKey, parseTimeOnly($data?.endTime));
  const useMapper = useFieldMapper(saleFormContext, fieldScheduleEndTimeKey);
  useMapper((v) => ({ project: { schedulePeriod: { endTime: v ? `PT${v.hour}H${v.minute}M` : null } } }), [], 'save');

  const useValidationScheduleEndTime = useFieldValidation(saleFormContext, fieldScheduleEndTimeKey);
  const hasErrors = useFieldHasErrors(saleFormContext, fieldScheduleEndTimeKey);

  const renderEndTime = useCallback(
    () => (
      <TimeInput
        value={endTime}
        setValue={setEndTime}
        labelKey='field.project.schedulePeriod.endTime'
        disabled={disabled}
        required={true}
        hasErrors={hasErrors}
      />
    ),
    [endTime, setEndTime, disabled, hasErrors],
  );

  return { endTime, setEndTime, renderEndTime, useValidationScheduleEndTime };
}

function TimeInput({
  value,
  setValue,
  labelKey,
  disabled,
  required,
  hasErrors,
}: {
  value: DateTime<boolean> | null;
  setValue: SetValueFn<DateTime<boolean> | null>;
  labelKey: string;
  disabled: boolean;
  required?: boolean;
  hasErrors: boolean;
}) {
  const { t } = useAmbientTranslation();

  const handleChange = useCallback((v: DateTime<boolean> | null) => setValue(v), [setValue]);

  const handleRound = useCallback(() => {
    if (!value) {
      return;
    }

    const rounded = roundDateTimeToMinuteStep(value, 30);
    if (!rounded.equals(value)) {
      handleChange?.(rounded);
    }
  }, [value, handleChange]);

  return (
    <TimePicker
      value={value}
      onChange={handleChange}
      minutesStep={30}
      timeSteps={{ minutes: 30 }}
      label={t(labelKey, { ns: 'jobs' })}
      disabled={disabled}
      timezone='default'
      views={['hours', 'minutes']}
      slotProps={{
        textField: {
          onBlur: handleRound,
          required: required,
          error: hasErrors,
        },
      }}
    />
  );
}

const fieldNatureOfWorkKey = createFieldKey<ForwardNatureOfWorkAutocompleteProps['value']>();

export function useFieldNatureOfWork($key: SaleProjectFields_NatureOfWorkFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkFragment on ISaleProject @argumentDefinitions(lang: { type: "String!" }) {
        natureOfWork {
          id
          code
          label(lang: $lang)
        }
      }
    `,
    $key,
  );

  const [natureOfWork, setNatureOfWork] = useField(saleFormContext, fieldNatureOfWorkKey, $data?.natureOfWork ?? null);

  const useValidation = useFieldValidation(saleFormContext, fieldNatureOfWorkKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldNatureOfWorkKey);
  useMapper((v) => ({ project: { natureOfWorkCode: v?.code ?? null } }), [], 'save');

  const isNatureOfWorkDirty = useFieldIsDirty(saleFormContext, fieldNatureOfWorkKey);

  const renderNatureOfWork = useCallback(
    (suggestions$key: SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <NatureOfWorkInput $key={suggestions$key} saleKind={saleKind} value={natureOfWork} setValue={setNatureOfWork} disabled={disabled} />
      );
    },
    [disabled, natureOfWork, setNatureOfWork],
  );

  return { natureOfWork, setNatureOfWork, renderNatureOfWork, isNatureOfWorkDirty };
}

function NatureOfWorkInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_NatureOfWorkInput_SuggestionsFragment$key | null | undefined;
  value: ForwardNatureOfWorkAutocompleteProps['value'];
  setValue: SetValueFn<ForwardNatureOfWorkAutocompleteProps['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldNatureOfWorkKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldNatureOfWorkKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardNatureOfWorkAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <NatureOfWorkAutocomplete
      value={value}
      onChange={handleChange}
      suggestionPromptInput={suggestionPromptInput}
      suggestible
      disabled={disabled}
      textFieldProps={() => ({
        label: t('field.project.work.natureOfWork'),
        error: hasErrors,
        required: true,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

const fieldNatureOfWorkSubCategoryKey = createFieldKey<NatureOfWorkSubCategoryEnum | null>();
export function useFieldNatureOfWorkSubCategory(
  $key: SaleProjectFields_NatureOfWorkSubCategoryFragment$key | null | undefined,
  categories$key: SaleProjectFields_NatureOfWorkSubCategoriesFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoryFragment on ISaleProject {
        natureOfWorkSubCategory
      }
    `,
    $key,
  );

  const categories$data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoriesFragment on NatureOfWorkLookup {
        natureOfWorkSubCategories
      }
    `,
    categories$key,
  );

  const [natureOfWorkSubCategory, setNatureOfWorkSubCategory] = useField(
    saleFormContext,
    fieldNatureOfWorkSubCategoryKey,
    $data?.natureOfWorkSubCategory ? castNatureOfWorkSubCategoryEnum($data.natureOfWorkSubCategory) : null,
  );

  const natureOfWorkSubCategoryIsDirty = useFieldIsDirty(saleFormContext, fieldNatureOfWorkSubCategoryKey);

  const useValidation = useFieldValidation(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  useValidation((v) => !!v, [], 'submittable,transferable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  useMapper((v) => ({ project: { natureOfWorkSubCategory: v } }), [], 'save');

  const options = useMemo(
    () => categories$data?.natureOfWorkSubCategories?.filter(isNatureOfWorkSubCategoryEnum) ?? [],
    [categories$data?.natureOfWorkSubCategories],
  );

  const renderNatureOfWorkSubCategory = useCallback(
    (input$key: SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <NatureOfWorkSubCategoryInput
          $key={input$key}
          value={natureOfWorkSubCategory}
          setValue={setNatureOfWorkSubCategory}
          saleKind={saleKind}
          options={options}
          disabled={disabled}
          required={true}
        />
      );
    },
    [natureOfWorkSubCategory, setNatureOfWorkSubCategory, options, disabled],
  );

  return { natureOfWorkSubCategory, setNatureOfWorkSubCategory, natureOfWorkSubCategoryIsDirty, renderNatureOfWorkSubCategory };
}

function NatureOfWorkSubCategoryInput({
  $key,
  value,
  setValue,
  saleKind,
  options,
  disabled,
  required,
}: {
  $key: SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key | null | undefined;
  value: NatureOfWorkSubCategoryEnum | null;
  setValue: SetValueFn<NatureOfWorkSubCategoryEnum | null>;
  saleKind: ServiceCallKind;
  options: NatureOfWorkSubCategoryEnum[];
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldNatureOfWorkSubCategoryKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldNatureOfWorkSubCategoryKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback(
    (v: NatureOfWorkSubCategoryEnum | null) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <NatureOfWorkSubCategoryAutocomplete
      disabled={disabled}
      value={value}
      onChange={handleChange}
      options={options}
      suggestionPromptInput={suggestionPromptInput}
      suggestible
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`natureOfWorkSubCategories.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('field.project.work.natureOfWorkSubCategory'),
        required: required,
        error: hasErrors && !disabled,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
        disabled,
      })}
    />
  );
}

const fieldWorkScheduleKey = createFieldKey<WorkScheduleEnum | null>();
export function useFieldWorkSchedule(
  $key: SaleProjectFields_WorkScheduleFragment$key | null | undefined,
  schedules$key: SaleProjectFields_WorkSchedulesFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkScheduleFragment on ISaleProject {
        workSchedule
      }
    `,
    $key,
  );

  const schedule$data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkSchedulesFragment on NatureOfWorkLookup {
        workSchedules
      }
    `,
    schedules$key,
  );

  const [workSchedule, setWorkSchedule] = useField(
    saleFormContext,
    fieldWorkScheduleKey,
    $data?.workSchedule ? castWorkScheduleEnum($data.workSchedule) : null,
  );

  const useValidation = useFieldValidation(saleFormContext, fieldWorkScheduleKey);
  useValidation((v) => !!v, [], 'transferable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldWorkScheduleKey);
  useMapper((v) => ({ project: { workSchedule: v } }), [], 'save');

  const options = useMemo(() => schedule$data?.workSchedules?.filter(isWorkScheduleEnum) ?? [], [schedule$data?.workSchedules]);

  const renderWorkSchedule = useCallback(() => {
    return <WorkScheduleInput value={workSchedule} setValue={setWorkSchedule} options={options} disabled={disabled} required={required} />;
  }, [workSchedule, setWorkSchedule, options, disabled, required]);

  return { workSchedule, setWorkSchedule, renderWorkSchedule };
}

function WorkScheduleInput({
  value,
  setValue,
  options,
  disabled,
  required,
}: {
  value: WorkScheduleEnum | null;
  setValue: SetValueFn<WorkScheduleEnum | null>;
  options: WorkScheduleEnum[];
  disabled: boolean;
  required: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldWorkScheduleKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldWorkScheduleKey);

  const handleChange = useCallback(
    (_: SyntheticEvent, v: WorkScheduleEnum | null) => {
      setValue(v);
    },
    [setValue],
  );

  return (
    <SelectPicker
      disabled={disabled}
      value={value}
      onChange={handleChange}
      options={options}
      getOptionKey={(o) => o}
      getOptionLabel={(o) => t(`workSchedules.${o}`, { ns: 'common' })}
      textFieldProps={() => ({
        label: t('field.project.work.workSchedule'),
        error: hasErrors && !disabled,
        required: required,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
        disabled,
      })}
    />
  );
}

const fieldWorkDescriptionKey = createFieldKey<string>();

export function useFieldWorkDescription($key: SaleProjectFields_WorkDescriptionFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkDescriptionFragment on ISaleProject {
        workDescription
      }
    `,
    $key,
  );

  const [workDescription, setWorkDescription] = useField(saleFormContext, fieldWorkDescriptionKey, $data?.workDescription ?? '');

  const useValidation = useFieldValidation(saleFormContext, fieldWorkDescriptionKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldWorkDescriptionKey);
  useMapper((v) => ({ project: { workDescription: v } }), [], 'save');

  const renderWorkDescription = useCallback(
    (input$key: SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <WorkDescriptionInput
          $key={input$key}
          value={workDescription}
          setValue={setWorkDescription}
          saleKind={saleKind}
          disabled={disabled}
        />
      );
    },
    [disabled, setWorkDescription, workDescription],
  );

  return { workDescription, setWorkDescription, renderWorkDescription };
}

function WorkDescriptionInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_WorkDescriptionInput_SuggestionsFragment$key | null | undefined;
  value: string;
  setValue: SetValueFn<string>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldWorkDescriptionKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldWorkDescriptionKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_WorkDescriptionInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  // HACK: Workaround a crash in ResizeObserver in MUI code: https://github.com/mui/base-ui/issues/167
  const workaroundTextFieldResizeObserverCrash: TextFieldProps = {
    // Need to use a fix row count to prevent height recalculation due to content changes.
    rows: 5,
    // Need to force visibility of vertical scrollbar to prevent height recalculation when it becomes visible otherwise.
    sx: { '& textarea': { overflowY: 'scroll !important' } },
  };

  return (
    <TextField
      error={hasErrors}
      multiline
      {...workaroundTextFieldResizeObserverCrash}
      label={t('field.project.work.workDescription')}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Suggestions
              disabled={disabled}
              content={(close) => (
                <WorkDescriptionSuggestions
                  suggestionPromptInput={suggestionPromptInput}
                  onChange={(v) => {
                    setValue(v);
                    close();
                  }}
                />
              )}
            />
          </InputAdornment>
        ),
      }}
      helperText={message ? t(message, { ns: 'common', ...errorParams }) : ''}
    />
  );
}

function WorkDescriptionSuggestions({
  onChange: handleChange,
  suggestionPromptInput,
}: {
  onChange: (selectedValue: string) => void;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const results = useLazyLoadQuery<SaleProjectFields_WorkDescription_SuggestionsQuery>(
    graphql`
      query SaleProjectFields_WorkDescription_SuggestionsQuery(
        $scoreThreshold: Float!
        $suggestionPrompt: SuggestionPromptInput!
        $suggestionCount: Int!
      ) {
        suggestedWorkDescriptions(scoreThreshold: $scoreThreshold, prompt: $suggestionPrompt, count: $suggestionCount) {
          value
          score
        }
      }
    `,
    {
      scoreThreshold: scoreThreshold,
      suggestionPrompt: suggestionPromptInput ?? emptySuggestionPromptInput,
      suggestionCount: suggestionCount,
    },
  );

  const data = results.suggestedWorkDescriptions ?? [];

  return (
    <AutomaticSuggestionList keySelector={(v) => `${v}`} items={data} onChange={(selectedValue: string) => handleChange(selectedValue)} />
  );
}

const fieldDispatchBranchKey = createFieldKey<ForwardDispatchBranchAutocompleteProps['value']>();

export function useFieldDispatchBranch($key: SaleProjectFields_DispatchBranchFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DispatchBranchFragment on ISaleProject @argumentDefinitions(lang: { type: "String!" }) {
        dispatchBranch {
          id
          label(lang: $lang)
          deletedAt
        }
      }
    `,
    $key,
  );

  const initialValue = $data?.dispatchBranch ?? null;
  const [dispatchBranch, setDispatchBranch] = useField(saleFormContext, fieldDispatchBranchKey, initialValue);

  const useValidation = useFieldValidation(saleFormContext, fieldDispatchBranchKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldDispatchBranchKey);
  useMapper((v) => ({ project: { dispatchBranchId: v?.id ?? null } }), [], 'save');

  const isDispatchBranchDirty = useFieldIsDirty(saleFormContext, fieldDispatchBranchKey);

  const renderDispatchBranch = useCallback(
    (input$key: SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return (
        <DispatchBranchInput $key={input$key} value={dispatchBranch} setValue={setDispatchBranch} saleKind={saleKind} disabled={disabled} />
      );
    },
    [disabled, dispatchBranch, setDispatchBranch],
  );

  return { dispatchBranch, setDispatchBranch, renderDispatchBranch, isDispatchBranchDirty };
}

function DispatchBranchInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_DispatchBranchInput_SuggestionsFragment$key | null | undefined;
  value: ForwardDispatchBranchAutocompleteProps['value'];
  setValue: SetValueFn<ForwardDispatchBranchAutocompleteProps['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldDispatchBranchKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldDispatchBranchKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_DispatchBranchInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardDispatchBranchAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <DispatchBranchAutocomplete
      value={value}
      onChange={handleChange}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.project.branch.dispatchBranch'),
        required: true,
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

const fieldSalesBranchKey = createFieldKey<ForwardSalesBranchAutocompleteProps['value']>();

export function useFieldSalesBranch($key: SaleProjectFields_SalesBranchFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_SalesBranchFragment on ISaleProject @argumentDefinitions(lang: { type: "String!" }) {
        salesBranch {
          id
          label(lang: $lang)
          deletedAt
        }
      }
    `,
    $key,
  );

  const initialValue = $data?.salesBranch ?? null;

  const [salesBranch, setSalesBranch] = useField(saleFormContext, fieldSalesBranchKey, initialValue);

  const useValidation = useFieldValidation(saleFormContext, fieldSalesBranchKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(saleFormContext, fieldSalesBranchKey);
  useMapper((v) => ({ project: { salesBranchId: v?.id ?? null } }), [], 'save');

  const isSalesBranchDirty = useFieldIsDirty(saleFormContext, fieldSalesBranchKey);

  const renderSalesBranch = useCallback(
    (input$key: SaleProjectFields_SaleBranchInput_SuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => {
      return <SalesBranchInput $key={input$key} value={salesBranch} setValue={setSalesBranch} saleKind={saleKind} disabled={disabled} />;
    },
    [disabled, salesBranch, setSalesBranch],
  );

  return { salesBranch, setSalesBranch, renderSalesBranch, isSalesBranchDirty };
}

function SalesBranchInput({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
}: {
  $key: SaleProjectFields_SaleBranchInput_SuggestionsFragment$key | null | undefined;
  value: ForwardSalesBranchAutocompleteProps['value'];
  setValue: SetValueFn<ForwardSalesBranchAutocompleteProps['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
}) {
  const { t } = useAmbientTranslation();
  const hasErrors = useFieldHasErrors(saleFormContext, fieldSalesBranchKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(saleFormContext, fieldSalesBranchKey);

  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_SaleBranchInput_SuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const handleChange = useCallback<NonNullable<ForwardSalesBranchAutocompleteProps['onChange']>>(
    (v) => {
      setValue(v);
    },
    [setValue],
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <SalesBranchAutocomplete
      value={value}
      onChange={handleChange}
      suggestible
      suggestionPromptInput={suggestionPromptInput}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.project.branch.salesBranch'),
        required: true,
        InputProps: {
          ...params.InputProps,
          startAdornment: (
            <>
              {value?.deletedAt && <InactiveStartAdornment />}
              {params.InputProps.startAdornment}
            </>
          ),
        },
        error: hasErrors,
        helperText: message ? t(message, { ns: 'common', ...errorParams }) : '',
      })}
    />
  );
}

export function useDispatchBranchChange($key: SaleProjectFields_UseDispatchBranchChangeFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_UseDispatchBranchChangeFragment on ISaleProject @argumentDefinitions(lang: { type: "String!" }) {
        ...SaleProjectFields_DispatchBranchFragment @arguments(lang: $lang)
        ...SaleProjectFields_SalesBranchFragment @arguments(lang: $lang)
      }
    `,
    $key,
  );

  const { dispatchBranch, isDispatchBranchDirty } = useFieldDispatchBranch($data, disabled);
  const { setSalesBranch } = useFieldSalesBranch($data, disabled);

  useEffect(() => {
    if (isDispatchBranchDirty && dispatchBranch) {
      setSalesBranch(dispatchBranch);
    }
  }, [dispatchBranch, isDispatchBranchDirty, setSalesBranch]);
}

const fieldIsSecondServingKey = createFieldKey<boolean>();

export function useFieldIsSecondServing($key: SaleProjectFields_IsSecondServingFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IsSecondServingFragment on ISaleProject {
        isSecondServing
      }
    `,
    $key,
  );

  const [isSecondServing, setIsSecondServing] = useField(saleFormContext, fieldIsSecondServingKey, $data?.isSecondServing ?? false);
  const useMapper = useFieldMapper(saleFormContext, fieldIsSecondServingKey);
  useMapper((v) => ({ project: { isSecondServing: v } }), [], 'save');

  const renderIsSecondServing = useCallback(
    () => (
      <ProjectCheckboxInput
        value={isSecondServing}
        setValue={setIsSecondServing}
        labelKey='field.project.date.isSecondServing'
        disabled={disabled}
      />
    ),
    [disabled, isSecondServing, setIsSecondServing],
  );

  return { isSecondServing, setIsSecondServing, renderIsSecondServing };
}

const fieldIncludeWeekendsAndHolidays = createFieldKey<boolean>();
export function useFieldIncludeWeekendsAndHolidays(
  $key: SaleProjectFields_IncludeWeekendsAndHolidaysFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleProjectFields_IncludeWeekendsAndHolidaysFragment on ISaleProject {
        includeWeekendsAndHolidays
      }
    `,
    $key,
  );

  const [includeWeekendsAndHolidays, setIncludeWeekendsAndHolidays] = useField(
    saleFormContext,
    fieldIncludeWeekendsAndHolidays,
    $data?.includeWeekendsAndHolidays ?? false,
  );
  const useMapper = useFieldMapper(saleFormContext, fieldIncludeWeekendsAndHolidays);
  useMapper((v) => ({ project: { includeWeekendsAndHolidays: v } }), [], 'save');

  const renderIncludeWeekendsAndHolidays = useCallback(
    (loading?: boolean) =>
      !loading ? (
        <ProjectCheckboxInput
          value={includeWeekendsAndHolidays}
          setValue={setIncludeWeekendsAndHolidays}
          labelKey='field.project.date.includeWeekendsAndHolidays'
          disabled={disabled}
        />
      ) : (
        <LoadingCheckboxInput labelKey='field.project.date.includeWeekendsAndHolidays' />
      ),
    [disabled, includeWeekendsAndHolidays, setIncludeWeekendsAndHolidays],
  );

  return {
    includeWeekendsAndHolidays,
    setIncludeWeekendsAndHolidays,
    renderIncludeWeekendsAndHolidays,
  };
}

export const INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY = 'useIncludeWeekendsAndHolidays_operation';
export function useIncludeWeekendsAndHolidays(
  base$key: SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment$key | null | undefined,
  sale$key: SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment$key | null | undefined,
  disabled: boolean,
) {
  const env = useRelayEnvironment();

  const base$data = useFragment(
    graphql`
      fragment SaleProjectFields_useIncludeWeekendsAndHolidays_BaseFragment on ProjectInternalBase
      @argumentDefinitions(isCopy: { type: "Boolean!" }) {
        ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: $isCopy)
      }
    `,
    base$key,
  );

  const sale$data = useFragment(
    graphql`
      fragment SaleProjectFields_useIncludeWeekendsAndHolidays_SaleFragment on ISaleProject {
        ...SaleProjectFields_IncludeWeekendsAndHolidaysFragment
      }
    `,
    sale$key,
  );

  const { setIncludeWeekendsAndHolidays, includeWeekendsAndHolidays } = useFieldIncludeWeekendsAndHolidays(sale$data, disabled);
  const { arrivalDate, arrivalDateIsDirty } = useFieldArrivalDate(base$data, disabled, true);

  const { startOperation, endOperation } = useOperations(INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY);
  const { resetError, setError } = useOperationsError(INCLUDE_WEEKENDS_AND_HOLIDAYS_OPERATION_KEY);

  const [_, setSubscription] = useCancellableSubscription();

  const fetchDateInfo = useEffectEvent((variables: SaleProjectFields_useIncludeWeekendsAndHolidaysQuery$variables) => {
    startOperation();
    resetError();

    setSubscription(
      fetchQuery<SaleProjectFields_useIncludeWeekendsAndHolidaysQuery>(
        env,
        graphql`
          query SaleProjectFields_useIncludeWeekendsAndHolidaysQuery($date: Date!) {
            dateInfo(date: $date) {
              isStatutoryHoliday
              isWeekend
            }
          }
        `,
        variables,
      ).subscribe({
        error: (error: Error) => {
          Sentry.captureException(error);
          endOperation();
          setError(error);
        },
        next: (result) => {
          // Contrary to many other usages of the fetchQuery pattern, we do not need to manually retain query
          // for this usage since once we set the field, we don't need query result anymore.

          const isDateWeekendOrHoliday = result.dateInfo.isWeekend || result.dateInfo.isStatutoryHoliday;

          // Set the field value to the queried one if it is different
          if (isDateWeekendOrHoliday !== includeWeekendsAndHolidays) {
            setIncludeWeekendsAndHolidays(isDateWeekendOrHoliday);
          }

          endOperation();
        },
        unsubscribe: () => {
          endOperation();
          resetError();
        },
      }),
    );
  });

  useEffect(() => {
    if (arrivalDate && arrivalDateIsDirty) {
      const date = arrivalDate.toISODate();
      if (date) {
        fetchDateInfo({ date });
      }
    }
  }, [arrivalDate, arrivalDateIsDirty, fetchDateInfo]);
}

function LoadingCheckboxInput({ labelKey }: { labelKey: string }) {
  const { t } = useAmbientTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <CircularProgress size={18} sx={{ m: '0.75rem', ml: '0.0625rem' }} />
      <Typography>{t(labelKey)}</Typography>
    </Box>
  );
}
