import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldErrorsFirstMessage,
  useFieldHasErrors,
  useFieldIsDirty,
  useFieldMapper,
  useFieldValidation,
} from '../../common/utils/forms';
import { DataID, useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { ChangeEvent, ReactNode, SetStateAction, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Chip, IconButton, TextField } from '@mui/material';
import { Place } from '../../common/components/AddressInput';
import { AddressInputGooglePlaces } from '../../common/components/AddressInputGooglePlaces';
import { MaskInput } from '../../common/components/MaskInput';
import {
  ClientRequirementAutocomplete,
  ForwardClientRequirementAutocompleteProps,
} from '../../common/components/ClientRequirementAutocomplete';
import { exclude } from '../../common/utils/arrayUtils';
import InfoIcon from '@mui/icons-material/Info';
import { RequirementsDialog } from '../../common/dialogs/RequirementsDialog';
import { ClientBaseFields_AssignedClientFragment$key } from './__generated__/ClientBaseFields_AssignedClientFragment.graphql';
import { ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_IsDirectSalesFragment.graphql';
import { ClientBaseFields_ClientOverrides_NameFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_NameFragment.graphql';
import { ClientBaseFields_ClientOverrides_LocationFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_LocationFragment.graphql';
import { ClientBaseFields_ClientOverrides_PhoneNumberFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_PhoneNumberFragment.graphql';
import { ClientBaseFields_RequirementsFragment$key } from './__generated__/ClientBaseFields_RequirementsFragment.graphql';
import { jobStageBaseFormContext } from '../JobStageBaseFields';
import { useSuggestions } from '../useSuggestions';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { SuggestionPromptInput } from '../../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { ClientBaseFields_ClientRequirementsSuggestionsFragment$key } from './__generated__/ClientBaseFields_ClientRequirementsSuggestionsFragment.graphql';
import { ClientBaseFields_ClientOverrides_LanguageCodeFragment$key } from './__generated__/ClientBaseFields_ClientOverrides_LanguageCodeFragment.graphql';
import { SelectPicker } from '../../common/components/SelectPicker';
import { ConfirmationDialog } from '../../common/dialogs/ConfirmationDialog';
import { SalesDetails_useBillingCodeSearchInput_ClientFragment$key } from '../__generated__/SalesDetails_useBillingCodeSearchInput_ClientFragment.graphql';
import { castClientLanguage, ClientLanguage, clientLanguages } from '../../__enums__/ClientLanguage';

const fieldAssignedClientKey = createFieldKey<{
  id: DataID;
  etag: string;
  useBillingCodeSearchInput$key: SalesDetails_useBillingCodeSearchInput_ClientFragment$key | null | undefined;
} | null>();
export function useFieldAssignedClient($key: ClientBaseFields_AssignedClientFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_AssignedClientFragment on ClientInternalBase {
        assignedClientId
        assignedClient {
          ...SalesDetails_useBillingCodeSearchInput_ClientFragment
        }
      }
    `,
    $key,
  );

  const [assignedClient, setAssignedClient] = useField(
    jobStageBaseFormContext,
    fieldAssignedClientKey,
    useMemo(() => {
      if (!$data?.assignedClientId) {
        return null;
      }
      if (!$data.assignedClientId) {
        throw new Error('[useFieldAssignedClient] assignedClient does not support being reset');
      }
      return { id: $data.assignedClientId, etag: nanoid(), useBillingCodeSearchInput$key: $data.assignedClient };
    }, [$data]),
  );
  const assignedClientIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldAssignedClientKey);

  const useValidation = useFieldValidation(jobStageBaseFormContext, fieldAssignedClientKey);
  useValidation((val) => val == null && 'error.clientIsRequired', [], 'transferable,submittable:required');

  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldAssignedClientKey);
  useMapper((v) => ({ clientBase: { assignedClientId: v?.id ?? null } }), [], 'save');

  return { assignedClient, setAssignedClient, assignedClientIsDirty };
}

export function useFieldAssignedClientErrors() {
  const hasErrors = useFieldHasErrors(jobStageBaseFormContext, fieldAssignedClientKey);
  const [message, errorParams] = useFieldErrorsFirstMessage(jobStageBaseFormContext, fieldAssignedClientKey);

  return { assignedClientErrorMessage: message, assignedClientErrorParams: errorParams, assignedClientHasErrors: hasErrors };
}

const fieldClientOverridesIsDirectSalesKey = createFieldKey<boolean>();
export function useFieldClientOverridesIsDirectSales($key: ClientBaseFields_ClientOverrides_IsDirectSalesFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_IsDirectSalesFragment on IOverridableClient {
        isDirectSales
      }
    `,
    $key,
  );

  const [clientOverridesIsDirectSales, setClientOverridesIsDirectSales] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesIsDirectSalesKey,
    $data?.isDirectSales ?? false,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesIsDirectSalesKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { isDirectSales: v } } }), [], 'save');

  return { clientOverridesIsDirectSales, setClientOverridesIsDirectSales };
}

const fieldClientOverridesNameKey = createFieldKey<string>();
export function useFieldClientOverridesName($key: ClientBaseFields_ClientOverrides_NameFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_NameFragment on IOverridableClient {
        name
      }
    `,
    $key,
  );

  const [clientOverridesName, setClientOverridesName] = useField(jobStageBaseFormContext, fieldClientOverridesNameKey, $data?.name ?? '');
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesNameKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { name: v } } }), [], 'save');

  const renderClientOverridesName = useCallback(
    () => <ClientNameInput value={clientOverridesName} setValue={setClientOverridesName} disabled={disabled} />,
    [clientOverridesName, disabled, setClientOverridesName],
  );

  return { clientOverridesName, setClientOverridesName, renderClientOverridesName };
}

function ClientNameInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('client');
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  return <TextField label={t('field.name')} value={value} onChange={handleChange} disabled={disabled} required />;
}

type ClientOverridesLocationFieldType = { address: string; placeToken: { id: string | null; placeId: string } | null };
const fieldClientOverridesLocationKey = createFieldKey<ClientOverridesLocationFieldType>();
export function useFieldClientOverridesLocation(
  $key: ClientBaseFields_ClientOverrides_LocationFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_LocationFragment on IOverridableClient {
        location {
          address
          placeToken {
            id
            placeId
          }
        }
      }
    `,
    $key,
  );

  const [clientOverridesLocation, setClientOverridesLocation] = useField(jobStageBaseFormContext, fieldClientOverridesLocationKey, {
    address: $data?.location?.address ?? '',
    placeToken: $data?.location?.placeToken ?? null,
  });
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesLocationKey);
  useMapper(
    (v) => ({
      clientBase: {
        assignedClientInfo: {
          location: v
            ? {
                address: v.address,
                placeId: v.placeToken?.placeId ?? null,
              }
            : null,
        },
      },
    }),
    [],
    'save',
  );

  const renderClientOverridesLocation = useCallback(
    () => <ClientLocationInput value={clientOverridesLocation} setValue={setClientOverridesLocation} disabled={disabled} />,
    [clientOverridesLocation, disabled, setClientOverridesLocation],
  );

  return { clientOverridesLocation, setClientOverridesLocation, renderClientOverridesLocation };
}

function ClientLocationInput({
  value,
  setValue,
  disabled,
}: {
  value: ClientOverridesLocationFieldType;
  setValue: SetValueFn<ClientOverridesLocationFieldType>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');

  const handleChange = useCallback(
    (newValue: string | Place | null) => {
      setValue((prev) =>
        typeof newValue === 'string' || newValue === null
          ? { ...prev, address: newValue ?? '', placeToken: null }
          : { ...prev, address: newValue.description, placeToken: { id: null, placeId: newValue.placeId } },
      );
    },
    [setValue],
  );

  return (
    <AddressInputGooglePlaces
      value={value.address}
      onChange={handleChange}
      placeId={value.placeToken?.placeId ?? null}
      placeTokenId={value.placeToken?.id ?? null}
      disabled={disabled}
      textFieldProps={(params) => ({
        label: t('field.address'),
        required: true,
        inputProps: { ...params.inputProps, maxLength: 200 },
      })}
    />
  );
}

const fieldClientOverridesPhoneNumberKey = createFieldKey<string>();
export function useFieldClientOverridesPhoneNumber(
  $key: ClientBaseFields_ClientOverrides_PhoneNumberFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_PhoneNumberFragment on IOverridableClient {
        phoneNumber
      }
    `,
    $key,
  );

  const [clientOverridesPhoneNumber, setClientOverridesPhoneNumber] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesPhoneNumberKey,
    $data?.phoneNumber ?? '',
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesPhoneNumberKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { phoneNumber: v } } }), [], 'save');

  const renderClientOverridesPhoneNumber = useCallback(
    () => <ClientPhoneNumberInput value={clientOverridesPhoneNumber} setValue={setClientOverridesPhoneNumber} disabled={disabled} />,
    [clientOverridesPhoneNumber, disabled, setClientOverridesPhoneNumber],
  );

  return { clientOverridesPhoneNumber, setClientOverridesPhoneNumber, renderClientOverridesPhoneNumber };
}

function ClientPhoneNumberInput({ value, setValue, disabled }: { value: string; setValue: SetValueFn<string>; disabled: boolean }) {
  const { t } = useTranslation('client');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );

  return (
    <TextField
      label={t('field.phoneNumber')}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      required
      InputProps={{
        inputComponent: MaskInput,
        inputProps: {
          mask: '000-000-0000',
        },
      }}
    />
  );
}

type useFieldClientOverridesRequirementsReturns = {
  clientOverridesRequirements: ForwardClientRequirementAutocompleteProps<true>['value'];
  setClientOverridesRequirements: (
    newValue: SetStateAction<ForwardClientRequirementAutocompleteProps<true>['value']>,
    dirty?: false,
  ) => void;
  renderClientOverridesRequirements: (
    input$key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined,
    saleKind: ServiceCallKind,
  ) => ReactNode;
  renderClientOverridesRequirementsNoSuggestions: () => ReactNode;
};
const fieldClientOverridesRequirementsKey = createFieldKey<ForwardClientRequirementAutocompleteProps<true>['value']>();
function equalsById(left: { id: DataID }, right: { id: DataID }) {
  return left.id === right.id;
}

export function useFieldClientOverridesRequirements(
  $key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  static$key: ClientBaseFields_RequirementsFragment$key | null | undefined,
  disabled: boolean,
): useFieldClientOverridesRequirementsReturns {
  const requirementsFragment = graphql`
    fragment ClientBaseFields_RequirementsFragment on Requirement @relay(plural: true) {
      id
      label
    }
  `;

  const $data = useFragment(requirementsFragment, $key);
  const static$data = useFragment(requirementsFragment, static$key);

  const [clientOverridesRequirements, setClientOverridesRequirementsInternal] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesRequirementsKey,
    exclude($data ?? [], static$data ?? [], equalsById),
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesRequirementsKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { requirements: v ? { ids: v.map((r) => r.id) } : null } } }), [], 'save');

  const setClientOverridesRequirements = useCallback<typeof setClientOverridesRequirementsInternal>(
    (newValue, dirty) => {
      if (typeof newValue === 'function') {
        setClientOverridesRequirementsInternal((old) => exclude(newValue(old) ?? [], static$data ?? [], equalsById), dirty);
      } else {
        setClientOverridesRequirementsInternal(exclude(newValue ?? [], static$data ?? [], equalsById), dirty);
      }
    },
    [setClientOverridesRequirementsInternal, static$data],
  );

  const displayedRequirements = useMemo(
    () => [...(static$data ?? []), ...(clientOverridesRequirements ?? [])],
    [clientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirements = useCallback(
    (input$key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind) => (
      <ClientRequirementsSuggested
        $key={input$key}
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        saleKind={saleKind}
        disabled={disabled}
        staticRequirements={static$data ?? []}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  const renderClientOverridesRequirementsNoSuggestions = useCallback(
    () => (
      <ClientRequirementsInput
        value={displayedRequirements}
        setValue={setClientOverridesRequirements}
        disabled={disabled}
        staticRequirements={static$data ?? []}
        suggestionPromptInput={null}
      />
    ),
    [disabled, displayedRequirements, setClientOverridesRequirements, static$data],
  );

  return {
    clientOverridesRequirements,
    setClientOverridesRequirements,
    renderClientOverridesRequirements,
    renderClientOverridesRequirementsNoSuggestions,
  };
}

function ClientRequirementsSuggested({
  $key,
  value,
  setValue,
  saleKind,
  disabled,
  staticRequirements,
}: {
  $key: ClientBaseFields_ClientRequirementsSuggestionsFragment$key | null | undefined;
  value: ForwardClientRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardClientRequirementAutocompleteProps<true>['value']>;
  saleKind: ServiceCallKind;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardClientRequirementAutocompleteProps<true>['value']>;
}) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientRequirementsSuggestionsFragment on ISale
      @argumentDefinitions(lang: { type: "String!" }, skipAccessories: { type: "Boolean!" }) {
        ...useSuggestionsFragment @arguments(lang: $lang, skipAccessories: $skipAccessories)
      }
    `,
    $key,
  );

  const suggestionPromptInput = useSuggestions($data, saleKind, disabled);

  return (
    <ClientRequirementsInput
      value={value}
      setValue={setValue}
      disabled={disabled}
      staticRequirements={staticRequirements}
      suggestionPromptInput={suggestionPromptInput}
    />
  );
}

function ClientRequirementsInput({
  value,
  setValue,
  disabled,
  staticRequirements,
  suggestionPromptInput,
}: {
  value: ForwardClientRequirementAutocompleteProps<true>['value'];
  setValue: SetValueFn<ForwardClientRequirementAutocompleteProps<true>['value']>;
  disabled: boolean;
  staticRequirements: NonNullable<ForwardClientRequirementAutocompleteProps<true>['value']>;
  suggestionPromptInput: SuggestionPromptInput | null;
}) {
  const { t } = useTranslation('client');
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleChange = useCallback(
    (values: ForwardClientRequirementAutocompleteProps<true>['value']) => {
      // we need to strip static requirements from results since they are not really part of the value.
      setValue(values?.filter((v) => !staticRequirements.some((sr) => sr.id === v.id)));
    },
    [setValue, staticRequirements],
  );

  return (
    <>
      <ClientRequirementAutocomplete
        multiple
        value={value}
        onChange={handleChange}
        suggestionPromptInput={suggestionPromptInput}
        suggestible={!!suggestionPromptInput}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const isDefaultRequirement = staticRequirements.some((r) => r.id === option.id);
            const handleDelete = isDefaultRequirement ? undefined : getTagProps({ index }).onDelete;
            return <Chip key={option.id} onDelete={handleDelete} label={option.label} size='small' disabled={disabled} />;
          })
        }
        getOptionDisabled={(option) => staticRequirements.some((r) => r.id === option.id)}
        textFieldProps={(params) => ({
          label: t('field.requirements', { ns: 'common' }),
          InputProps: {
            ...params.InputProps,
            startAdornment: (
              <>
                {value && value.length > 0 && (
                  <IconButton onClick={() => setDialogOpen(true)} sx={{ position: 'absolute', left: 0, top: 'calc(50% - 1.25rem)' }}>
                    <InfoIcon sx={(theme) => ({ color: theme.palette.grey.A400 })} />
                  </IconButton>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
          },
        })}
        sx={value && value.length > 0 ? { '.MuiAutocomplete-inputRoot.MuiInputBase-adornedStart': { pl: '2.5rem' } } : {}}
        disabled={disabled}
      />
      <RequirementsDialog
        requirementIds={value?.map((r) => r.id) ?? []}
        onClose={() => setDialogOpen(false)}
        isOpen={dialogOpen}
        title={t('dialog.requirementsTitle', { ns: 'client' })}
      />
    </>
  );
}

const fieldClientOverridesLanguageCodeKey = createFieldKey<ClientLanguage | null>();
export function useFieldClientOverridesLanguageCode(
  $key: ClientBaseFields_ClientOverrides_LanguageCodeFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment ClientBaseFields_ClientOverrides_LanguageCodeFragment on IOverridableClient {
        language
      }
    `,
    $key,
  );

  const [languageCode, setLanguageCode] = useField(
    jobStageBaseFormContext,
    fieldClientOverridesLanguageCodeKey,
    $data?.language ? castClientLanguage($data.language) : null,
  );
  const useMapper = useFieldMapper(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);
  useMapper((v) => ({ clientBase: { assignedClientInfo: { language: v } } }), [], 'save');

  const clientOverridesLanguageCodeIsDirty = useFieldIsDirty(jobStageBaseFormContext, fieldClientOverridesLanguageCodeKey);

  const renderClientOverridesLanguageCode = useCallback(
    () => <ClientLanguageInput value={languageCode} setValue={setLanguageCode} disabled={disabled} />,
    [languageCode, disabled, setLanguageCode],
  );

  return {
    clientOverridesLanguageCode: languageCode,
    setClientOverridesLanguageCode: setLanguageCode,
    clientOverridesLanguageCodeIsDirty,
    renderClientOverridesLanguageCode,
  };
}

function ClientLanguageInput({
  value,
  setValue,
  disabled,
}: {
  value: ClientLanguage | null;
  setValue: SetValueFn<ClientLanguage | null>;
  disabled: boolean;
}) {
  const { t } = useTranslation('client');
  const [newValue, setNewValue] = useState<ClientLanguage | null>(null);
  const handleChange = useCallback((_: SyntheticEvent, v: ClientLanguage) => setNewValue(v), []);

  const handleConfirm = useCallback(() => {
    if (newValue) {
      setValue(newValue);
    }
    setNewValue(null);
  }, [newValue, setValue]);

  const handleCancel = useCallback(() => setNewValue(null), []);

  return (
    <>
      <SelectPicker
        value={value ?? 'fr'}
        disableClearable
        getOptionKey={(o) => o}
        getOptionLabel={(o) => t(`language.${o}`, { ns: 'client' })}
        onChange={handleChange}
        options={clientLanguages}
        disabled={disabled}
        textFieldProps={(params) => ({
          ...params,
          label: t('field.preferredLanguage'),
        })}
      />
      <ConfirmationDialog
        title={t('warning', { ns: 'common' })}
        message={t('placeholder.changeLanguageWarning')}
        onConfirm={handleConfirm}
        showClose={false}
        onCancel={handleCancel}
        isOpen={!!newValue}
      />
    </>
  );
}
