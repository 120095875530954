/**
 * @generated SignedSource<<a9b9c103826f985a22eaa066c1c64de5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useResetBoomConfigurationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SaleCraneSelectorFields_CraneCapacityFragment" | "SaleCraneSelectorFields_CraneConfigurationKindFragment" | "SaleCraneSelectorFields_EquipmentKindFragment" | "SaleCraneSelectorFields_Manual_BoomConfigurationFragment">;
  readonly " $fragmentType": "JobEquipment_useResetBoomConfigurationFragment";
};
export type JobEquipment_useResetBoomConfigurationFragment$key = {
  readonly " $data"?: JobEquipment_useResetBoomConfigurationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useResetBoomConfigurationFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useResetBoomConfigurationFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneCapacityFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_EquipmentKindFragment"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_CraneConfigurationKindFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SaleCraneSelectorFields_Manual_BoomConfigurationFragment"
    }
  ],
  "type": "ManualConfigurationInfo",
  "abstractKey": null
};
})();

(node as any).hash = "d8dee38227673d3e4db74ebdcd203845";

export default node;
