import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

interface Props {
  error: Error;
  onButtonClick?: () => void;
  titleMessage?: string;
  subtitleMessage?: string;
  buttonMessage?: string;
}

export default function ErrorFallback({
  error,
  onButtonClick: handleResetError,
  titleMessage = undefined,
  subtitleMessage = undefined,
  buttonMessage = undefined,
}: Props) {
  const { t } = useTranslation('common');

  return (
    <Stack
      data-testid='errorFallback'
      sx={{
        px: '1rem',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        height: '70vh',
      }}
      spacing={1}>
      <Typography variant='h5'>{titleMessage || t('errorMessages.somethingWentWrong')}</Typography>
      <Typography>{subtitleMessage || error.message}</Typography>
      {handleResetError && (
        <Button onClick={handleResetError} variant='contained'>
          {buttonMessage ? buttonMessage : t('button.goBack')}
        </Button>
      )}
    </Stack>
  );
}
