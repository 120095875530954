import {
  ElementType,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import {
  Autocomplete,
  AutocompleteOwnerState,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Checkbox,
  ChipTypeMap,
  CircularProgress,
  List,
  ListItem,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Suggestion } from './PaginatedAutocomplete';
import StarIcon from '@mui/icons-material/Star';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteFreeSoloValueMapping,
} from '@mui/base/useAutocomplete/useAutocomplete';
import { GraphQLTaggedNode, useFragment } from 'react-relay';
import { RelayObservable } from 'relay-runtime/lib/network/RelayObservable';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { useCancellableSubscription } from '../hooks/useCancellableSubscription';

export type SelectPickerValue<Value, Multiple, DisableClearable, FreeSolo> = Multiple extends true
  ? ReadonlyArray<Value | AutocompleteFreeSoloValueMapping<FreeSolo>>
  : DisableClearable extends true
    ? NonNullable<Value | AutocompleteFreeSoloValueMapping<FreeSolo>>
    : Value | null | AutocompleteFreeSoloValueMapping<FreeSolo>;

export type AutocompleteOwnerStatePatched<
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType,
> = Omit<
  AutocompleteOwnerState<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'defaultValue' | 'value' | 'onChange' | 'renderOption' | 'renderTags'
> &
  AutocompleteReadonlyPatch<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>;

export interface AutocompleteReadonlyPatch<
  Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType,
> {
  defaultValue?: SelectPickerValue<Option, Multiple, DisableClearable, FreeSolo>;
  value?: SelectPickerValue<Option, Multiple, DisableClearable, FreeSolo>;
  onChange?: (
    event: React.SyntheticEvent,
    value: SelectPickerValue<Option, Multiple, DisableClearable, FreeSolo>,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Option>,
  ) => void;
  renderTags?: (
    value: SelectPickerValue<Option, Multiple, DisableClearable, FreeSolo>,
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerStatePatched<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ) => React.ReactNode;
}

export interface SelectPickerProps<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> extends AutocompleteReadonlyPatch<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> {
  renderListItemContent?: (params: SelectPickerContentSuggestibleProps<Option>) => SelectPickerListItemProps<Multiple>['content'];
  textFieldProps?: (params: AutocompleteRenderInputParams) => Partial<TextFieldProps>;
  getOptionKey: AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>['getOptionKey'];
}

export type ForwardAutocompleteProps<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  | 'ref'
  | 'autoComplete'
  | 'renderInput'
  | 'renderOption'
  | 'getOptionKey'
  | 'defaultValue'
  | 'value'
  | 'onChange'
  | 'renderTags'
  | 'renderGroup'
>;

export const SelectPicker = forwardRef<
  HTMLDivElement,
  SelectPickerProps<unknown, boolean, boolean, boolean, ElementType> &
    ForwardAutocompleteProps<unknown, boolean, boolean, boolean, ElementType>
>(function SelectPicker<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    renderListItemContent,
    textFieldProps,
    multiple,
    getOptionLabel,
    getOptionDisabled,
    defaultValue,
    value,
    onChange: handleChange,
    renderTags,
    ...autocompleteProps
  }: SelectPickerProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    ForwardAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: Ref<HTMLDivElement>,
) {
  const { t } = useTranslation('common');
  return (
    <Autocomplete<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>
      clearText={autocompleteProps.clearText || t('autocomplete.clearText')}
      closeText={autocompleteProps.closeText || t('autocomplete.closeText')}
      loadingText={autocompleteProps.loadingText || t('autocomplete.loadingText')}
      noOptionsText={autocompleteProps.noOptionsText || t('autocomplete.noOptionsText')}
      openText={autocompleteProps.openText || t('autocomplete.openText')}
      multiple={multiple}
      {...(multiple ? { disableCloseOnSelect: true, filterSelectedOptions: false } : {})}
      {...autocompleteProps}
      ref={ref}
      {...{
        // There is a bug in Autocomplete typing that assumes that arrays (Multiple = true) are immutable when they aren't.
        defaultValue: defaultValue as AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>['defaultValue'],
        value: value as AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>['value'],
        onChange: handleChange as AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>['onChange'],
        renderTags: renderTags as AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>['renderTags'],
      }}
      autoComplete
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      renderOption={(props, option, { selected }, { getOptionLabel: getOptionLabelEffective, groupBy }) => {
        const optionDisabled = getOptionDisabled?.(option) ?? false;
        return (
          <SelectPickerListItem
            // props type is inaccurate it does not include the key property
            key={(props as unknown as { key: string }).key}
            multiple={multiple}
            // getOptionLabel type is inaccurate it returns the result type as is and not stringified
            content={
              renderListItemContent?.({
                label: getOptionLabelEffective(option),
                group: groupBy?.(option),
                option: option,
                selected,
                disabled: optionDisabled,
              }) ?? `${getOptionLabelEffective(option)}`
            }
            disabled={optionDisabled}
            props={props}
            selected={selected}
          />
        );
      }}
      renderInput={(params) => {
        const p = textFieldProps?.(params);
        return <TextField {...params} title={buildTitle(p?.inputProps?.value ?? params.inputProps?.value)} {...p} />;
      }}
      renderGroup={({ key, ...params }) => <SelectPickerGroup key={key} {...params} />}
    />
  );
}) as <
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: SelectPickerProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    ForwardAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    RefAttributes<HTMLDivElement>,
) => ReactElement;

export interface SelectPickerListItemProps<Multiple extends boolean | undefined = false> {
  multiple: Multiple | undefined;
  content: ReactNode;
  props: HTMLAttributes<HTMLLIElement>;
  selected: boolean;
  disabled: boolean;
}

export function SelectPickerListItem<Multiple extends boolean | undefined = false>({
  multiple,
  content,
  props,
  selected,
  disabled,
}: SelectPickerListItemProps<Multiple>) {
  return (
    <ListItem {...props}>
      {multiple && <Checkbox sx={{ mr: '0.5rem' }} checked={selected} disabled={disabled} />}
      {content}
    </ListItem>
  );
}

export type SelectPickerContentSuggestibleProps<Option> = {
  group: string | undefined;
  label: string;
  option: Option;
  selected: boolean;
  disabled: boolean;
};

export function SelectPickerContentSuggestible({ group, label, disabled }: SelectPickerContentSuggestibleProps<unknown>) {
  return (
    <>
      {group === 'suggestions' && (
        <StarIcon
          sx={(theme) => ({
            color: disabled ? theme.palette.suggestion.dark : theme.palette.suggestion.main,
            marginRight: '0.5rem',
            marginLeft: '-0.5rem',
          })}
        />
      )}
      {label}
    </>
  );
}

type SelectPickerSuggestibleQueryResult<O> = {
  readonly suggestions?: ReadonlyArray<Suggestion<O>>;
};

export type ForwardSelectPickerSuggestibleProps<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  SelectPickerProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    ForwardAutocompleteProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  'ref' | 'onOpen' | 'loading' | 'groupBy' | 'onChange'
>;

export type SelectPickerSuggestibleProps<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
> = {
  fragment: GraphQLTaggedNode;
  onQuery: () => RelayObservable<KeyType<SelectPickerSuggestibleQueryResult<Option>>>;
  onChange?: (value: SelectPickerValue<Option, Multiple, DisableClearable, FreeSolo>, event: SyntheticEvent) => void;
};
export const SelectPickerSuggestible = forwardRef<
  HTMLInputElement,
  SelectPickerSuggestibleProps<unknown, boolean, boolean, boolean> &
    ForwardSelectPickerSuggestibleProps<unknown, boolean, boolean, boolean, ElementType>
>(function SelectPickerSuggestible<
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    onQuery,
    options,
    fragment,
    onChange,
    textFieldProps,
    ...pickerProps
  }: SelectPickerSuggestibleProps<Option, Multiple, DisableClearable, FreeSolo> &
    ForwardSelectPickerSuggestibleProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent>,
  ref: Ref<HTMLDivElement>,
) {
  const [, setSubscription] = useCancellableSubscription();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<KeyType<SelectPickerSuggestibleQueryResult<Option>> | null>(null);

  const doQuery = useCallback(() => {
    setResults(null);
    setIsLoading(true);

    let result: KeyType<SelectPickerSuggestibleQueryResult<Option>>;
    setSubscription(
      onQuery().subscribe({
        complete: () => {
          setResults(result);
          setIsLoading(false);
        },
        error: () => setIsLoading(false),
        next: (nextResult) => (result = nextResult),
        unsubscribe: () => setIsLoading(false),
      }),
    );
  }, [setSubscription, onQuery]);

  const data = useFragment(fragment, results);

  const suggestions =
    data?.suggestions
      // HACK: We have no idea why the s in this context can be undefined
      // Basically the suggestions array contains 5 times "undefined" rather than being undefined itself
      ?.filter((s) => s)
      // This ensures we do not display suggestions that aren't actual values present in the list.
      // This feature doesn't support dynamic options, so if the options changes over-time,
      // like when paging, you might be missing values until they are added to the list.
      .filter((s) => options.includes(s.value))
      .map((s) => s.value) ?? [];
  const values = [...suggestions, ...options].reduce((acc, cur) => [...acc, ...(acc.includes(cur) ? [] : [cur])], [] as Option[]);

  return (
    <SelectPicker
      ref={ref}
      {...pickerProps}
      options={values}
      loading={isLoading}
      renderListItemContent={(params) => <SelectPickerContentSuggestible {...params} />}
      groupBy={(o) => (suggestions.includes(o) ? 'suggestions' : 'searchResults')}
      onOpen={() => {
        doQuery();
      }}
      onChange={(e, v, reason) => {
        if (reason !== 'blur') {
          onChange?.(v, e);
        }
      }}
      textFieldProps={(params) => {
        const p = textFieldProps?.(params);
        return {
          ...p,
          InputProps: {
            ...(p?.InputProps ?? params.InputProps),
            endAdornment: (
              <>
                {isLoading && <CircularProgress color='inherit' size={20} />}
                {p?.InputProps?.endAdornment ?? params.InputProps.endAdornment}
              </>
            ),
          },
        };
      }}
    />
  );
}) as <
  Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: SelectPickerSuggestibleProps<Option, Multiple, DisableClearable, FreeSolo> &
    ForwardSelectPickerSuggestibleProps<Option, Multiple, DisableClearable, FreeSolo, ChipComponent> &
    RefAttributes<HTMLDivElement>,
) => ReactElement;

function buildTitle(value: string | number | readonly string[] | undefined): string | undefined {
  if (!value) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return `${value}`;
}

export function SelectPickerGroup({
  group,
  children,
}: PropsWithChildren<{
  group: string | undefined;
}>): ReactNode {
  const { t } = useTranslation('common');
  if (!group) {
    return children;
  }
  return (
    <ListItem sx={{ alignItems: 'stretch', flexDirection: 'column', p: 0 }} aria-label={'group'}>
      <Typography variant={'subtitle2'} component={'p'} sx={{ pb: '0.5rem', pl: '0.75rem' }}>
        {t(group)}
      </Typography>
      <List sx={{ p: 0, pb: '0.75rem' }}>{children}</List>
    </ListItem>
  );
}
