/**
 * @generated SignedSource<<973062764272944508a7f72318a2c621>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useSuggestionsFragment">;
  readonly " $fragmentType": "SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment";
};
export type SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$key = {
  readonly " $data"?: SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipAccessories"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SaleProjectFields_NatureOfWorkSubCategoryInput_SuggestionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "lang",
          "variableName": "lang"
        },
        {
          "kind": "Variable",
          "name": "skipAccessories",
          "variableName": "skipAccessories"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useSuggestionsFragment"
    }
  ],
  "type": "ISale",
  "abstractKey": "__isISale"
};

(node as any).hash = "faafe96b5e77367ddbc4569dcb2e08e5";

export default node;
