import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, useCallback } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
} from './PaginatedAutocomplete';
import { resolvedLanguage } from '../../i18n';
import { convertToTsQuery } from '../utils/stringUtils';
import { WorkForceTypeAutocompleteFragment$data } from './__generated__/WorkForceTypeAutocompleteFragment.graphql';
import { WorkForceTypeAutocompleteQuery } from './__generated__/WorkForceTypeAutocompleteQuery.graphql';
import { useAmbientTranslation } from '../hooks/useAmbientTranslation';

export type ForwardWorkForceTypeAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<WorkForceTypeAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const WorkForceTypeAutocomplete = forwardRef<
  HTMLInputElement,
  ForwardPaginatedAutocompleteProps<
    ConnectionNode<WorkForceTypeAutocompleteFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    boolean,
    boolean,
    ElementType
  >
>(function WorkForceTypeAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    ...paginatedAutocompleteProps
  }: ForwardPaginatedAutocompleteProps<
    ConnectionNode<WorkForceTypeAutocompleteFragment$data> & AutocompleteMetadata,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  >,
  ref: Ref<HTMLInputElement>,
) {
  const { i18n } = useAmbientTranslation();
  const env = useRelayEnvironment();
  const lang = resolvedLanguage(i18n);
  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<WorkForceTypeAutocompleteQuery>(
        env,
        graphql`
          query WorkForceTypeAutocompleteQuery($lang: String!, $searchTerm: String) {
            ...WorkForceTypeAutocompleteFragment @arguments(lang: $lang, searchTerm: $searchTerm)
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          lang,
        },
      ),
    [lang, env],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<WorkForceTypeAutocompleteFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref}
      fragment={graphql`
        fragment WorkForceTypeAutocompleteFragment on Query
        @refetchable(queryName: "workForceTypeAutocomplete2FragmentQuery")
        @argumentDefinitions(
          lang: { type: "String!" }
          searchTerm: { type: "String" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchWorkForceTypes(searchTerm: $searchTerm, after: $cursor, first: $count)
            @connection(key: "workForceTypeAutocomplete2Fragment_searchResults") {
            edges {
              node {
                id
                code
                label(lang: $lang)
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: ForwardPaginatedAutocompleteProps<
    ConnectionNode<WorkForceTypeAutocompleteFragment$data>,
    'fragment' | 'onQuery' | 'getOptionLabel',
    Multiple,
    DisableClearable,
    ChipComponent
  > & { ref?: Ref<HTMLInputElement> },
) => ReactElement;
