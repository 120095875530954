import { QuoteStatus } from '../../__enums__/QuoteStatus';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { TransportKind } from '../../__enums__/TransportKind';

const kindAria: Record<QuoteKind, string> = {
  accessoriesRental: 'Créer une soumission au type location d’accessoires',
  bare: 'Créer une soumission au type grue non-opérée',
  laborRental: 'Créer une soumission au type location de main d’oeuvre',
  liftingPlan: 'Créer une soumission au type plan de levage',
  liftingTest: 'Créer une soumission au type test de levage',
  operatedHourly: 'Créer une soumission au type grue opérée (à l’heure)',
  operatedMonthly: 'Créer une soumission au type grue opérée (mensuelle)',
  rollingEquipment: 'Créer une soumission au type matériel roulant',
};

const kindCapitalized: Record<QuoteKind, string> = {
  accessoriesRental: 'LOCATION D’ACCESSOIRES',
  bare: 'GRUE NON-OPÉRÉE',
  laborRental: 'LOCATION DE MAIN D’OEUVRE',
  liftingPlan: 'PLAN DE LEVAGE',
  liftingTest: 'TEST DE LEVAGE',
  operatedHourly: 'GRUE OPÉRÉE (À L’HEURE)',
  operatedMonthly: 'GRUE OPÉRÉE (MENSUELLE)',
  rollingEquipment: 'MATÉRIEL ROULANT',
};

export const quote = {
  addenda: 'Addenda',
  button: {
    approve: 'Approuver',
    decline: 'Décliner',
    modify: 'Modifier',
    returnToInWriting: 'Retourner en saisie',
    rejectBySupervisor: 'Rejeter',
    accept: 'Accepter',
  },
  costs: 'Coûts',
  createQuoteAction: {
    main: kindCapitalized.operatedHourly,
    options: kindCapitalized,
    ariaLabels: {
      main: kindAria.operatedHourly,
      more: 'Montrer les options',
      options: kindAria,
    },
  },
  dateTime: {
    sevenDays: '7 jours',
    fourteenDays: '14 jours',
    thirtyDays: '30 jours',
    sixtyDays: '60 jours',
  },
  dialog: {
    email: {
      title: 'Envoyer par courriel',
      sendToProjectManager: 'Chargé de projet',
      sendToClientRepresentative: 'Représentant au compte client',
      sendToCurrentUser: 'L’utilisateur connecté',
      sendButton: 'Envoyer',
      unavailable: 'non disponible',
      error: 'Une erreur s’est produite pendant l’envoi de courriel.',
    },
    accept: {
      title: 'Accepter la soumission',
      body: 'Êtes-vous certain de vouloir accepter cette soumission\u00a0? Cette opération est irréversible. Un appel de service sera automatiquement créé.',
      cancelButton: 'Non',
      confirmButton: 'Oui',
    },
    decline: {
      title: 'Décliner la soumission',
      body: 'Êtes-vous sur de vouloir décliner la soumission\u00a0? Cette opération est irréversible.',
      cancelButton: 'Non',
      confirmButton: 'Oui',
    },
  },
  error: {
    arrivalDateBeforeDepartureDate: 'La date d’arrivée ne peut pas être avant la date de départ',
    dateInThePast: 'La date ne peut pas être dans le passé',
    errorDuringDeclineByClient: 'Une erreur s’est produite pendant le refus',
    errorDuringAccept: 'Une erreur s’est produite pendant l’acceptation',
    errorDuringRequestChange: 'Une erreur s’est produite pendant la demande de modification',
    errorDuringRequestReview: 'Une erreur s’est produite pendant la demande d’approbation',
    errorDuringRejectBySupervisor: 'Une erreur s’est produite pendant le rejet',
    errorDuringApproveBySupervisor: 'Une erreur s’est produite pendant l’acceptation',
    errorDuringReturnToInWriting: 'Une erreur s’est produite pendant le retour en saisie',
    readyDateBeforeArrivalDate: 'La date prête ne peut pas être avant la date d’arrivée',
    clientIsRequired: 'Veuillez sélectionner un client',
    worksiteIsRequired: 'Veuillez sélectionner un chantier',
  },
  field: {
    accessory: {
      accessory: 'Accessoire',
      billed: 'Facturé',
      billingCode: 'Nom du code de facturation',
      noAccessory: 'Aucun accessoire',
      overline: 'Accessoires',
      qty: 'Qté.',
      type: 'Type d’accessoire',
      billable: 'Facturable',
      quantity: 'Quantité',
      rate: 'Taux',
    },
    addenda: {
      description: 'Description',
      kind: 'Type',
      title: 'Titre',
    },
    cost: {
      overline: 'Coûts',
      billingCode: 'Nom du code de facturation',
      billable: 'Facturable',
      crane: 'Grue',
      labor: 'Main d’oeuvre',
      noLine: 'Aucune ligne',
      quantity: 'Quantité',
      qty: 'Qté.',
      rate: 'Taux',
    },
    client: {
      inTheCareOf: 'A/S',
      orderNumber: '# Commande',
      projectManager: 'Chargé de projet',
      requirement: 'Exigences',
    },
    equipment: {
      kind: 'Genre d’équipement',
      type: 'Genre',
      boomConfiguration: 'Configuration de flèche',
      craneConfiguration: 'Configuration de grue',
      craneSelectionMode: 'Sélection de la grue',
      lifts: 'Levages',
      configurationList: 'Liste de configurations',
      configuration: 'Configuration',
      otherInformation: 'Autres informations',
      transport: {
        transportKind: 'Type de transport',
        craneTransportRate: 'Principale',
        additionalCraneTransportRate: 'Additionnelle {{ craneIndex }}',
        boomTransportRate: 'Principale',
        otherBoomTransportRate: 'Autre',
      },
    },
    project: {
      branch: {
        dispatchBranch: 'Succursale de répartition',
        overline: 'Succursales',
        salesBranch: 'Succursale de ventes',
      },
      date: {
        arrival: 'Arrivée',
        date: 'Date',
        departure: 'Départ',
        duration: 'Durée des travaux',
        durationPerDay: 'Durée par jour',
        isSecondServing: '2e tablée',
        includeWeekendsAndHolidays: 'Inclure fin de semaine et fériés',
        notes: 'Notes et informations complémentaires',
        ready: 'Prête',
      },
      work: {
        isEstablishedSchedule: 'Horaire établi',
        natureOfWork: 'Nature du travail',
        natureOfWorkSubCategory: 'Catégorie',
        overline: 'Travail',
        workDescription: 'Description du travail',
        workSchedule: 'Horaire de travail',
      },
    },
    costs: {
      goToDesktop: 'Poursuivre sur l’application web pour accéder à la calculette de soumission',
    },
  },
  info: 'Infos',
  kind: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Grue non-opérée',
    laborRental: 'Location de main d’oeuvre',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    operatedHourly: 'Grue opérée (à l’heure)',
    operatedMonthly: 'Grue opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
  } satisfies Record<QuoteKind, string>,
  kindShort: {
    accessoriesRental: 'Location d’accessoires',
    bare: 'Non-opérée',
    laborRental: 'Location de main d’oeuvre',
    liftingPlan: 'Plan de levage',
    liftingTest: 'Test de levage',
    operatedHourly: 'Opérée (à l’heure)',
    operatedMonthly: 'Opérée (mensuelle)',
    rollingEquipment: 'Matériel roulant',
  } satisfies Record<QuoteKind, string>,
  list: {
    column: {
      friendlyId: 'ID',
      client: 'Client',
      worksite: 'Chantier',
      kind: 'Type',
      crane: 'Grue',
      status: 'Statut',
      date: 'Date',
    },
  },
  pages: {
    accessories: {
      title: 'Accessoires',
    },
    addenda: {
      title: 'Addenda',
    },
    addendaAndAttachments: {
      title: 'Addenda et pièces jointes',
    },
    costLine: {
      title: 'Lignes de coût',
    },
    client: {
      title: 'Informations du client',
      requirementDescriptionsTitle: 'Exigences client',
    },
    details: {
      copyTitle: 'Copier la soumission',
      copyTitleShort: 'Copier',
      shortTitle: 'Modifier',
      title: 'Modifier la soumission',
      newTitle: 'Nouvelle soumission',
    },
    project: {
      title: 'Informations du project',
      subsection: {
        branches: 'Succursales',
        dateAndTime: 'Dates et heures',
        work: 'Travail',
        worksite: 'Chantier',
      },
    },
    questions: {
      title: 'Questions',
    },
    equipment: {
      title: 'Informations de l’équipement',
      subsection: {
        craneSelector: 'Sélecteur de grue',
      },
    },
    costs: {
      title: 'Informations des coûts',
      subsection: {
        additionalCranes: 'Grues additionnelles',
        mainCrane: 'Grue principale',
        emptyCraneConfiguration: 'Aucune configuration sélectionnée',
      },
    },
  },
  placeholder: {
    accessoryInfo: 'Sélectionnez des accessoires (facultatif)',
    accessoryOutOfInventory: 'Écrire l’accessoire',
    addAddenda: 'Ajouter un addenda',
    addendaInfo: 'Ajouter des addenda (facultatif)',
    clientInfo: 'Entrez les informations du client',
    equipmentInfo: 'Entrez les informations de l’équipement',
    projectInfo: 'Entrez les informations du projet',
    questionsInfo: 'Répondre aux questions de base',
    costsInfo: 'Entrez les informations des coûts',
    selectConfiguration: 'Sélectionner une configuration',
  },
  section: {
    addenda: 'Addenda',
    addendaAndAttachments: 'Addendas et pièces jointes',
    calculator: {
      header: 'Calculette',
      action: {
        refresh: 'Recalculer',
        apply: 'Appliquer',
      },
    },
    client: 'Client',
    costLines: 'Lignes de coût',
    project: 'Projet',
    questions: 'Questions',
    equipment: 'Équipement',
    accessories: 'Accessoires',
    costs: 'Coûts',
    transport: {
      header: 'Transport',
      craneTransportRate: 'Taux de grue',
      boomTransportRate: 'Taux de flèche',
    },
  },
  quotes: 'Soumissions',
  status: {
    accepted: 'Accepté',
    canceled: 'Annulé',
    inWriting: 'En saisie',
    declined: 'Décliné',
    revising: 'En revue',
    inApproval: 'En approbation',
    awaitingClient: 'Attente client',
    locked: 'Verrouillé',
  } satisfies Record<QuoteStatus, string>,
  transportKind: {
    crane: 'Transport de grue',
    jibAndCounterweight: 'Transport de flèche et contrepoids',
    mobilisationAndDemobilisation: 'Mobilisation et démobilisation',
  } satisfies Record<TransportKind, string>,
};
