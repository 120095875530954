/**
 * @generated SignedSource<<07d1fe000815913f1fd1441dda236460>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteFragment">;
  readonly " $fragmentType": "JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment";
};
export type JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$key = {
  readonly " $data"?: JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "lang"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "lang",
          "variableName": "lang"
        }
      ],
      "kind": "FragmentSpread",
      "name": "JobEquipment_useCraneSelectorFavoriteFragment"
    }
  ],
  "type": "CraneSelectorInternal",
  "abstractKey": null
};

(node as any).hash = "ffc6e8ddc88405586b3ea5b82e8e265b";

export default node;
