import graphql from 'babel-plugin-relay/macro';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { ElementType, forwardRef, ReactElement, Ref, RefAttributes, useCallback, useEffect, useRef } from 'react';
import { ChipTypeMap } from '@mui/material';
import {
  AutocompleteMetadata,
  ConnectionNode,
  ConnectionPaginatedAutocomplete,
  ForwardPaginatedAutocompleteProps,
  Queryable,
} from './PaginatedAutocomplete';
import { useTranslation } from 'react-i18next';
import { resolvedLanguage } from '../../i18n';
import { convertToTsQuery } from '../utils/stringUtils';
import { EquipmentKindAutocompleteFragment$data } from './__generated__/EquipmentKindAutocompleteFragment.graphql';
import { EquipmentKindAutocompleteQuery } from './__generated__/EquipmentKindAutocompleteQuery.graphql';

export interface EquipmentKindAutocompleteProps {
  capacity: number | null;
  vehicleIds: string[] | null;
  configurationKindCode: number | null;
}

export type ForwardEquipmentKindAutocompleteProps<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = ForwardPaginatedAutocompleteProps<
  ConnectionNode<EquipmentKindAutocompleteFragment$data>,
  'fragment' | 'onQuery' | 'getOptionLabel',
  Multiple,
  DisableClearable,
  ChipComponent
>;

export const EquipmentKindAutocomplete = forwardRef<
  HTMLInputElement,
  EquipmentKindAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<EquipmentKindAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      boolean,
      boolean,
      ElementType
    >
>(function EquipmentKindAutocomplete<
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  {
    capacity,
    vehicleIds,
    configurationKindCode,
    ...paginatedAutocompleteProps
  }: EquipmentKindAutocompleteProps &
    ForwardPaginatedAutocompleteProps<
      ConnectionNode<EquipmentKindAutocompleteFragment$data> & AutocompleteMetadata,
      'fragment' | 'onQuery' | 'getOptionLabel',
      Multiple,
      DisableClearable,
      ChipComponent
    >,
  ref: Ref<HTMLInputElement>,
) {
  const { i18n } = useTranslation('serviceCall');
  const lang = resolvedLanguage(i18n);
  const env = useRelayEnvironment();
  const queryRef = useRef<Queryable>(null);

  useEffect(() => {
    queryRef.current?.reset();
  }, [capacity, vehicleIds, configurationKindCode]);

  const handleQuery = useCallback(
    (searchTerm: string | null) =>
      fetchQuery<EquipmentKindAutocompleteQuery>(
        env,
        graphql`
          query EquipmentKindAutocompleteQuery(
            $searchTerm: String
            $capacity: Int
            $vehicleIds: [String!]
            $configurationKindCode: Int
            $lang: String!
          ) {
            ...EquipmentKindAutocompleteFragment
              @arguments(
                lang: $lang
                searchTerm: $searchTerm
                capacity: $capacity
                vehicleIds: $vehicleIds
                configurationKindCode: $configurationKindCode
              )
          }
        `,
        {
          searchTerm: convertToTsQuery(searchTerm),
          capacity,
          vehicleIds,
          configurationKindCode,
          lang,
        },
      ),
    [env, capacity, vehicleIds, configurationKindCode, lang],
  );

  return (
    <ConnectionPaginatedAutocomplete<
      ConnectionNode<EquipmentKindAutocompleteFragment$data> & AutocompleteMetadata,
      Multiple,
      DisableClearable,
      ChipComponent
    >
      ref={ref}
      queryRef={queryRef}
      fragment={graphql`
        fragment EquipmentKindAutocompleteFragment on Query
        @refetchable(queryName: "equipmentKindAutocomplete2FragmentQuery")
        @argumentDefinitions(
          lang: { type: "String!" }
          searchTerm: { type: "String" }
          capacity: { type: "Int" }
          vehicleIds: { type: "[String!]" }
          configurationKindCode: { type: "Int" }
          cursor: { type: "String" }
          count: { type: "Int", defaultValue: 25 }
        ) {
          searchResults: searchCraneEquipmentKinds(
            searchTerm: $searchTerm
            capacity: $capacity
            vehicleIds: $vehicleIds
            configurationKindCode: $configurationKindCode
            after: $cursor
            first: $count
          ) @connection(key: "equipmentKindAutocomplete2Fragment_searchResults") {
            edges {
              node {
                id
                code
                label(lang: $lang)
              }
            }
          }
        }
      `}
      onQuery={handleQuery}
      {...paginatedAutocompleteProps}
    />
  );
}) as <
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: EquipmentKindAutocompleteProps &
    ForwardEquipmentKindAutocompleteProps<Multiple, DisableClearable, ChipComponent> &
    RefAttributes<HTMLInputElement>,
) => ReactElement;
